import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PropTypes } from "./LCACollapsible.types";

const LCACollapsible = ({
	title,
	defaultExpanded,
	children,
	expanded,
	onChange,
}: PropTypes) => {
	return (
		<Accordion
			sx={{
				boxShadow: "none",
				borderRadius: "8px!important",
				border: "1px solid #E3E3E3",
				backgroundColor: "#ffffff",
				overflow: "hidden",
				boxSizing: "border-box",
			}}
			defaultExpanded={defaultExpanded}
			expanded={expanded}
			onChange={onChange}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel-content"
				id="panel-header"
				sx={{
					backgroundColor: "#ffffff",
					padding: "6px",
					margin: 0,
					"&.Mui-expanded": {
						margin: "0px",
						minHeight: 0,
					},
					"& .MuiAccordionSummary-content.Mui-expanded": {
						margin: "0px 0",
					},
				}}
			>
				<Box sx={{ width: "100%", textAlign: "left", marginLeft: 1 }}>
					{title}
				</Box>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

export default LCACollapsible;
