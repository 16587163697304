import { PropTypes } from "./Heading.types";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { Search } from "../Search";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";

export function Heading({
	title,
	subtitle,
	search,
	showSearch,
	actions,
	newCertification,
	setSearch,
	setShowProductInformation,
	noMarginBottom,
	tooltip,
	onTitleClick,
	setPaginationModel,
}: PropTypes) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: {
					xs: "column",
					sm: "column",
					md: "column",
					lg: "row",
				},
				alignItems: "stretch",
				justifyContent: "space-between",
				marginBottom: noMarginBottom ? 1 : 4,
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					flex: actions ? 0 : 1,
					minWidth: "340px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						marginBottom: 2,
						alignItems: "center",
						cursor: onTitleClick ? "pointer" : "inherit",
						":hover": {
							textDecoration: onTitleClick ? "underline" : "inherit",
						},
					}}
					onClick={() => {
						onTitleClick && onTitleClick();
					}}
				>
					<Typography variant="h4" sx={{ fontWeight: 600 }}>
						{title}
					</Typography>
					{tooltip && (
						<Tooltip title={tooltip}>
							<IconButton>
								<InfoIcon sx={{ fontSize: 16 }} />
							</IconButton>
						</Tooltip>
					)}
				</Box>

				{subtitle && (
					<Typography variant="body2" marginBottom={2}>
						{subtitle}
					</Typography>
				)}
				{showSearch && setSearch && (
					<Box
						sx={{
							maxWidth: {
								xs: 240,
								sm: 240,
								md: 240,
								lg: 300,
							},
						}}
					>
						<Search
							search={search}
							setSearch={setSearch}
							{...(setPaginationModel && { setPaginationModel })}
						/>
					</Box>
				)}
			</Box>
			{actions && (
				<Box
					sx={{
						flex: 1,
						width: "100%",
						alignItems: "stretch",
						display: "flex",
						"& .MuiButton-contained": {
							maxHeight: 33,
						},
						"& .MuiButton-outlined": {
							maxHeight: 33,
						},
					}}
				>
					{actions()}
				</Box>
			)}
			{newCertification && (
				<Box sx={{}}>
					<Button
						variant="contained"
						onClick={() => {
							navigate(newCertification);
						}}
					>
						{t("heading.createNew")}
					</Button>
				</Box>
			)}
		</Box>
	);
}
