import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Heading } from "../Heading";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { sale as saleActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { IEstimation } from "../../models";
import { UserContext } from "../../contexts/user";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 8,
		marginBottom: 4,
		fontWeight: 700,
		color: "#888",
	},
});
export function SalesReportDetail() {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const navigate = useNavigate();
	const { id: paramsId } = useParams();
	const id = paramsId;
	const [sale, setSale] = useState<IEstimation | null>(null);
	const [salePeriod, setSalePeriod] = useState("");
	const [values, setValues] = useState<
		Array<{ id: number; amount: number; emissions: number; period: string }>
	>(
		id
			? []
			: [
					{ id: -1, amount: 0, emissions: 0, period: "Q1-2024" },
					{ id: -2, amount: 0, emissions: 0, period: "Q2-2024" },
					{ id: -3, amount: 0, emissions: 0, period: "Q3-2024" },
					{ id: -4, amount: 0, emissions: 0, period: "Q4-2024" },
			  ]
	);
	const { user } = useContext(UserContext);
	const currentQuarter =
		new Date().getUTCMonth() < 3
			? "Q1"
			: new Date().getUTCMonth() < 6
			? "Q2"
			: new Date().getUTCMonth() < 9
			? "Q3"
			: "Q4";

	const { refetch } = useQuery(
		"sales-detail",
		() =>
			saleActions.getOne(
				parseInt(id!),
				!user?.is_superuser ? user?.company.id : undefined
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setSale(res);

				const startDate = res?.start_date ?? "";
				const startQuarter = Math.ceil(
					((new Date(startDate).getUTCMonth() + 1) / 12) * 4
				);
				const endQuarter = startQuarter === 1 ? 4 : startQuarter - 1;
				const startYear = new Date(startDate).getUTCFullYear();
				const endYear = startQuarter === 1 ? startYear : startYear + 1;
				setSalePeriod(
					"Q" +
						startQuarter +
						"-" +
						startYear.toString() +
						" - " +
						("Q" + endQuarter + "-" + endYear.toString())
				);

				const auxValues = res.product.sales
					?.filter(
						(sale, index) =>
							!(res.product?.sales ?? []).find(
								(s, sIndex) =>
									s.id !== sale.id &&
									s.start_date === sale.start_date &&
									(s.amount > sale.amount || (s.amount === 0 && sIndex < index))
							)
					)
					.sort((a, b) =>
						a.start_date === b.start_date
							? (a.amount ?? 0) - (b.amount ?? 0)
							: new Date(a.start_date).getTime() -
							  new Date(b.start_date).getTime()
					)
					.map((sale) => {
						return {
							id: sale.id,
							amount: sale.amount,
							emissions: sale.product_lca_version_emission?.emissions ?? 0,
							period:
								"Q" +
								Math.ceil(
									((new Date(sale.start_date!).getUTCMonth() + 1) / 12) * 4
								).toString() +
								"-" +
								new Date(sale.start_date!).getUTCFullYear().toString(),
						};
					});

				if (auxValues && auxValues.length < 4) {
					for (let i = 0; i < 4 - auxValues.length; i++) {
						auxValues.push({
							id: -(i + 1),
							amount: 0,
							emissions: 0,
							period: "Q1-2024",
						});
					}
				}

				setValues(auxValues ?? []);
			},
			onError: (error: any) => {
				console.log(error);
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar(t("general.errorMessage"), {
						variant: "error",
					});
				}
			},
		}
	);

	const { isLoading, mutate } = useMutation(
		"sales-detail-create",
		({
			saleId,
			amount,
			start_date,
			end_date,
			companyId,
		}: {
			saleId: number;
			amount: number;
			start_date: string;
			end_date: string;
			companyId?: number;
		}) =>
			saleActions.update(
				saleId,
				amount,
				//start_date,
				//end_date,
				companyId
			),
		{
			retry: false,
		}
	);

	useEffect(() => {
		if (id) {
			refetch();
		}
	}, [id, refetch]);

	const isPreviousQuarter = (selectedQuarter: string) => {
		const quarters = ["Q1", "Q2", "Q3", "Q4"];
		const currentQuarterIndex = quarters.indexOf(currentQuarter);
		const selectedQuarterIndex = quarters.indexOf(
			selectedQuarter.split("-")[0]
		);
		const currentYear = new Date().getUTCFullYear();
		const selectedYear = parseInt(selectedQuarter.split("-")[1]);

		if (currentQuarterIndex === -1 || selectedQuarterIndex === -1) {
			return false;
		}

		if (
			(currentQuarterIndex === 0 &&
				selectedQuarterIndex === 3 &&
				selectedYear === currentYear - 1) ||
			(selectedQuarterIndex === currentQuarterIndex - 1 &&
				selectedYear === currentYear)
		) {
			return true;
		}

		return false;
	};

	return (
		<Box>
			<Box sx={{ flexGrow: 1 }}>
				<Heading title={id ? "Edit Sale" : "Create Sale"} showSearch={false} />

				<Typography variant="h6" sx={{ marginBottom: 1, fontSize: "1rem" }}>
					{t("salesReportDetail.productDetails")}
				</Typography>
				<FormControl sx={{ width: "100%" }}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							flex: 1,
							marginBottom: 2,
						}}
					>
						<span className={classes.inputLabel}>
							{t("salesReportDetail.product")}
						</span>
						<TextField
							InputLabelProps={{ shrink: false }}
							InputProps={{ readOnly: true }}
							fullWidth
							sx={{ backgroundColor: "white" }}
							disabled={true}
							label=""
							value={
								sale?.product.name +
								(sale?.product.asin
									? " - " + sale?.product.asin
									: sale?.product.upc
									? " - " + sale?.product.upc
									: "")
							}
							placeholder={t("salesReportDetail.product")}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							flex: 1,
							marginBottom: 2,
						}}
					>
						<span className={classes.inputLabel}>
							{t("products.headerNameCompany")}
						</span>
						<TextField
							InputLabelProps={{ shrink: false }}
							InputProps={{ readOnly: true }}
							fullWidth
							sx={{ backgroundColor: "white" }}
							disabled={true}
							label=""
							value={sale?.product.company?.name}
							placeholder={t("salesReportDetail.company")}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: 2,
							alignItems: "flex-start",
							marginBottom: 2,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								flex: 1,
								marginBottom: 2,
							}}
						>
							<span className={classes.inputLabel}>
								{t("salesReportDetail.estimatedAnnualSales")}
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								InputProps={{ readOnly: true }}
								fullWidth
								sx={{ backgroundColor: "white" }}
								disabled={true}
								label=""
								value={Intl.NumberFormat("en", {
									maximumFractionDigits: 0,
								}).format(sale?.amount ?? 0)}
								placeholder={t("salesReportDetail.estimatedAnnualSales")}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								flex: 1,
								marginBottom: 2,
							}}
						>
							<span className={classes.inputLabel}>
								{t("salesReportDetail.emissionsPerUnit")}
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								InputProps={{ readOnly: true }}
								fullWidth
								sx={{ backgroundColor: "white" }}
								disabled={true}
								label=""
								value={
									Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
									}).format(
										sale?.product_lca_version_emission?.emissions ?? 0
									) + " kg of CO2e"
								}
								placeholder={t("salesReportDetail.emissionsPerUnit")}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								flex: 1,
								marginBottom: 2,
							}}
						>
							<span className={classes.inputLabel}>
								{t("salesReportDetail.estimatedAnnualEmissions")}
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								InputProps={{ readOnly: true }}
								fullWidth
								sx={{ backgroundColor: "white" }}
								disabled={true}
								label=""
								value={
									Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
									}).format(
										Math.ceil(
											((sale?.amount ?? 0) *
												(sale?.product_lca_version_emission?.emissions ?? 1)) /
												1000
										)
									) + " mtCO2e"
								}
								placeholder={t("salesReportDetail.emissionsUnit")}
							/>
						</Box>

						<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
							<span className={classes.inputLabel}>
								{t("salesReportDetail.period").toUpperCase()}
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								InputProps={{ readOnly: true }}
								fullWidth
								sx={{ backgroundColor: "white" }}
								disabled={true}
								label=""
								value={salePeriod}
								placeholder={t("salesReportDetail.period")}
							/>
						</Box>
					</Box>

					<Divider sx={{ marginTop: 0, marginBottom: 4 }} />
					<Typography variant="h6" sx={{ marginBottom: 1, fontSize: "1rem" }}>
						{t("salesReportDetail.actualSales")}
					</Typography>
					<Button
						variant="contained"
						onClick={() => {
							const mutateSale = (
								id: number,
								amount: number,
								period: string,
								index: number
							) => {
								const quarter = parseInt(period.split("-")[0].substring(1, 2));
								const startDate =
									"20" +
									period.split("-")[1] +
									"-" +
									((quarter / 4) * 12 - 2) +
									"-01";
								const endDate =
									"20" +
									period.split("-")[1] +
									"-" +
									(quarter / 4) * 12 +
									"-28";
								mutate(
									{
										saleId: id,
										amount,
										start_date: startDate,
										end_date: endDate,
										companyId: !user?.is_superuser
											? user?.company.id
											: undefined,
									},
									{
										onSuccess: () => {
											index = index + 1;
											if (index < values.length) {
												mutateSale(
													values[index].id,
													values[index].amount,
													values[index].period,
													index
												);
											} else {
												navigate("/sales");
											}
										},
										onError: (error) => {
											console.log(error);
											enqueueSnackbar(t("general.errorMessage"), {
												variant: "error",
											});
										},
									}
								);
							};
							let index = 0;
							if (values.length > 0) {
								mutateSale(
									values[index].id,
									values[index].amount,
									values[index].period,
									index
								);
							}
						}}
						sx={{
							marginTop: 2,
							maxWidth: 300,
							alignSelf: "end",
							minHeight: 34,
						}}
					>
						{isLoading ? (
							<CircularProgress size={12} sx={{ color: "white" }} />
						) : (
							"Save"
						)}
					</Button>
					{values.map((value, index) => (
						<Box
							sx={{
								display: "flex",
								gap: 2,
								alignItems: "flex-start",
								marginBottom: 2,
							}}
							key={value.id}
						>
							<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
								<span className={classes.inputLabel}>
									{t("salesReportDetail.quarterlyUnitSales")}
								</span>
								<NumericFormat
									value={value?.amount}
									placeholder="Amount of sales"
									customInput={TextField}
									onValueChange={({ value: v }) => {
										setValues((prevState) => {
											const auxValues = [...prevState];
											auxValues[index].amount = parseFloat(v);
											return auxValues;
										});
									}}
									inputProps={{
										style: {
											border: isPreviousQuarter(value.period)
												? "1px solid rgb(3, 176, 147)"
												: "1px solid #C9C9C9",
											borderRadius: "8px",
										},
									}}
									allowNegative={false}
									decimalScale={0}
									autoFocus={isPreviousQuarter(value.period)}
									thousandSeparator={","}
									disabled={
										parseInt(value.period.split("-")[1]) >
											new Date().getUTCFullYear() ||
										(parseInt(value.period.split("-")[1]) ===
											new Date().getUTCFullYear() &&
											value.period.split("-")[0] >= currentQuarter)
									}
								/>
							</Box>
							<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
								<span className={classes.inputLabel}>
									{t("salesReportDetail.emissionsToBeOffset")}
								</span>
								<NumericFormat
									value={(value?.emissions * value?.amount) / 1000}
									placeholder={t("salesReportDetail.emissionsToBeOffset")}
									customInput={TextField}
									decimalScale={2}
									style={{ backgroundColor: "white" }}
									disabled={true}
									thousandSeparator={","}
									suffix={t("salesReportDetail.mtCO2e")}
								/>
							</Box>
							<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
								<span className={classes.inputLabel}>
									{t("salesReportDetail.period").toUpperCase()}
								</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									select
									value={value?.period}
									placeholder={t("salesReportDetail.period")}
									disabled={true}
									style={{ backgroundColor: "white" }}
									onChange={(e) => {
										setValues((prevState) => {
											const auxValues = [...prevState];
											auxValues[index].period = e.target.value;
											return auxValues;
										});
									}}
									sx={{
										".MuiSelect-icon": {
											display: "none",
										},
										"& > div > div": {
											padding: "10px",
											borderRadius: "8px",
										},
									}}
								>
									{[
										"Q1-2018",
										"Q2-2018",
										"Q3-2018",
										"Q4-2018",
										"Q1-2019",
										"Q2-2019",
										"Q3-2019",
										"Q4-2019",
										"Q1-2020",
										"Q2-2020",
										"Q3-2020",
										"Q4-2020",
										"Q1-2021",
										"Q2-2021",
										"Q3-2021",
										"Q4-2021",
										"Q1-2022",
										"Q2-2022",
										"Q3-2022",
										"Q4-2022",
										"Q1-2023",
										"Q2-2023",
										"Q3-2023",
										"Q4-2023",
										"Q1-2024",
										"Q2-2024",
										"Q3-2024",
										"Q4-2024",
										"Q1-2025",
										"Q2-2025",
										"Q3-2025",
										"Q4-2025",
										"Q1-2026",
										"Q2-2026",
										"Q3-2026",
										"Q4-2026",
									].map((period) => {
										return <MenuItem value={period}>{period}</MenuItem>;
									})}
								</TextField>
							</Box>
						</Box>
					))}

					<Divider sx={{ marginTop: 2, marginBottom: 4 }} />
					<Typography variant="h6" sx={{ marginBottom: 1, fontSize: "1rem" }}>
						{t("salesReportDetail.totalAggregatedActualSales")}
					</Typography>
					<Box
						sx={{
							display: "flex",
							gap: 2,
							alignItems: "flex-start",
							marginBottom: 2,
						}}
					>
						<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
							<span className={classes.inputLabel}>
								{t("salesReportDetail.quarterlyUnitSales")}
							</span>
							<NumericFormat
								value={values.reduce(
									(prev, current) => prev + current.amount,
									0
								)}
								placeholder={t("salesReportDetail.amountofSales")}
								customInput={TextField}
								style={{ backgroundColor: "white" }}
								allowNegative={false}
								decimalScale={0}
								thousandSeparator={","}
								disabled={true}
							/>
						</Box>
						<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
							<span className={classes.inputLabel}>
								{t("salesReportDetail.emissionsToBeOffset")}
							</span>
							<NumericFormat
								value={Math.ceil(
									values.reduce(
										(prev, current) =>
											prev + (current.amount * current.emissions) / 1000,
										0
									)
								)}
								placeholder={t("salesReportDetail.emissionsToBeOffset")}
								customInput={TextField}
								decimalScale={2}
								style={{ backgroundColor: "white" }}
								disabled={true}
								thousandSeparator={","}
								suffix=" mtCO2e"
							/>
						</Box>
						<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
							<span className={classes.inputLabel}>
								{t("salesReportDetail.period").toUpperCase()}
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								value={"All year"}
								placeholder={t("salesReportDetail.period")}
								disabled={true}
								style={{ backgroundColor: "white" }}
							/>
						</Box>
					</Box>
				</FormControl>
			</Box>
		</Box>
	);
}
