import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	TextField,
	Typography,
	Grid,
	Autocomplete,
	Divider,
	IconButton,
	Table,
	TableHead,
	TableCell,
	TableRow,
	FormControlLabel,
	Switch,
	TableContainer,
	TableBody,
	Tooltip,
	ButtonGroup,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Heading } from "../Heading";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { analyticsEvent as analyticsEventActions } from "../../controllers";
import {
	company as companyActions,
	offset as offsetActions,
} from "../../controllers";
import { useSnackbar } from "notistack";
import {
	IBalance,
	ICertificationFiles,
	ICompany,
	ILCA,
	IOffset,
} from "../../models";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {
	sale as saleActions,
	certification as certificationActions,
	lca as lcaActions,
} from "../../controllers";
import { AiFillDelete as DeleteIcon } from "react-icons/ai";
import { NumericFormat } from "react-number-format";
import { BasicModal } from "../BasicModal";
import { ContractDocuments } from "../ContractDocuments";
import { ClimecoCertifications } from "../ClimecoCertifications";
import { Certifications } from "../Certifications";
import { CompanyAnalytics } from "../CompanyAnalytics";
import InfoIcon from "@mui/icons-material/Info";
import { UserContext } from "../../contexts/user";
import { useTranslation } from "react-i18next";
import AttestationEntityArrayInput from "../AttestationEntityArrayInput/AttestationEntityArrayInput";
import ArrayInput from "../ArrayInput/ArrayInput";
import { PropTypes } from "./CompanyDetail.types";

const useStyles = makeStyles({
	inputLabel: {
		display: "flex",
		marginTop: 8,
		marginBottom: "14px",
		fontSize: 14,
		fontWeight: 700,
	},
	inputLabelRequired: {
		fontSize: 18,
		display: "inline-block",
		height: "5px",
		verticalAlign: "sub",
		color: "#B61313",
	},
});

export function CompanyDetail({ companyId, isUser }: PropTypes) {
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useContext(UserContext);
	const classes = useStyles();
	const navigate = useNavigate();
	const { id: paramsId } = useParams();
	const id = companyId ? companyId.toString() : paramsId;
	const [company, setCompany] = useState<ICompany | null>({
		id: 0,
		name: "",
		description: "",
		formatted_address: "",
	});
	const [balance, setBalance] = useState<IBalance | null>(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showContractDocumentsModal, setShowContractDocumentsModal] =
		useState(false);
	const [offsets, setOffsets] = useState<number | string>("");
	const [offsetsDescription, setOffsetsDescription] = useState<string>("");
	const [offsetsPrice, setOffsetsPrice] = useState<number | string>("");
	const [companyOffsets, setCompanyOffsets] = useState<Array<IOffset> | null>(
		null
	);
	const { t } = useTranslation();
	const [contractDocuments, setContractDocuments] = useState<
		Array<ICertificationFiles>
	>(Array<ICertificationFiles>());
	const [LCADocuments, setLCADocuments] = useState<Array<ILCA>>(Array<ILCA>());

	const [inputValue, setInputValue] = useState(
		company?.formatted_address || ""
	);

	const [editing, setEditing] = useState(!id);

	const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
		usePlacesService({
			apiKey: "AIzaSyBeHWqS_4Rm9ZBIqbOtQ5PwGJIUYsaHxD0",
			options: {
				types: ["street_address", "route"],
			},
			language: "en",
		});

	const { refetch } = useQuery(
		"companies",
		() => companyActions.getOne(parseInt(id!)),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				res.attestation_form_approved = res.attestation_form_approved
					? res.attestation_form_approved.split("T")[0]
					: null;
				setCompany(res);
				setInputValue(res.formatted_address ?? "");
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				}
			},
		}
	);

	const { refetch: refetchBalance } = useQuery(
		"balance",
		() => saleActions.getBalance([], parseInt(id!)),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setBalance(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchDocuments } = useQuery(
		"documents",
		() =>
			certificationActions.getDocuments({
				isClimeCo: true,
				id: -1,
				companyId: parseInt(id!),
			}),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setContractDocuments(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchLCADocuments } = useQuery(
		"company-lcas",
		() => lcaActions.list(parseInt(id!)),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setLCADocuments(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchCompanyOffsets } = useQuery(
		"company-offsets",
		() => offsetActions.companyList(parseInt(id!)),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanyOffsets(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { isLoading, mutate } = useMutation(
		"companies",
		() =>
			id
				? companyActions.update({
						id: parseInt(id),
						name: company?.name,
						description: company?.description,
						address: company?.address,
						formatted_address: company?.formatted_address,
						city: company?.city,
						state: company?.state,
						zip_code: company?.zip_code,
						country: company?.country,
						contact_name: company?.contact_name,
						email: company?.email,
						phone: company?.phone,
						historically_approved: company?.historically_approved,
						total_estimations: company?.total_estimations,
						historically_amazon_certified:
							company?.historically_amazon_certified,
						legally_recognized_companies: company?.legally_recognized_companies,
						government_entities: company?.government_entities,
						payor_companies: company?.payor_companies,
						company_or_amazon_webpages: company?.company_or_amazon_webpages,
						company_representative_name: company?.company_representative_name,
						company_representative_title: company?.company_representative_title,
						are_any_entities_deemed_hostile_to_usa:
							company?.are_any_entities_deemed_hostile_to_usa,
						are_any_people_listed_in_ofac:
							company?.are_any_people_listed_in_ofac,
						are_any_people_engaged_in_money_laundering:
							company?.are_any_people_engaged_in_money_laundering,
						are_any_people_subject_to_usa_trade_restrictions:
							company?.are_any_people_subject_to_usa_trade_restrictions,
						total_first_year_forecasted_sales:
							company?.total_first_year_forecasted_sales,
						attestation_form_approved: company?.attestation_form_approved,
				  })
				: companyActions.create({
						name: company?.name,
						description: company?.description,
						address: company?.address,
						formatted_address: company?.formatted_address,
						city: company?.city,
						state: company?.state,
						zip_code: company?.zip_code,
						country: company?.country,
						contact_name: company?.contact_name,
						email: company?.email,
						phone: company?.phone,
						historically_approved: company?.historically_approved,
						total_estimations: company?.total_estimations,
						historically_amazon_certified:
							company?.historically_amazon_certified,
						is_parent_company: user?.company.is_parent_company,
						government_entities: company?.government_entities,
						payor_companies: company?.payor_companies,
						company_or_amazon_webpages: company?.company_or_amazon_webpages,
						company_representative_name: company?.company_representative_name,
						company_representative_title: company?.company_representative_title,
						are_any_entities_deemed_hostile_to_usa:
							company?.are_any_entities_deemed_hostile_to_usa,
						are_any_people_listed_in_ofac:
							company?.are_any_people_listed_in_ofac,
						are_any_people_engaged_in_money_laundering:
							company?.are_any_people_engaged_in_money_laundering,
						are_any_people_subject_to_usa_trade_restrictions:
							company?.are_any_people_subject_to_usa_trade_restrictions,
						total_first_year_forecasted_sales:
							company?.total_first_year_forecasted_sales,
				  }),
		{
			retry: false,
			onSuccess: (res) => {
				if (id) {
					enqueueSnackbar("Changes saved", {
						variant: "success",
					});
					setEditing(false);
				} else {
					navigate("/companies");
				}
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);
	useEffect(() => {
		if (id) {
			refetch();
			refetchBalance();
			refetchDocuments();
			refetchLCADocuments();
			refetchCompanyOffsets();
		}
	}, [
		id,
		refetch,
		refetchBalance,
		refetchDocuments,
		refetchLCADocuments,
		refetchCompanyOffsets,
	]);

	const { isLoading: isLoadingMutateAddOffsets, mutate: mutateAddOffsets } =
		useMutation(
			"certification-add-offsets",
			() =>
				certificationActions.addOffsets({
					isClimeCo: true,
					id: -1,
					companyId: parseInt(id!),
					quantity: parseFloat(offsets!.toString()),
					description: offsetsDescription,
					amount:
						typeof offsetsPrice === "string"
							? parseFloat(offsets!.toString()) * 12.5
							: parseFloat(offsets!.toString()) * offsetsPrice,
				}),
			{
				retry: false,
				onSuccess: (res) => {
					setOffsets("");
					setOffsetsDescription("");
					setOffsetsPrice("");
					refetchBalance();
					refetchCompanyOffsets();
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				},
			}
		);

	const validateCompany = () => {
		return (
			!company?.name ||
			!company?.description ||
			!company?.email ||
			!company?.formatted_address ||
			(company?.legally_recognized_companies ?? []).length === 0 ||
			(company?.legally_recognized_companies ?? []).find(
				(c) =>
					(c?.name ?? "").trim().length === 0 ||
					(c?.address ?? "").trim().length === 0 ||
					(c?.url ?? "").trim().length === 0
			) !== undefined ||
			(company?.government_entities ?? []).length === 0 ||
			(company?.government_entities ?? []).find(
				(c) =>
					(c?.name ?? "").trim().length === 0 ||
					(c?.address ?? "").trim().length === 0 ||
					(c?.url ?? "").trim().length === 0
			) !== undefined ||
			(company?.company_representative_name ?? "").trim().length === 0 ||
			(company?.company_representative_title ?? "").trim().length === 0 ||
			(company?.company_or_amazon_webpages ?? []).length === 0 ||
			(company?.company_or_amazon_webpages ?? []).find(
				(c) => (c ?? "").trim().length === 0
			) !== undefined ||
			company?.are_any_entities_deemed_hostile_to_usa === undefined ||
			company?.are_any_entities_deemed_hostile_to_usa === true ||
			company?.are_any_people_engaged_in_money_laundering === undefined ||
			company?.are_any_people_engaged_in_money_laundering === true ||
			company?.are_any_people_listed_in_ofac === undefined ||
			company?.are_any_people_listed_in_ofac === true ||
			company?.are_any_people_subject_to_usa_trade_restrictions === undefined ||
			company?.are_any_people_subject_to_usa_trade_restrictions === true ||
			company?.total_first_year_forecasted_sales === undefined
		);
	};

	const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				//eslint-disable-next-line
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const { mutate: analyticsMutate } = useMutation(
		"child-company-creation",
		({
			name,
			label,
			analyticsEventType,
		}: {
			name: string;
			label: string;
			analyticsEventType: "CLICK" | "VIEW" | "OTHER";
		}) => {
			return analyticsEventActions.create({
				name: name,
				label: label,
				url: window.location.href,
				extra: JSON.stringify({ companyName: company?.name }),
				analyticsEventType: analyticsEventType,
				userId: user!.id,
			});
		}
	);

	return (
		<Box>
			{id && user?.is_superuser && !isUser && (
				<Box sx={{ marginTop: 2, marginBottom: 6 }}>
					<CompanyAnalytics
						title={(company && company.name) ?? ""}
						companyId={id ? parseInt(id) : undefined}
					/>
				</Box>
			)}
			{!isUser && (
				<Heading
					title={id ? "Edit Company" : "Create Company"}
					showSearch={false}
					noMarginBottom
				/>
			)}

			<FormControl sx={{ width: "100%" }}>
				{!isUser && (
					<>
						<Typography
							variant={"h6"}
							sx={{
								textTransform: "uppercase",
								fontSize: 16,
								fontWeight: "bold",
								marginTop: 2,
								marginBottom: 1,
							}}
						>
							{t("companyOnboarding.companyDetails")}
						</Typography>
						<Divider sx={{ opacity: 0.5, marginBottom: 3 }} />
						<span className={classes.inputLabel}>
							{t("companyDetail.title")}
						</span>
						<TextField
							InputLabelProps={{ shrink: false }}
							fullWidth
							label=""
							id="title"
							value={company?.name}
							placeholder={t("companyDetail.titlePlaceholder")}
							onChange={(e) => {
								setCompany(
									(prevState) =>
										({
											...prevState,
											name: e.target.value,
										} as ICompany)
								);
							}}
							disabled={!editing}
						/>
						<span
							className={classes.inputLabel}
							style={{ display: "block", marginTop: "24px" }}
						>
							{t("companyDetail.description")}
						</span>
						<TextField
							InputLabelProps={{ shrink: false }}
							fullWidth
							label=""
							//multilines
							rows={3}
							id="description"
							value={company?.description}
							placeholder={t("companyDetail.descriptionPlaceholder")}
							onChange={(e) => {
								setCompany(
									(prevState) =>
										({
											...prevState,
											description: e.target.value,
										} as ICompany)
								);
							}}
							disabled={!editing}
						/>
						<Grid container spacing={2} sx={{ marginTop: 0 }}>
							<Grid item xs={12} sm={6} md={6}>
								<span className={classes.inputLabel}>
									{t("companyDetail.email")}
								</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="email"
									placeholder={t("companyDetail.emailPlaceholder")}
									sx={{ marginBottom: 2 }}
									value={company?.email}
									onChange={(e) => {
										setCompany(
											(prevState) =>
												({
													...prevState,
													email: e.target.value,
												} as ICompany)
										);
									}}
									disabled={!editing}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<span className={classes.inputLabel}>
									{t("companyDetail.phone")}
								</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="phone"
									placeholder={t("companyDetail.phonePlaceholder")}
									value={company?.phone}
									sx={{ marginBottom: 2 }}
									onChange={(e) => {
										setCompany(
											(prevState) =>
												({
													...prevState,
													phone: e.target.value,
												} as ICompany)
										);
									}}
									disabled={!editing}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={6}>
								<span className={classes.inputLabel}>
									{t("companyDetail.address")}
								</span>
								{company && (
									<Autocomplete
										id="autocomplete-address"
										value={inputValue}
										noOptionsText={
											isPlacePredictionsLoading
												? "Loading..."
												: "No address found"
										}
										options={placePredictions.map((place) => place.description)}
										sx={{ width: "100%", marginBottom: 2, height: 45 }}
										renderInput={(params) => (
											<TextField
												{...params}
												value={inputValue}
												placeholder={t("companyDetail.addressPlaceholder")}
												variant="outlined"
												InputLabelProps={{ shrink: false }}
												onChange={(e) => {
													const value = e.target.value;
													setInputValue(value);
													setCompany((prevState) => ({
														...prevState!,
														formatted_address: value,
													}));
													if (value.trim() !== "") {
														getPlacePredictions({ input: value });
													}
												}}
											/>
										)}
										onChange={(e, newValue) => {
											if (newValue) {
												setInputValue(newValue);

												setCompany((prevState) => ({
													...prevState!,
													formatted_address: newValue,
												}));
											}
										}}
										disabled={!editing}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<span className={classes.inputLabel}>
									{t("companyDetail.contactName")}
								</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="contact_name"
									placeholder={t("companyDetail.contactNamePlaceholder")}
									value={company?.contact_name}
									sx={{ marginBottom: 2 }}
									onChange={(e) => {
										setCompany(
											(prevState) =>
												({
													...prevState,
													contact_name: e.target.value,
												} as ICompany)
										);
									}}
									disabled={!editing}
								/>
							</Grid>
						</Grid>{" "}
						{user?.is_superuser && (
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={12}>
									<FormControlLabel
										control={
											<Switch
												checked={
													company?.historically_amazon_certified ? true : false
												}
												onChange={(e) => {
													setCompany(
														(prevState) =>
															({
																...prevState,
																historically_amazon_certified: e.target.checked
																	? true
																	: false,
															} as ICompany)
													);
												}}
												name="amazon-historical-data"
												disabled={!editing}
											/>
										}
										label={t("companyDetail.amazonCertified")}
										sx={{
											"& .MuiFormControlLabel-label": {
												fontSize: "14px",
											},
										}}
									/>
								</Grid>
							</Grid>
						)}
						{user?.is_superuser && (
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={12}>
									<FormControlLabel
										control={
											<Switch
												checked={
													(company?.country ?? "").toLowerCase() === "cn"
												}
												onChange={(e) => {
													setCompany(
														(prevState) =>
															({
																...prevState,
																country: e.target.checked ? "cn" : "",
															} as ICompany)
													);
												}}
												name="offsets-from-china"
												disabled={!editing}
											/>
										}
										label={t("companyDetail.offsetsfromChina")}
										sx={{
											"& .MuiFormControlLabel-label": {
												fontSize: "14px",
											},
										}}
									/>
								</Grid>
							</Grid>
						)}
					</>
				)}
				{(!isUser || (isUser && !user?.is_superuser)) && (
					<>
						<Typography
							variant={"h6"}
							sx={{
								textTransform: "uppercase",
								fontSize: 16,
								fontWeight: "bold",
								marginTop: 6,
								marginBottom: 1,
							}}
						>
							{t("companyOnboarding.attestationForm")}
						</Typography>
						<Divider sx={{ opacity: 0.5, marginBottom: 3 }} />
						<Box sx={{ marginTop: 2, marginBottom: 8 }}>
							<Box>
								<span className={classes.inputLabel}>
									{t("companyOnboarding.legallyRecognizedQuestion")}
								</span>
								<AttestationEntityArrayInput
									array={company?.legally_recognized_companies}
									setArray={(legallyRecognizedCompanies) => {
										setCompany(
											(prevState) =>
												({
													...prevState,
													legally_recognized_companies:
														legallyRecognizedCompanies,
												} as ICompany)
										);
									}}
									title={
										<>
											{t("companyOnboarding.legallyRecognizedCompaniesLabel")}{" "}
											<span className={classes.inputLabelRequired}>*</span>
										</>
									}
									required={{
										name: true,
										address: true,
										url: true,
										relationship: true,
									}}
									disabled={!editing}
								/>
								<AttestationEntityArrayInput
									array={company?.government_entities}
									setArray={(governmentEntities) => {
										setCompany(
											(prevState) =>
												({
													...prevState,
													government_entities: governmentEntities,
												} as ICompany)
										);
									}}
									title={
										<>
											{t("companyOnboarding.governmentEntitiesLabel")}{" "}
											<span className={classes.inputLabelRequired}>*</span>
										</>
									}
									required={{
										name: true,
										address: true,
										url: true,
										relationship: true,
									}}
									disabled={!editing}
								/>
							</Box>
							<Box sx={{ marginTop: 4 }}>
								<span
									style={{ paddingTop: "32px!important" }}
									className={classes.inputLabel}
								>
									{t("companyOnboarding.payorCompanyQuestion")}
								</span>
								<AttestationEntityArrayInput
									array={company?.payor_companies}
									setArray={(payorCompanies) => {
										setCompany(
											(prevState) =>
												({
													...prevState,
													payor_companies: payorCompanies,
												} as ICompany)
										);
									}}
									showRelationship
									title={t("companyOnboarding.payorCompanyLabel")}
									required={{
										name: false,
										address: false,
										url: false,
										relationship: false,
									}}
									disabled={!editing}
								/>
							</Box>
							<Box sx={{ marginTop: 4 }}>
								<span className={classes.inputLabel}>
									{t("companyOnboarding.amazonQuestion")}
									<span className={classes.inputLabelRequired}>*</span>
								</span>
								<ArrayInput
									array={company?.company_or_amazon_webpages}
									setArray={(webpages) => {
										setCompany(
											(prevState) =>
												({
													...prevState,
													company_or_amazon_webpages: webpages,
												} as ICompany)
										);
									}}
									title={t("companyOnboarding.amazonLabel")}
									disabled={!editing}
								/>
							</Box>
							<Box sx={{ marginTop: 4 }}>
								<span className={classes.inputLabel}>
									{t("companyOnboarding.representativeQuestion")}
								</span>
								<span className={classes.inputLabel}>
									{t("companyOnboarding.representativeNameLabel")}
									<span className={classes.inputLabelRequired}>*</span>
								</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									placeholder={t("companyOnboarding.representativeNameLabel")}
									value={company?.company_representative_name}
									sx={{ marginBottom: 2 }}
									onChange={(e) => {
										setCompany(
											(prevState) =>
												({
													...prevState,
													company_representative_name: e.target.value,
												} as ICompany)
										);
									}}
									disabled={!editing}
								/>
								<span className={classes.inputLabel}>
									{t("companyOnboarding.representativeTitleLabel")}
									<span className={classes.inputLabelRequired}>*</span>
								</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									placeholder={t("companyOnboarding.representativeTitleLabel")}
									value={company?.company_representative_title}
									sx={{ marginBottom: 2 }}
									onChange={(e) => {
										setCompany(
											(prevState) =>
												({
													...prevState,
													company_representative_title: e.target.value,
												} as ICompany)
										);
									}}
									disabled={!editing}
								/>
							</Box>
							<Box sx={{ marginTop: 4 }}>
								<span className={classes.inputLabel}>
									{t("companyOnboarding.entitiesQuestion")}
								</span>
								<FormControlLabel
									control={
										<ButtonGroup
											disableElevation
											variant="contained"
											aria-label={t("companyOnboarding.entitiesDeemedLabel")}
											sx={{
												"& button:not(:last-of-type)": {
													borderColor: "#bdbdbd!important",
												},
											}}
											disabled={!editing}
										>
											<Button
												onClick={() =>
													setCompany(
														(prevState) =>
															({
																...prevState,
																are_any_entities_deemed_hostile_to_usa: true,
															} as ICompany)
													)
												}
												color={
													company?.are_any_entities_deemed_hostile_to_usa
														? "primary"
														: "inherit"
												}
												sx={{
													backgroundColor:
														company?.are_any_entities_deemed_hostile_to_usa
															? "primary.main"
															: "#fafafa",
													color: company?.are_any_entities_deemed_hostile_to_usa
														? "common.white!important"
														: "black!important",
													"&:hover": {
														backgroundColor: "primary.main!important",
														color: "common.white!important",
													},
													"&:disabled": {
														backgroundColor:
															company?.are_any_entities_deemed_hostile_to_usa
																? "primary.main!important"
																: "#fafafa!important",
														color:
															company?.are_any_entities_deemed_hostile_to_usa
																? "common.white!important"
																: "black!important",
													},
												}}
											>
												{t("companyOnboarding.yes")}
											</Button>
											<Button
												onClick={() =>
													setCompany(
														(prevState) =>
															({
																...prevState,
																are_any_entities_deemed_hostile_to_usa: false,
															} as ICompany)
													)
												}
												sx={{
													backgroundColor:
														!company?.are_any_entities_deemed_hostile_to_usa &&
														company?.are_any_entities_deemed_hostile_to_usa !==
															undefined
															? "primary.main"
															: "#fafafa",
													color:
														!company?.are_any_entities_deemed_hostile_to_usa &&
														company?.are_any_entities_deemed_hostile_to_usa !==
															undefined
															? "common.white!important"
															: "black!important",
													"&:hover": {
														backgroundColor: "primary.main!important",
														color: "common.white!important",
													},
													"&:disabled": {
														backgroundColor:
															!company?.are_any_entities_deemed_hostile_to_usa &&
															company?.are_any_entities_deemed_hostile_to_usa !==
																undefined
																? "primary.main!important"
																: "#fafafa!important",
														color:
															!company?.are_any_entities_deemed_hostile_to_usa &&
															company?.are_any_entities_deemed_hostile_to_usa !==
																undefined
																? "common.white!important"
																: "black!important",
													},
												}}
											>
												{t("companyOnboarding.no")}
											</Button>
										</ButtonGroup>
									}
									label={
										<>
											{t("companyOnboarding.entitiesDeemedLabel")}{" "}
											<span className={classes.inputLabelRequired}>*</span>
										</>
									}
									sx={{
										paddingLeft: 1.5,
										marginTop: 2,
										"@media (max-width: 600px)": {
											marginBottom: 2,
										},
										"& .MuiFormControlLabel-label": {
											fontSize: "14px",
											paddingLeft: 1,
											fontWeight: "500",
										},
										display: "block",
									}}
								/>
								<FormControlLabel
									control={
										<ButtonGroup
											disableElevation
											variant="contained"
											aria-label={t("companyOnboarding.ofacLabel")}
											sx={{
												"& button:not(:last-of-type)": {
													borderColor: "#bdbdbd!important",
												},
											}}
											disabled={!editing}
										>
											<Button
												onClick={() =>
													setCompany(
														(prevState) =>
															({
																...prevState,
																are_any_people_listed_in_ofac: true,
															} as ICompany)
													)
												}
												color={
													company?.are_any_people_listed_in_ofac
														? "primary"
														: "inherit"
												}
												sx={{
													backgroundColor:
														company?.are_any_people_listed_in_ofac
															? "primary.main"
															: "#fafafa",
													color: company?.are_any_people_listed_in_ofac
														? "common.white!important"
														: "black!important",
													"&:hover": {
														backgroundColor: "primary.main!important",
														color: "common.white!important",
													},
													"&:disabled": {
														backgroundColor:
															company?.are_any_people_listed_in_ofac
																? "primary.main!important"
																: "#fafafa!important",
														color: company?.are_any_people_listed_in_ofac
															? "common.white!important"
															: "black!important",
													},
												}}
											>
												{t("companyOnboarding.yes")}
											</Button>
											<Button
												onClick={() =>
													setCompany(
														(prevState) =>
															({
																...prevState,
																are_any_people_listed_in_ofac: false,
															} as ICompany)
													)
												}
												sx={{
													backgroundColor:
														!company?.are_any_people_listed_in_ofac &&
														company?.are_any_people_listed_in_ofac !== undefined
															? "primary.main"
															: "#fafafa",
													color:
														!company?.are_any_people_listed_in_ofac &&
														company?.are_any_people_listed_in_ofac !== undefined
															? "common.white!important"
															: "black!important",
													"&:hover": {
														backgroundColor: "primary.main!important",
														color: "common.white!important",
													},
													"&:disabled": {
														backgroundColor:
															!company?.are_any_people_listed_in_ofac &&
															company?.are_any_people_listed_in_ofac !==
																undefined
																? "primary.main!important"
																: "#fafafa!important",
														color:
															!company?.are_any_people_listed_in_ofac &&
															company?.are_any_people_listed_in_ofac !==
																undefined
																? "common.white!important"
																: "black!important",
													},
												}}
											>
												{t("companyOnboarding.no")}
											</Button>
										</ButtonGroup>
									}
									label={
										<>
											{t("companyOnboarding.ofacLabel")}{" "}
											<a href="https://us.practicallaw.thomsonreuters.com/Document/Ibb0a1462ef0511e28578f7ccc38dcbee/View/FullText.html?originationContext=document&vr=3.0&rs=cblt1.0&transitionType=DocumentItem&contextData=(sc.Search)">
												Office of Foreign Assets Control
											</a>{" "}
											{t("companyOnboarding.ofacLabel2")}
											<span className={classes.inputLabelRequired}>*</span>
										</>
									}
									sx={{
										paddingLeft: 1.5,
										marginTop: 2,
										"@media (max-width: 600px)": {
											marginBottom: 2,
										},
										"& .MuiFormControlLabel-label": {
											fontSize: "14px",
											paddingLeft: 1,
											fontWeight: "500",
										},
										display: "block",
									}}
								/>
								<FormControlLabel
									control={
										<ButtonGroup
											disableElevation
											variant="contained"
											aria-label={t("companyOnboarding.moneyLaunderingLabel")}
											sx={{
												"& button:not(:last-of-type)": {
													borderColor: "#bdbdbd!important",
												},
											}}
											disabled={!editing}
										>
											<Button
												onClick={() =>
													setCompany(
														(prevState) =>
															({
																...prevState,
																are_any_people_engaged_in_money_laundering:
																	true,
															} as ICompany)
													)
												}
												color={
													company?.are_any_people_engaged_in_money_laundering
														? "primary"
														: "inherit"
												}
												sx={{
													backgroundColor:
														company?.are_any_people_engaged_in_money_laundering
															? "primary.main"
															: "#fafafa",
													color:
														company?.are_any_people_engaged_in_money_laundering
															? "common.white!important"
															: "black!important",
													"&:hover": {
														backgroundColor: "primary.main!important",
														color: "common.white!important",
													},
													"&:disabled": {
														backgroundColor:
															company?.are_any_people_engaged_in_money_laundering
																? "primary.main!important"
																: "#fafafa!important",
														color:
															company?.are_any_people_engaged_in_money_laundering
																? "common.white!important"
																: "black!important",
													},
												}}
											>
												{t("companyOnboarding.yes")}
											</Button>
											<Button
												onClick={() =>
													setCompany(
														(prevState) =>
															({
																...prevState,
																are_any_people_engaged_in_money_laundering:
																	false,
															} as ICompany)
													)
												}
												sx={{
													backgroundColor:
														!company?.are_any_people_engaged_in_money_laundering &&
														company?.are_any_people_engaged_in_money_laundering !==
															undefined
															? "primary.main"
															: "#fafafa",
													color:
														!company?.are_any_people_engaged_in_money_laundering &&
														company?.are_any_people_engaged_in_money_laundering !==
															undefined
															? "common.white!important"
															: "black!important",
													"&:hover": {
														backgroundColor: "primary.main!important",
														color: "common.white!important",
													},
													"&:disabled": {
														backgroundColor:
															!company?.are_any_people_engaged_in_money_laundering &&
															company?.are_any_people_engaged_in_money_laundering !==
																undefined
																? "primary.main!important"
																: "#fafafa!important",
														color:
															!company?.are_any_people_engaged_in_money_laundering &&
															company?.are_any_people_engaged_in_money_laundering !==
																undefined
																? "common.white!important"
																: "black!important",
													},
												}}
											>
												{t("companyOnboarding.no")}
											</Button>
										</ButtonGroup>
									}
									label={
										<>
											{t("companyOnboarding.moneyLaunderingLabel")}{" "}
											<span className={classes.inputLabelRequired}>*</span>
										</>
									}
									sx={{
										paddingLeft: 1.5,
										marginTop: 2,
										"@media (max-width: 600px)": {
											marginBottom: 2,
										},
										"& .MuiFormControlLabel-label": {
											fontSize: "14px",
											paddingLeft: 1,
											fontWeight: "500",
										},
										display: "block",
									}}
								/>
								<FormControlLabel
									control={
										<ButtonGroup
											disableElevation
											variant="contained"
											aria-label={t("companyOnboarding.tradeRestrictionsLabel")}
											sx={{
												"& button:not(:last-of-type)": {
													borderColor: "#bdbdbd!important",
												},
											}}
											disabled={!editing}
										>
											<Button
												onClick={() =>
													setCompany(
														(prevState) =>
															({
																...prevState,
																are_any_people_subject_to_usa_trade_restrictions:
																	true,
															} as ICompany)
													)
												}
												color={
													company?.are_any_people_subject_to_usa_trade_restrictions
														? "primary"
														: "inherit"
												}
												sx={{
													backgroundColor:
														company?.are_any_people_subject_to_usa_trade_restrictions
															? "primary.main"
															: "#fafafa",
													color:
														company?.are_any_people_subject_to_usa_trade_restrictions
															? "common.white!important"
															: "black!important",
													"&:hover": {
														backgroundColor: "primary.main!important",
														color: "common.white!important",
													},
													"&:disabled": {
														backgroundColor:
															company?.are_any_people_subject_to_usa_trade_restrictions
																? "primary.main!important"
																: "#fafafa!important",
														color:
															company?.are_any_people_subject_to_usa_trade_restrictions
																? "common.white!important"
																: "black!important",
													},
												}}
											>
												{t("companyOnboarding.yes")}
											</Button>
											<Button
												onClick={() =>
													setCompany(
														(prevState) =>
															({
																...prevState,
																are_any_people_subject_to_usa_trade_restrictions:
																	false,
															} as ICompany)
													)
												}
												sx={{
													backgroundColor:
														!company?.are_any_people_subject_to_usa_trade_restrictions &&
														company?.are_any_people_subject_to_usa_trade_restrictions !==
															undefined
															? "primary.main"
															: "#fafafa",
													color:
														!company?.are_any_people_subject_to_usa_trade_restrictions &&
														company?.are_any_people_subject_to_usa_trade_restrictions !==
															undefined
															? "common.white!important"
															: "black!important",
													"&:hover": {
														backgroundColor: "primary.main!important",
														color: "common.white!important",
													},
													"&:disabled": {
														backgroundColor:
															!company?.are_any_people_subject_to_usa_trade_restrictions &&
															company?.are_any_people_subject_to_usa_trade_restrictions !==
																undefined
																? "primary.main!important"
																: "#fafafa!important",
														color:
															!company?.are_any_people_subject_to_usa_trade_restrictions &&
															company?.are_any_people_subject_to_usa_trade_restrictions !==
																undefined
																? "common.white!important"
																: "black!important",
													},
												}}
											>
												{t("companyOnboarding.no")}
											</Button>
										</ButtonGroup>
									}
									label={
										<>
											{t("companyOnboarding.tradeRestrictionsLabel")}{" "}
											<span className={classes.inputLabelRequired}>*</span>
										</>
									}
									sx={{
										paddingLeft: 1.5,
										marginTop: 2,
										"@media (max-width: 600px)": {
											marginBottom: 2,
										},
										"& .MuiFormControlLabel-label": {
											fontSize: "14px",
											paddingLeft: 1,
											fontWeight: "500",
										},
										display: "block",
									}}
								/>
							</Box>

							<Box sx={{ marginTop: 6 }}>
								<span className={classes.inputLabel}>
									{t("companyOnboarding.numberOfSalesQuestion")}
									<span className={classes.inputLabelRequired}>*</span>
								</span>
								<NumericFormat
									value={company?.total_first_year_forecasted_sales}
									name={"amount"}
									placeholder={t("companyOnboarding.numberOfSalesLabel")}
									customInput={TextField}
									type="text"
									thousandSeparator={","}
									allowNegative={false}
									fullWidth
									onValueChange={({ floatValue }) => {
										setCompany(
											(prevState) =>
												({
													...prevState,
													total_first_year_forecasted_sales: floatValue
														? Math.floor(floatValue)
														: undefined,
												} as ICompany)
										);
									}}
									style={{
										flex: 1,
										minWidth: "140px",
									}}
									disabled={!editing}
								/>
							</Box>
							{user?.is_superuser && (
								<Box sx={{ marginTop: 6 }}>
									<span className={classes.inputLabel}>
										{t("companyOnboarding.attestationFormApprovedDate")}
									</span>
									<TextField
										InputLabelProps={{ shrink: false }}
										fullWidth
										label=""
										type="date"
										placeholder={t(
											"companyOnboarding.attestationFormApprovedDate"
										)}
										value={company?.attestation_form_approved}
										sx={{ marginBottom: 2 }}
										onChange={(e) => {
											setCompany(
												(prevState) =>
													({
														...prevState,
														attestation_form_approved: e.target.value,
													} as ICompany)
											);
										}}
										InputProps={{
											inputProps: {
												max: `${new Date().toISOString().split("T")[0]}`,
												min: "2021-01-01",
												style: { minWidth: "150px" },
											},
										}}
										disabled={!editing}
									/>
								</Box>
							)}
						</Box>
					</>
				)}
				{!isUser && (
					<Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
						{id && editing && user?.is_superuser && (
							<Button
								variant="outlined"
								onClick={() => {
									setEditing(false);
								}}
								sx={{
									marginTop: 2,
									minHeight: 34,
									maxWidth: 300,
									alignSelf: "end",
								}}
							>
								{t("companyDetail.cancel")}
							</Button>
						)}
						<Button
							variant="contained"
							onClick={() => {
								if (editing && !user?.is_superuser) {
									analyticsMutate({
										name: "CREATE_CHILD_COMPANY_" + company?.name,
										label: "CREATE_CHILD_COMPANY",
										analyticsEventType: "CLICK",
									});
								}
								if (id && !editing) {
									setEditing(true);
								} else {
									if (!validateEmail(company?.email ?? "")) {
										enqueueSnackbar("Email field is incorrect.", {
											variant: "error",
										});
									} else {
										mutate();
									}
								}
							}}
							sx={{
								marginTop: 2,
								minHeight: 34,
								maxWidth: 300,
								alignSelf: "end",
							}}
							disabled={editing && validateCompany()}
						>
							{isLoading ? (
								<CircularProgress size={12} sx={{ color: "white" }} />
							) : id ? (
								editing ? (
									t("companyDetail.save")
								) : (
									t("companyDetail.edit")
								)
							) : (
								t("companyDetail.create")
							)}
						</Button>
					</Box>
				)}
			</FormControl>

			{id && (
				<>
					{!isUser && (
						<>
							<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
							<Box
								sx={{
									display: "flex",
									marginBottom: 2,
									flexDirection: "column",
								}}
							>
								<Typography
									variant="h4"
									sx={{ fontWeight: 600, marginBottom: 4 }}
								>
									{t("companyDetail.certifications")}
								</Typography>
								<ClimecoCertifications companyId={parseInt(id!)} />
							</Box>
							<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
							<Box
								sx={{
									display: "flex",
									marginBottom: 2,
									flexDirection: "column",
								}}
							>
								<Typography
									variant="h4"
									sx={{ fontWeight: 600, marginBottom: 4 }}
								>
									{t("companyDetail.externalCertifications")}
								</Typography>
								<Certifications companyId={parseInt(id!)} />
							</Box>
							<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
							<Typography
								variant="h4"
								sx={{ fontWeight: 600, marginBottom: 2 }}
							>
								{t("companyDetail.offsetsBalance")}
							</Typography>
							<Box
								sx={{
									padding: 2,
									background: "#efefef",
									display: "flex",
									gap: 8,
									justifyContent: "flex-end",
									borderRadius: 1,
									overflowX: "auto",
									flexDirection: {
										xs: "column",
										sm: "row",
									},
									textAlign: {
										xs: "right",
										sm: "left",
									},
									marginBottom: 1,
								}}
							>
								<Box sx={{ textAlign: "center" }}>
									<Typography fontWeight={"bold"} fontSize={14}>
										{t("companyDetail.forecastedEmissions")}{" "}
										<span style={{ fontWeight: "normal" }}> (mtCO2e)</span>
										<Tooltip
											sx={{ padding: 0 }}
											title={t("companyDetail.forecastedEmissionsTooltip")}
										>
											<IconButton sx={{ height: 12 }}>
												<InfoIcon sx={{ fontSize: 14 }} />
											</IconButton>
										</Tooltip>
									</Typography>
									<Typography fontSize={14}>
										{balance?.estimated_amount
											? Intl.NumberFormat("en", {
													maximumFractionDigits: 0,
											  }).format(Math.ceil(balance.estimated_amount))
											: "--"}
									</Typography>
								</Box>
								<Box sx={{ textAlign: "center" }}>
									<Typography fontWeight={"bold"} fontSize={14}>
										{t("companyDetail.actualEmissions")}{" "}
										<span style={{ fontWeight: "normal" }}>(mtCO2e)</span>
										<Tooltip
											sx={{ padding: 0 }}
											title={t("companyDetail.actualEmissionsTooltip")}
										>
											<IconButton sx={{ height: 12 }}>
												<InfoIcon sx={{ fontSize: 14 }} />
											</IconButton>
										</Tooltip>
									</Typography>
									<Typography fontSize={14}>
										{balance?.sales_amount
											? Intl.NumberFormat("en", {
													maximumFractionDigits: 0,
											  }).format(Math.ceil(balance.sales_amount))
											: "--"}
									</Typography>
								</Box>
								{/*<Box sx={{ textAlign: "center" }}>
							<Typography fontWeight={"bold"} fontSize={14}>
								Retired Emissions{" "}
								<span style={{ fontWeight: "normal" }}>(mtCO2e)</span>
							</Typography>
							<Typography fontSize={14}>
								{balance?.purchased_offsets
									? Intl.NumberFormat("en", {
											maximumFractionDigits: 0,
									  }).format(Math.ceil(balance.purchased_offsets))
									: "--"}
							</Typography>
						</Box>
						<Box sx={{ textAlign: "center" }}>
							<Typography fontWeight={"bold"} fontSize={14}>
								Difference{" "}
								<span style={{ fontWeight: "normal" }}>(mtCO2e)</span>
							</Typography>
							<Typography fontSize={14}>
								{Intl.NumberFormat("en", {
									maximumFractionDigits: 0,
								}).format(
									(() => {
										const num =
											(balance?.purchased_offsets ?? 0) -
											(balance?.sales_amount ?? 0) -
											(balance?.estimated_amount ?? 0);
										return Math.sign(num) * Math.ceil(Math.abs(num));
									})()
								)}
							</Typography>
						</Box>*/}

								<Box sx={{ textAlign: "center" }}>
									<Typography fontWeight={"bold"} fontSize={14}>
										{t("companyDetail.purchasedOffsets")}{" "}
										<span style={{ fontWeight: "normal" }}>(mtCO2e)</span>
										<Tooltip
											sx={{ padding: 0 }}
											title={t("companyDetail.purchasedOffsetsTooltip")}
										>
											<IconButton sx={{ height: 12 }}>
												<InfoIcon sx={{ fontSize: 14 }} />
											</IconButton>
										</Tooltip>
									</Typography>
									<Typography fontSize={14}>
										{balance?.offsets_amount
											? Intl.NumberFormat("en", {
													maximumFractionDigits: 0,
											  }).format(
													Math.ceil(
														balance.offsets_amount + balance.purchased_offsets
													)
											  )
											: "--"}
									</Typography>
								</Box>
								<Box sx={{ textAlign: "center" }}>
									<Typography fontWeight={"bold"} fontSize={14}>
										{t("companyDetail.balance")}{" "}
										<span style={{ fontWeight: "normal" }}>(mtCO2e)</span>
										<Tooltip
											sx={{ padding: 0 }}
											title={t("companyDetail.balanceTooltip")}
										>
											<IconButton sx={{ height: 12 }}>
												<InfoIcon sx={{ fontSize: 14 }} />
											</IconButton>
										</Tooltip>
									</Typography>
									<Typography
										fontSize={14}
										sx={{
											color:
												Math.ceil(
													(balance?.purchased_offsets ?? 0) -
														(balance?.sales_amount ?? 0) +
														(balance?.offsets_amount ?? 0) /*-
												(balance?.estimated_amount ?? 0)*/
												) >= -0
													? "inherit"
													: "red",
										}}
									>
										{Intl.NumberFormat("en", {
											maximumFractionDigits: 0,
										}).format(
											(() => {
												const num =
													Math.sign(
														(balance?.purchased_offsets ?? 0) -
															(balance?.sales_amount ?? 0) -
															(balance?.estimated_amount ?? 0)
													) *
														Math.ceil(
															Math.abs(
																(balance?.purchased_offsets ?? 0) -
																	(balance?.sales_amount ?? 0) /*-
															(balance?.estimated_amount ?? 0)*/
															)
														) +
													Math.ceil(balance?.offsets_amount ?? 0);
												return Math.ceil(num) === -0
													? 0
													: Math.sign(num) * Math.ceil(Math.abs(num));
											})()
										)}
									</Typography>
								</Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									marginTop: 2,
									gap: 2,
									maxWidth: 600,
									margin: "auto",
								}}
							>
								<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
									<span className={classes.inputLabel}>Offsets</span>
									<NumericFormat
										value={offsets}
										placeholder="Amount of offsets to add"
										customInput={TextField}
										onValueChange={({ value }) => {
											setOffsets(parseInt(value));
										}}
										style={{}}
										allowNegative={true}
										decimalScale={0}
										thousandSeparator={","}
									/>
								</Box>
								<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
									<span className={classes.inputLabel}>Description</span>
									<TextField
										InputLabelProps={{ shrink: false }}
										fullWidth
										label=""
										id="description"
										value={offsetsDescription}
										placeholder="Description"
										onChange={(e) => {
											setOffsetsDescription(e.target.value);
										}}
									/>
								</Box>
								<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
									<span className={classes.inputLabel}>Price per Offset</span>
									<NumericFormat
										value={offsetsPrice}
										placeholder="Price per Offset"
										customInput={TextField}
										onValueChange={({ value }) => {
											setOffsetsPrice(parseFloat(value));
										}}
										style={{}}
										allowNegative={false}
										decimalScale={2}
										thousandSeparator={","}
									/>
								</Box>
								<Button
									variant="outlined"
									sx={{
										maxWidth: 300,
										alignSelf: "end",
										minHeight: 34,
									}}
									disabled={isLoadingMutateAddOffsets || !offsets || !id}
									onClick={() => {
										mutateAddOffsets();
									}}
								>
									{isLoadingMutateAddOffsets ? (
										<CircularProgress size={12} />
									) : (
										"Add offsets"
									)}
								</Button>
							</Box>
							<Box sx={{ marginTop: 2 }}>
								<span
									style={{ marginBottom: 8 }}
									className={classes.inputLabel}
								>
									All company offsets
								</span>
								<br />
								{companyOffsets && companyOffsets.length > 0 ? (
									<Table sx={{ marginBottom: 2 }}>
										<TableHead>
											<TableCell>Name</TableCell>
											<TableCell>Description</TableCell>
											<TableCell>Quantity</TableCell>
											<TableCell>Payment</TableCell>
											<TableCell>Date</TableCell>
											<TableCell>Added by</TableCell>
										</TableHead>
										{companyOffsets?.map((offset) => (
											<TableRow key={offset.id}>
												<TableCell>{offset.name}</TableCell>
												<TableCell sx={{ maxWidth: 200 }}>
													{offset.description}
												</TableCell>
												<TableCell>
													{(() => {
														const formattedValue = Intl.NumberFormat("en", {
															maximumFractionDigits: 0,
														}).format(offset.value);

														if (offset.name === "Products Offsets") {
															return `${formattedValue} product(s)`;
														} else if (offset.name === "First Time Offsets") {
															return `${formattedValue} unit`;
														} else if (
															offset.name === "ClimeCo Offsets" ||
															offset.name.toLowerCase().includes("adjustment")
														) {
															return `${formattedValue} mtCO2`;
														} else {
															return `${formattedValue}`;
														}
													})()}
												</TableCell>
												<TableCell>
													{Intl.NumberFormat("en", {
														maximumFractionDigits: 2,
														style: "currency",
														currency: "USD",
													}).format(offset.amount)}
												</TableCell>
												<TableCell>
													{new Intl.DateTimeFormat("en", {
														day: "2-digit",
														month: "2-digit",
														year: "numeric",
														timeZone: "UTC",
													}).format(new Date(offset.created_at))}
												</TableCell>
												<TableCell>{offset.admin?.email ?? "--"}</TableCell>
											</TableRow>
										))}
									</Table>
								) : companyOffsets && companyOffsets.length === 0 ? (
									<Typography variant="body2">No offsets yet</Typography>
								) : null}
							</Box>
						</>
					)}
					{(!isUser || (isUser && !user?.is_superuser)) && (
						<>
							<Divider
								sx={{
									marginTop: 4,
									marginBottom: 4,
									borderColor: "rgba(0, 0, 0, 0.12)",
								}}
							/>

							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									marginBottom: 2,
								}}
							>
								<Typography variant="h4" sx={{ fontWeight: 600 }}>
									{t("companyDetail.contractDocuments")}
								</Typography>
								{!isUser && (
									<Button
										variant="outlined"
										sx={{
											maxWidth: 300,
											alignSelf: "end",
											marginRight: 2,
										}}
										onClick={() => {
											setShowContractDocumentsModal(true);
										}}
									>
										{t("companyDetail.addNewDocument")}
									</Button>
								)}
							</Box>

							{contractDocuments && contractDocuments.length > 0 ? (
								<TableContainer>
									<Table
										sx={{ border: "none", borderBottom: "none" }}
										size="small"
									>
										<TableBody>
											{contractDocuments.map((document, index) => (
												<TableRow key={document.id}>
													<TableCell sx={{ borderBottom: "none" }}>
														{document.name ? (
															<a
																target="_blank"
																href={
																	document.file
																		? (document.file as string)
																		: document.image
																		? (document.image as string)
																		: "#"
																}
																rel="noreferrer"
															>
																<Typography variant="body2">
																	{document.name}
																</Typography>
															</a>
														) : (
															<a
																href={
																	document.file
																		? (document.file as string)
																		: document.image
																		? (document.image as string)
																		: "#"
																}
																style={{
																	textOverflow: "ellipsis",
																	overflow: "hidden",
																}}
																download
															>
																<Typography variant="body2">
																	{document.file
																		? (document.file as string).substring(
																				(document.file as string).length - 20
																		  )
																		: document.image
																		? (document.image as string).substring(
																				(document.image as string).length - 20
																		  )
																		: "Empty document (TO BE DELETED)"}
																	...
																</Typography>
															</a>
														)}
													</TableCell>
													<TableCell
														align="center"
														sx={{ borderBottom: "none" }}
													>
														<Typography
															variant="body2"
															sx={{ textAlign: "left" }}
														>
															{document.certification_process?.name ?? "--"}
														</Typography>
													</TableCell>
													<TableCell
														align="center"
														sx={{ borderBottom: "none" }}
													>
														{!isUser && (
															<IconButton
																aria-label="delete document"
																onClick={() => {
																	setShowDeleteModal(true);
																}}
																sx={{ fontSize: 16 }}
															>
																<DeleteIcon />
															</IconButton>
														)}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								<Typography variant="body2">
									{t("companyDetail.noContractDocuments")}
								</Typography>
							)}

							<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									marginBottom: 2,
								}}
							>
								<Typography variant="h4" sx={{ fontWeight: 600 }}>
									{t("companyDetail.lcaDocuments")}
								</Typography>
							</Box>

							{LCADocuments && LCADocuments.length > 0 ? (
								LCADocuments.map((document) => (
									<Box key={document.id}>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "space-between",
												maxWidth: 1000,
											}}
										>
											<a
												href={
													document.lca_versions &&
													document.lca_versions.length > 0 &&
													(document.lca_versions[0].lca_document as string) !==
														""
														? (document.lca_versions[0].lca_document as string)
														: "-"
												}
												onClick={(e) => {
													if (
														!(document?.lca_versions &&
														document.lca_versions.length > 0 &&
														(document.lca_versions[0]
															.lca_document as string) !== ""
															? false
															: true)
													) {
														e.preventDefault();
													}
												}}
												style={{ textOverflow: "ellipsis", overflow: "hidden" }}
												download
											>
												<Typography variant="body2">
													{document.lca_versions &&
													document.lca_versions.length > 0
														? (document.lca_versions[0].lca_document as string)
														: "Empty document (TO BE DELETED)"}
												</Typography>
											</a>
											{!isUser && (
												<IconButton
													aria-label="delete document"
													onClick={() => {
														setShowDeleteModal(true);
													}}
													sx={{ fontSize: 16 }}
												>
													<DeleteIcon />
												</IconButton>
											)}
										</Box>
									</Box>
								))
							) : (
								<Typography variant="body2">
									{t("companyDetail.noLcaDocuments")}
								</Typography>
							)}
							<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
						</>
					)}
				</>
			)}
			<BasicModal
				width={600}
				showModal={showDeleteModal}
				handleClose={() => {
					setShowDeleteModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("companyDetail.confirmDeleteDocument")}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowDeleteModal(false);
								}}
							>
								{t("companyDetail.cancel")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={true}
								onClick={() => {}}
							>
								{t("companyDetail.delete")}
							</Button>
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={600}
				showModal={showContractDocumentsModal}
				handleClose={() => {
					setShowContractDocumentsModal(false);
				}}
				children={
					<ContractDocuments
						companyId={parseInt(id ?? "-1")}
						closeModal={() => {
							setShowContractDocumentsModal(false);
							refetchDocuments();
						}}
					/>
				}
			/>
		</Box>
	);
}
