import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { PropTypes } from "./UplaodProductsCSV.types";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export function UploadProductsCSV({ setHandleEvent, setTabValue }: PropTypes) {
	const { t } = useTranslation();
	return (
		<Box sx={{ padding: 4 }}>
			<label
				htmlFor="upload-csv"
				style={{
					width: "100%",
					display: "block",
					cursor: "pointer",
				}}
			>
				<input
					accept=".csv"
					style={{ display: "none" }}
					id="upload-csv"
					type="file"
					onChange={(event) => {
						console.log("FIRING EVENT");
						console.log(event);

						setTabValue(1);

						setTimeout(() => {
							setHandleEvent(event);
						}, 500);
					}}
				/>
				<Box
					sx={{
						width: "100%",
						textAlign: "center",
						padding: 4,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='99.8%25' height='99.8%25' fill='none' rx='6' ry='6' stroke='%23e3e3e3' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
						borderRadius: "6px",
						cursor: "pointer",
					}}
				>
					<Box
						sx={{
							marginBottom: 2,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<UploadFileIcon sx={{ fontSize: 24, color: "#003899" }} />

						<Typography
							variant="body2"
							sx={{
								marginBottom: 1,
								fontWeight: "bold",
								color: "#003899",
							}}
						>
							{t("bulkProductsTable.uploadYourProductsInBulk")}
						</Typography>
						<Typography
							variant="body2"
							sx={{
								color: "#757575",
							}}
						>
							{t("bulkProductsTable.saveYourExcelAsCSV")}
						</Typography>
					</Box>
				</Box>
			</label>
			<Tooltip title={t("getCertified.downloadTemplateTooltip")}>
				<Typography
					variant="body2"
					sx={{
						marginTop: 2,
						marginBottom: 2,
						color: "#003899",
						cursor: "pointer",
						textDecoration: "underline",
					}}
					onClick={() => {
						const link = document.createElement("a");
						link.href = `${process.env.PUBLIC_URL}/assets/Bulk Product Upload Template.xlsx`;
						link.download = "Bulk Product Upload Template.xlsx";
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}}
				>
					{t("getCertified.downloadTemplate")}
				</Typography>
			</Tooltip>
		</Box>
	);
}
