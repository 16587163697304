import { useContext, useState, useEffect } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	Modal,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { BasicModal, MultiItemSelector } from "..";
import {
	ICertification,
	ICertificationSteps,
	IEstimation,
	IGetAmazonCertifiedStep,
	IProduct,
	IStep,
} from "../../models";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
	certification as certificationActions,
	estimation as estimationsActions,
	product as productActions,
	certification as certificationAction,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import HomeIcon from "@mui/icons-material/Home";
import Logo from "../../assets/logo-vertical.png";
import CloseIcon from "@mui/icons-material/Close";
import SelectMarketplaces from "../SelectMarketplaces/SelectMarketplaces";
import { PropTypes } from "./GetAmazonCertified.types";
import CheckCircle from "@mui/icons-material/CheckCircle";
import QueryBuilder from "@mui/icons-material/QueryBuilder";
import AmazonCertificationComplete from "../AmazonCertificationComplete/AmazonCertificationComplete";
import { useTranslation } from "react-i18next";

type Provider = {
	id: number;
	name: string;
	certification_type: string;
};

const steps = [
	{
		title: "In what marketplace(s) would you like to be certified?",
		description: "Choose all that apply",
		step: IGetAmazonCertifiedStep.CHOOSE_MARKETPLACE,
		size: "700px",
	},
	{
		title: "Confirm Your ASIN Numbers",
		description: "",
		step: IGetAmazonCertifiedStep.SELECT_PRODUCTS,
		size: "900px",
	},
	{
		title: "Waiting for badge confirmation",
		description:
			"Please confirm here when you are able to see your Climate Pledge Friendly badge on your product's Amazon page.",
		step: IGetAmazonCertifiedStep.PRODUCT_LIST,
		size: "900px",
	},
	{
		title: "Certification completed",
		description:
			"Congratulations! Your submitted products are now Amazon Climate Pledge Friendly Certified",
		step: IGetAmazonCertifiedStep.COMPLETED,
		size: "900px",
	},
];

const useStyles = makeStyles({
	logo: {
		height: "auto",
		width: 70,
		objectFit: "contain",
	},
});

export default function GetAmazonCertified({ defaultOpen }: PropTypes) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(defaultOpen ?? false);
	const [completeOpen, setCompleteOpen] = useState(false);
	const location = useLocation();
	const getCurrentStepFromLocation = (
		path: string,
		certification: ICertification | null
	): number => {
		if (path.includes("/create")) {
			return 0;
		} else {
			if (certification) {
				let step = 0;
				const pendingStep = certification.certification_process_steps.findIndex(
					(step) => step.status === "PENDING"
				);

				step =
					pendingStep > -1
						? pendingStep
						: certification.certification_process_steps.length - 1;

				switch (
					certification.certification_process_steps[step].step.step_type
				) {
					case "CERTIFICATION_SUMMARY":
						return 2;
					default:
						return 3;
				}
			} else {
				return 0;
			}
		}
	};
	const getCertificationProcessIdFromLocation = (path: string): number => {
		if (
			!path.includes("/create") &&
			path.split("/").length > 2 &&
			!isNaN(parseInt(path.split("/")[2]))
		) {
			return parseInt(path.split("/")[2]);
		} else {
			return -1;
		}
	};
	const [certificationProcessId] = useState(
		getCertificationProcessIdFromLocation(location.pathname)
	);

	const classes = useStyles();
	const handleClose = () => {
		navigate("/");
		setOpen(false);
	};
	const [selectedMarketplaces, setSelectedMarketplaces] = useState<number[]>(
		[]
	);

	const { id } = useParams();
	const [certification, setCertification] = useState<ICertification | null>(
		null
	);
	const [currentStep, setCurrentStep] = useState(
		getCurrentStepFromLocation(location.pathname, certification)
	);

	const [productsFilter, setProductsFilter] = useState("");
	const [currentProducts, setCurrentProducts] = useState<Array<IProduct>>([]);

	const [originalEstimations] = useState<Array<IEstimation>>([]);
	const [products, setProducts] = useState<Array<IProduct>>([]);

	const [reloadCertification, setReloadCertification] = useState(true);
	const { user } = useContext(UserContext);

	const [providers, setProviders] = useState<Provider[]>([]);

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoadingSelectedProducts, setIsLoadingSelectedProducts] =
		useState(false);

	useEffect(() => {
		setCurrentStep(
			getCurrentStepFromLocation(location.pathname, certification)
		);
	}, [location, certification]);

	useEffect(() => {
		if (currentStep === 3) {
			setCompleteOpen(true);
		}
	}, [currentStep]);

	useQuery(
		"providers",
		() =>
			certificationAction.listProviders({
				certification_type: "DEFAULT_EXTERNAL_CERTIFICATION",
			}),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProviders(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const marketplaces = [
		{ id: 1, name: "United States", domain: "https://www.amazon.com/dp/" },
		{ id: 3, name: "United Kingdom", domain: "https://www.amazon.co.uk/dp/" },
		{ id: 4, name: "Germany", domain: "https://www.amazon.de/dp/" },
		{ id: 35691, name: "Italy", domain: "https://www.amazon.it/dp/" },
		{ id: 44551, name: "Spain", domain: "https://www.amazon.es/dp/" },
	];

	const getMarketPlace = (id: number) => {
		return marketplaces.find((marketplace) => marketplace.id === id)!.name;
	};

	const { refetch: refetchActiveProducts, isLoading: isLoadingProducts } =
		useQuery(
			"products-active-amazon",
			() =>
				productActions.list(
					user?.is_superuser ? certification?.company.id : user?.company.id,
					undefined,
					[],
					"ClimeCo certified",
					1,
					undefined,
					1000
				),
			{
				enabled: false,
				refetchOnWindowFocus: false,
				retry: false,
				onSuccess: (res) => {
					const products = res.results.filter(
						(product) =>
							product.certification_processes?.find(
								(certificationProcess) =>
									certificationProcess.certification?.certification_type ===
										"CLIMECO_INTERNAL_CERTIFICATION" &&
									!certificationProcess.certification_process_steps.find(
										(certificationProcessStep) =>
											certificationProcessStep.status !== "COMPLETED"
									)
							) &&
							!product.certification_processes?.find(
								(certificationProcess) =>
									certificationProcess.certification?.certification_type ===
									"DEFAULT_EXTERNAL_CERTIFICATION"
							)
					);
					setProducts(products);
					setCurrentProducts(products);
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar(t("general.errorMessage"), {
						variant: "error",
					});
				},
			}
		);

	useEffect(() => {
		if (certification) {
			refetchActiveProducts();
		}
	}, [certification, refetchActiveProducts]);

	const { refetch: refetchCertification } = useQuery(
		"climeco-certifications-detail",
		() =>
			certificationActions.getOne(
				false,
				parseInt(id!),
				user?.is_superuser ? undefined : user?.company.id
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				let step = 0;
				const rejectedStep = res.certification_process_steps.findIndex(
					(step) => step.status === "REJECTED"
				);

				const pendingStep = res.certification_process_steps.findIndex(
					(step) => step.status === "PENDING"
				);

				step =
					rejectedStep > -1
						? rejectedStep
						: pendingStep > -1
						? pendingStep
						: res.certification_process_steps.length - 1;

				if (
					res.certification_process_steps[step].step.step_type ===
					"PRODUCT_LIST"
				) {
					res.estimations.map((estimation) => {
						estimation.amount = undefined;
						return estimation;
					});
				}
				setCertification(res);
				setReloadCertification(true);
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar(t("general.errorMessage"), {
						variant: "error",
					});
				}
			},
		}
	);

	const { mutate: updateProductMutate } = useMutation(
		"update-product",
		({
			productId,
			asin,
			marketplaces,
		}: {
			productId: number;
			asin: string;
			marketplaces: string;
		}) =>
			productActions.update({
				id: productId,
				asin: asin,
				marketplaces: marketplaces,
				companyId: certification!.company.id,
			}),
		{
			retry: false,
		}
	);

	const { mutate: createCertification } = useMutation(
		"create-certification",
		(_: null) =>
			certificationActions.create({
				isClimeCo: false,
				name: "New",
				companyId: user!.company.id,
				isHistorical: false,
				certification: providers.find(
					(p) => p.certification_type === "DEFAULT_EXTERNAL_CERTIFICATION"
				)!.id,
			}),
		{
			retry: false,
		}
	);

	const { mutate: createEstimationMutate } = useMutation(
		"estimations",
		(estimation: IEstimation) =>
			estimationsActions.create({
				amount: estimation.amount,
				product: estimation.product.id,
				certificationProcess:
					estimation.certification_process?.id ?? certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { mutate: deleteEstimationMutate } = useMutation(
		"delete-estimations",
		(estimation: IEstimation) =>
			estimationsActions.delete({
				id: estimation.id,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser ? undefined : user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { mutate: updateEstimationMutate } = useMutation(
		"estimations-update",
		(estimation: IEstimation) =>
			estimationsActions.update({
				id: estimation.id,
				amount: estimation.amount,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingToggleBadge, mutate: toggleBadge } = useMutation(
		"estimations-toggle-marketplace-badge",
		({
			estimationId,
			marketPlaceBadgeId,
		}: {
			estimationId: number;
			marketPlaceBadgeId: number;
		}) =>
			estimationsActions.toggleBadge({
				id: estimationId,
				marketPlaceBadgeId: marketPlaceBadgeId,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { mutate: generateCSV } = useMutation(
		"certification-generate-csv",
		(certificationProcess?: ICertification) =>
			certificationActions.generateCSV({
				isClimeCo: false,
				id: certificationProcess?.id ?? certification?.id ?? -1,
				companyId: user?.is_superuser
					? certificationProcess?.company?.id ?? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const {
		refetch: refetchCertificationSteps,
		isLoading: isLoadingCertificationSteps,
	} = useQuery(
		"fetch-steps",
		() =>
			certificationActions.getSteps("DEFAULT_EXTERNAL_CERTIFICATION", false),
		{
			retry: false,
			enabled: false,
			onSuccess: (res: IStep[]) => {
				const certificationSteps = res.map((step) => {
					return {
						id: new Date().getTime(),
						status: "PENDING",
						payment_status: null,
						reject_reason: "",
						step,
					} as ICertificationSteps;
				});
				setCertification({
					id: -1,
					name: "ClimeCo Certification - " + new Date().getTime(),
					certification_process_steps: certificationSteps,
					company: user!.company,
					estimations: Array<IEstimation>(),
					is_historical: false,
					status: "COMPLETED",
				});
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (id && user) {
			if (id === "create") {
				refetchCertificationSteps();
			} else {
				refetchCertification();
			}
		} else if (!id) {
			refetchCertificationSteps();
		}
	}, [
		id,
		refetchCertification,
		user,
		enqueueSnackbar,
		refetchCertificationSteps,
	]);

	useEffect(() => {
		if (
			reloadCertification &&
			certification &&
			certification.certification_process_steps
		) {
			setReloadCertification(false);
		}
	}, [certification, reloadCertification]);

	const isValidASIN = (asin: string): boolean => {
		const asinRegex = /^(B[\dA-Z]{9}|\d{9}[\dX])$/;
		return asinRegex.test(asin);
	};

	const shouldNextButtonBeDisabled = () => {
		switch (steps[currentStep].step) {
			case IGetAmazonCertifiedStep.CHOOSE_MARKETPLACE:
				return selectedMarketplaces.length === 0;
			case IGetAmazonCertifiedStep.SELECT_PRODUCTS:
				return currentProducts.length === 0 ||
					currentProducts.find(
						(product) =>
							!product.marketplaces ||
							product.marketplaces?.length === 0 ||
							!product.asin ||
							!isValidASIN(product.asin)
					)
					? true
					: false;
			default:
				return false;
		}
	};

	const clickNextButton = () => {
		switch (steps[currentStep].step) {
			case IGetAmazonCertifiedStep.CHOOSE_MARKETPLACE: {
				setCurrentStep((step) => step + 1);
				break;
			}
			case IGetAmazonCertifiedStep.SELECT_PRODUCTS: {
				setIsLoadingSelectedProducts(true);
				let index = 0;

				for (const estimation of originalEstimations) {
					deleteEstimationMutate(estimation);
				}
				const createEstimation = (
					estimationId: number,
					amount: number,
					product: IProduct,
					index: number,
					certificationProcess?: ICertification
				) => {
					updateProductMutate(
						{
							productId: product.id,
							asin: product.asin!,
							marketplaces: product!
								.marketplaces!.map(
									(marketplace: { id: number }) => marketplace.id
								)
								.join(","),
						},
						{
							onSuccess: () => {
								createEstimationMutate(
									{
										id: estimationId,
										amount: amount,
										product: product,
										certification_process: certificationProcess,
									},
									{
										onSuccess: () => {
											index += 1;
											if (index < currentProducts.length) {
												createEstimation(
													index,
													0,
													currentProducts[index],
													index,
													certificationProcess
												);
											} else {
												generateCSV(certificationProcess, {
													onSuccess: async (res) => {
														if (certificationProcess && id === "create") {
															navigate(
																"/certifications/" + certificationProcess!.id
															);
														} else {
															await refetchCertification();
															setIsLoadingSelectedProducts(false);
														}
													},
													onError: (error: any) => {
														console.log(error);
														setIsLoadingSelectedProducts(false);
														enqueueSnackbar(t("general.errorMessage"), {
															variant: "error",
														});
													},
												});
											}
										},
										onError: (error) => {
											console.log(error);
											setIsLoadingSelectedProducts(false);
											enqueueSnackbar(t("general.errorMessage"), {
												variant: "error",
											});
										},
									}
								);
							},
							onError: (error) => {
								console.log(error);
								setIsLoadingSelectedProducts(false);
								enqueueSnackbar(t("general.errorMessage"), {
									variant: "error",
								});
							},
						}
					);
				};

				if (certification && certification.id === -1) {
					createCertification(null, {
						onSuccess: (res) => {
							createEstimation(index, 0, currentProducts[index], index, res);
						},
						onError: (error) => {
							console.log(error);
							setIsLoadingSelectedProducts(false);
							enqueueSnackbar(t("general.errorMessage"), {
								variant: "error",
							});
						},
					});
				} else {
					createEstimation(index, 0, currentProducts[index], index);
				}
				break;
			}
			case IGetAmazonCertifiedStep.PRODUCT_LIST: {
				setIsLoadingSelectedProducts(true);
				if (certification) {
					let index = 0;

					const updateEstimation = (
						id: number,
						amount: number,
						product: IProduct,
						index: number
					) => {
						updateEstimationMutate(
							{
								id: id,
								amount: amount,
								product: product,
							},
							{
								onSuccess: async () => {
									index += 1;
									if (index < certification.estimations.length) {
										updateEstimation(
											certification.estimations[index].id,
											certification.estimations[index].amount!,
											certification.estimations[index].product,
											index
										);
									} else {
										await refetchCertification();
										setIsLoadingSelectedProducts(false);
									}
								},
								onError: (error) => {
									console.log(error);
									setIsLoadingSelectedProducts(false);
									enqueueSnackbar(t("general.errorMessage"), {
										variant: "error",
									});
								},
							}
						);
					};

					updateEstimation(
						certification.estimations[index].id,
						certification.estimations[index].amount!,
						certification.estimations[index].product,
						index
					);
				}
				break;
			}
		}
	};

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						backgroundColor: "white",
						position: "relative",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
							padding: "16px 24px",
							borderBottom: "1px solid #ccc",
							position: "absolute",
							top: 0,
						}}
					>
						<Box sx={{ display: "flex", gap: 2 }}>
							<img src={Logo} alt="climeco" className={classes.logo} />

							<Typography variant="body2" sx={{ fontSize: 16 }}>
								<span style={{ fontWeight: "bold" }}>
									{t("getAmazonCertified.getCertified")}{" "}
								</span>
								| {t("getAmazonCertified.amazonPledge")}
							</Typography>
						</Box>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Box>

					<Box
						sx={{
							display: "flex",
							gap: 4,
							padding: 2,
							paddingTop: 1,
							marginTop: "80px",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
							<HomeIcon
								fontSize="small"
								sx={{
									color:
										steps[currentStep].step ===
										IGetAmazonCertifiedStep.CHOOSE_MARKETPLACE
											? "#34918E"
											: "#ABA9AD",
								}}
							/>
							<Typography
								variant="body2"
								sx={{
									color:
										steps[currentStep].step ===
										IGetAmazonCertifiedStep.CHOOSE_MARKETPLACE
											? "#34918E"
											: "#ABA9AD",
								}}
							>
								{t("getAmazonCertified.chooseMarkets")}
							</Typography>
						</Box>

						<Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
							<HomeIcon
								fontSize="small"
								sx={{
									color:
										steps[currentStep].step ===
										IGetAmazonCertifiedStep.SELECT_PRODUCTS
											? "#34918E"
											: "#ABA9AD",
								}}
							/>
							<Typography
								variant="body2"
								sx={{
									color:
										steps[currentStep].step ===
										IGetAmazonCertifiedStep.SELECT_PRODUCTS
											? "#34918E"
											: "#ABA9AD",
								}}
							>
								{t("getAmazonCertified.confirmASINNumbers")}
							</Typography>
						</Box>

						<Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
							<HomeIcon
								fontSize="small"
								sx={{
									color:
										steps[currentStep].step ===
											IGetAmazonCertifiedStep.PRODUCT_LIST ||
										steps[currentStep].step ===
											IGetAmazonCertifiedStep.COMPLETED
											? "#34918E"
											: "#ABA9AD",
								}}
							/>
							<Typography
								variant="body2"
								sx={{
									color:
										steps[currentStep].step ===
											IGetAmazonCertifiedStep.PRODUCT_LIST ||
										steps[currentStep].step ===
											IGetAmazonCertifiedStep.COMPLETED
											? "#34918E"
											: "#ABA9AD",
								}}
							>
								{t("getAmazonCertified.badgeCertification")}
							</Typography>
						</Box>
					</Box>

					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							maxHeight: "calc(100% - 250px)",
							overflow: "auto",
							alignItems: "flex-start",
							marginTop: "40px",
							position: "relative",
						}}
					>
						<Box
							sx={{
								width: { xs: "90%", sm: "80%", md: steps[currentStep].size },
								paddingBottom: "20px",
								maxWidth: "100%",
								backgroundColor: "white",
								borderRadius: "48px",
								border: "1px solid #B1D1F0",
								textAlign: "center",
								overflow: "hidden",
							}}
						>
							<Box
								sx={{
									textAlign: "center",
									borderBottom: "1px solid #B1D1F0",
									padding: 6,
									paddingBottom: 2,
									position: "relative",
									alignItems: "center",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Typography variant="h5" fontWeight="bold">
									{steps[currentStep].title}
								</Typography>
								<Typography
									variant="body1"
									color="textSecondary"
									sx={{ marginTop: 1 }}
								>
									{steps[currentStep].description}
								</Typography>
								{user?.is_superuser && certification?.company && (
									<Box
										sx={{
											fontSize: 12,
											background: "#34918E",
											padding: "6px 12px",
											borderBottomLeftRadius: 16,
											borderBottomRightRadius: 16,
											position: "absolute",
											top: 0,
											color: "white",
											fontWeight: "bold",
										}}
									>
										{certification.company.name}
									</Box>
								)}
							</Box>

							<Box
								sx={{
									overflow: "auto",
									minHeight: "210px",
									position: "relative",
								}}
							>
								{steps[currentStep].step ===
									IGetAmazonCertifiedStep.CHOOSE_MARKETPLACE && (
									<SelectMarketplaces
										selectedMarketplaces={selectedMarketplaces}
										setSelectedMarketplaces={setSelectedMarketplaces}
										selectedElements={currentProducts}
										setSelectedElements={(elements) =>
											setCurrentProducts(elements as Array<IProduct>)
										}
									/>
								)}

								{steps[currentStep].step ===
									IGetAmazonCertifiedStep.SELECT_PRODUCTS && (
									<Box>
										<MultiItemSelector
											title={t("getAmazonCertified.productList")}
											placeholderAutocomplete={t(
												"getAmazonCertified.selectProduct"
											)}
											buttonTitle={t("getAmazonCertified.addProduct")}
											listTitle={t("getAmazonCertified.productsSelected")}
											emptyState={t("getAmazonCertified.noProductsApplied")}
											setFilter={setProductsFilter}
											elements={products.filter(
												(product) =>
													!productsFilter ||
													product.name
														.toLowerCase()
														.includes(productsFilter.toLowerCase()) ||
													product.description
														.toLowerCase()
														.includes(productsFilter.toLowerCase()) ||
													(product.asin || product.upc || product.sku || "")
														.toLowerCase()
														.includes(productsFilter.toLowerCase())
											)}
											selectedElements={currentProducts}
											setSelectedElements={(elements) =>
												setCurrentProducts(elements as Array<IProduct>)
											}
											selectedMarketplaces={selectedMarketplaces}
											external
											externalLoading={
												isLoadingProducts ||
												isLoadingCertificationSteps ||
												reloadCertification ||
												!certification
											}
										/>
									</Box>
								)}
								{steps[currentStep].step ===
									IGetAmazonCertifiedStep.PRODUCT_LIST && (
									<Box>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												width: "100%",
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													width: "100%",
													alignItems: "flex-end",
													padding: 2,
												}}
											>
												<Button
													variant="contained"
													sx={{ maxWidth: 150 }}
													onClick={() => {
														if (certification) {
															const link = document.createElement("a");
															link.download = certification
																.csv!.split("/")
																.pop()!;
															link.href = certification.csv!;
															link.click();
														}
													}}
												>
													{t("getAmazonCertified.downloadCSV")}
												</Button>
											</Box>
											<Box sx={{ padding: 2, marginTop: 2 }}>
												{certification &&
													certification.estimations
														.sort((a, b) => {
															const dateA = new Date(
																a.created_at ?? ""
															).getTime();
															const dateB = new Date(
																b.created_at ?? ""
															).getTime();

															return dateA - dateB;
														})
														.map((estimation, index) => {
															return (
																estimation.product.marketplaces ?? []
															).map((marketplace) => {
																return (
																	<Box
																		key={
																			estimation.id +
																			"-" +
																			index +
																			"-" +
																			marketplace.toString()
																		}
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			marginTop: 4,
																		}}
																	>
																		<Box
																			sx={{
																				display: "flex",
																				alignItems: {
																					xs: "flex-start",
																					sm: "flex-start",
																					md: "center",
																				},
																				flexDirection: {
																					xs: "column",
																					sm: "column",
																					md: "row",
																				},
																				textAlign: "left",
																				gap: 2,
																				flex: 1,
																				justifyContent: "space-between",
																			}}
																		>
																			<Typography
																				flex={1}
																				sx={{
																					flex: 1,
																					maxWidth: 400,
																				}}
																			>
																				{estimation.product.name +
																					" - " +
																					estimation.product.asin}
																			</Typography>
																			<Box
																				sx={{
																					display: "flex",
																					justifyContent: "center",
																					minWidth: 200,
																				}}
																			>
																				<Typography>
																					{getMarketPlace(
																						parseInt(marketplace.toString())
																					)}
																				</Typography>
																			</Box>
																			<Box
																				sx={{
																					display: "flex",
																					flexDirection: "column",
																				}}
																			>
																				<Box
																					sx={{
																						display: "flex",
																						flexDirection: "row",
																						width: "100%",
																						flex: 1,
																						maxWidth: 200,
																						textAlign: "center",
																						alignItems: "center",
																						justifyContent: {
																							xs: "flex-start",
																							sm: "flex-start",
																							md: "center",
																						},
																						gap: 1,
																					}}
																				>
																					{estimation.marketplaces!.find(
																						(m) =>
																							m.marketplace_id.toString() ===
																							marketplace.toString()
																					) ? (
																						<CheckCircle
																							sx={{
																								fontSize: 14,
																								color: "#6ab04c",
																							}}
																						/>
																					) : (
																						<QueryBuilder
																							sx={{ fontSize: 14 }}
																						/>
																					)}
																					<Typography sx={{ fontSize: 14 }}>
																						{estimation.marketplaces!.find(
																							(m) =>
																								m.marketplace_id.toString() ===
																								marketplace.toString()
																						) ? (
																							<>
																								{t(
																									"getAmazonCertified.badgeActive"
																								)}
																								<br />
																								{new Intl.DateTimeFormat("en", {
																									day: "2-digit",
																									month: "2-digit",
																									year: "numeric",
																									timeZone: "UTC",
																								}).format(
																									new Date(
																										estimation.marketplaces!.find(
																											(m) =>
																												m.marketplace_id.toString() ===
																												marketplace.toString()
																										)!.badge_confirmed_date
																									)
																								)}
																							</>
																						) : (
																							t("getAmazonCertified.waiting")
																						)}
																					</Typography>
																				</Box>
																				{!estimation.marketplaces!.find(
																					(m) =>
																						m.marketplace_id.toString() ===
																						marketplace.toString()
																				) && (
																					<Box>
																						<Button
																							onClick={() => {
																								toggleBadge({
																									estimationId: estimation.id,
																									marketPlaceBadgeId: parseInt(
																										marketplace.toString()
																									),
																								});
																							}}
																							disabled={isLoadingToggleBadge}
																						>
																							{isLoadingToggleBadge ? (
																								<CircularProgress
																									size={12}
																									sx={{ color: "white" }}
																								/>
																							) : (
																								t(
																									"getAmazonCertified.confirmBadge"
																								)
																							)}
																						</Button>
																					</Box>
																				)}
																			</Box>
																		</Box>
																		<Divider
																			sx={{
																				marginTop: 2,
																				marginBottom: 2,
																				opacity: 0.5,
																			}}
																		/>
																	</Box>
																);
															});
														})}
											</Box>
										</Box>
									</Box>
								)}
								{steps[currentStep].step ===
									IGetAmazonCertifiedStep.COMPLETED &&
									certification && <Box sx={{ padding: 4 }}></Box>}
							</Box>
						</Box>
						{certificationProcessId > -1 && !certification && (
							<Box
								sx={{
									position: "absolute",
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									display: "flex",
									justifyContent: "center",
									alignItems: "flex-start",
									padding: 4,
									backgroundColor: "#ffffff",
									height: "100%",
								}}
							>
								<CircularProgress size={12} />
							</Box>
						)}
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							position: "absolute",
							bottom: 0,
							left: 0,
							right: 0,
							padding: 2,
							background: "#FFFFFF",
							borderTop: "1px solid #EAEBEB",
							fontSize: "16px",
							paddingLeft: "26px",
							paddingRight: "26px",
						}}
					>
						<Button
							variant="outlined"
							disabled
							sx={{
								fontSize: "16px",
								paddingLeft: "26px",
								paddingRight: "26px",
							}}
							onClick={() => {
								setCurrentStep(currentStep - 1);
							}}
						>
							{t("getAmazonCertified.previous")}
						</Button>
						<Box>
							<Button
								variant="contained"
								disabled={shouldNextButtonBeDisabled()}
								onClick={clickNextButton}
								sx={{
									position: "relative",
									color: isLoadingSelectedProducts ? "transparent" : "white",
									fontSize: "16px",
									paddingLeft: "26px",
									paddingRight: "26px",
								}}
							>
								{isLoadingSelectedProducts ? (
									<CircularProgress size={12} />
								) : (
									t("getAmazonCertified.next")
								)}
							</Button>
						</Box>
					</Box>
				</Box>
			</Modal>
			{certification && (
				<BasicModal
					width={800}
					showModal={completeOpen}
					handleClose={() => {
						setCompleteOpen(false);
					}}
					hideClose
					children={
						<AmazonCertificationComplete certification={certification!} />
					}
				/>
			)}
		</div>
	);
}
