import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const getChipBackgroundColor = (
	status: "ACTIVE" | "PENDING" | "OUTDATED" | "REJECTED"
) => {
	if (status.toLowerCase() === "active") {
		return "#6ab04c";
	} else if (
		status.toLowerCase() === "outdated" ||
		status.toLowerCase() === "rejected"
	) {
		return "#eb4d4b";
	} else if (status.toLowerCase() === "pending") {
		return "#f9ca24";
	}
};

const getChipColor = (status: "ACTIVE" | "PENDING" | "OUTDATED") => {
	if (status.toLowerCase() === "active") {
		return "#fff";
	} else if (
		status.toLowerCase() === "outdated" ||
		status.toLowerCase() === "rejected"
	) {
		return "#fff";
	} else if (status.toLowerCase() === "pending") {
		return "#000";
	}
};

export function ProductStatusChip({
	status,
	size = "small",
}: {
	status: "ACTIVE" | "PENDING" | "OUTDATED";
	size?: "medium" | "small";
}) {
	const { t } = useTranslation();

	return (
		<Chip
			label={t("productStatus." + status.toLowerCase())}
			size={size}
			sx={{
				backgroundColor: getChipBackgroundColor(status),
				color: getChipColor(status),
				fontWeight: "bold",
				fontSize: 10,
			}}
		/>
	);
}
