import { PropTypes } from "./ProductDetail.types";
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	FormControl,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Heading } from "../Heading";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { product as productActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { ICertification, IEstimation, IProduct } from "../../models";
import { UserContext } from "../../contexts/user";
import ArrayInput from "../ArrayInput/ArrayInput";
import { Drawer } from "@mui/material";
import { ListTable } from "../ListTable";
import { ProductStatusChip } from "./ProductStatusChip";
import { BasicModal } from "../BasicModal";
import { CertificateProduct } from "../CertificateProduct";
import {
	certification as certificationActions,
	estimation as estimationsActions,
} from "../../controllers";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { Filters } from "../../routes/Routes.types";
import { ProductStatus } from "../ProductStatus";
import { LCARecommendations } from "../LCARecommendations";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
	inputLabel: {
		fontSize: "14px",
		marginTop: 8,
		marginBottom: 4,
		fontWeight: 700,
	},
});
export function ProductDetail({ inModal, inModalCallback }: PropTypes) {
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useContext(UserContext);
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();
	const { id: paramsId } = useParams();
	const id = inModal
		? inModal === "create"
			? 0
			: inModal
		: paramsId
		? parseInt(paramsId)
		: 0;

	const [product, setProduct] = useState<IProduct | null>({
		id: 0,
		name: "",
		description: "",
		status: "PENDING",
	});

	const [products, setProducts] = useState<Array<IProduct>>([]);
	const [search] = useState("");
	const [filters] = useState<Filters>({
		...(user?.is_superuser ? { companies: [] } : {}),
		certification: "All certifications",
	});
	const [paginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: "updated_at",
			sort: "desc",
		})
	);

	const [showProductInformation, setShowProductInformation] = useState(false);
	const [showLCARejectedModal, setShowLCARejectedModal] = useState(false);
	const [showCertificateModal, setShowCertificateModal] = useState(false);
	const [showLCARecommendations, setLCARecommendations] = useState(false);

	const { refetch } = useQuery(
		"products-detail-list",
		() =>
			productActions.getOne(
				id!,
				!user?.is_superuser ? user?.company.id : undefined
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProduct(res);

				if (
					res &&
					res.estimations &&
					res?.estimations?.length > 0 &&
					res.estimations.find((e) => e.rejected_date !== null)
				) {
					setShowLCARejectedModal(true);
				}
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar(t("productDetail.somethingWrong"), {
						variant: "error",
					});
				}
			},
		}
	);

	const { refetch: refetchProducts } = useQuery(
		"products",
		() =>
			productActions.list(
				!user?.is_superuser ? user?.company.id : undefined,
				search,
				filters.companies,
				filters.certification,
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field
					  }`
					: "-updated_at"
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProducts(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("productDetail.somethingWrong"), {
					variant: "error",
				});
			},
		}
	);

	const { isLoading, mutate } = useMutation(
		"products",
		() =>
			id
				? productActions.update({
						id: id,
						name: product?.name,
						description: product?.description,
						companyId: !user?.is_superuser ? user?.company.id : undefined,
						external_id: product?.external_id,
						sku: product?.sku,
						asin: product?.asin,
						ean: product?.ean,
						isbn: product?.isbn,
						upc: product?.upc,
						gtin: product?.gtin,
						brand: product?.brand,
						websites: product?.websites ?? [""],
						status: user?.is_superuser ? product?.status : undefined,
				  })
				: productActions.create({
						name: product?.name,
						description: product?.description,
						companyId: user?.company.id,
						external_id: product?.external_id,
						sku: product?.sku,
						asin: product?.asin,
						ean: product?.ean,
						isbn: product?.isbn,
						upc: product?.upc,
						gtin: product?.gtin,
						websites: product?.websites ?? [""],
						brand: product?.brand,
						status: user?.is_superuser ? product?.status : undefined,
				  }),
		{
			retry: false,
			onSuccess: async (res) => {
				setProduct({ ...product!, id: res.id });

				enqueueSnackbar(t("productDetail.successProductSaved"), {
					variant: "success",
				});
			},
			onError: (error: any) => {
				if (
					error.response.data.name.length > 0 &&
					error.response.data.name[0] ===
						"product with this name already exists."
				) {
					enqueueSnackbar(t("productDetail.errorProductNameExists"), {
						variant: "error",
					});
				} else {
					enqueueSnackbar(t("productDetail.somethingWrong"), {
						variant: "error",
					});
				}
			},
		}
	);

	const goToProducts = () => {
		navigate("/products");
	};
	const addProduct = () => {
		setShowCertificateModal(false);
		setProduct({
			id: 0,
			name: "",
			description: "",
			status: "PENDING",
			asin: "",
			upc: "",
			lca_documents: [],
			brand: "",
			websites: [""],
		});
	};

	const { mutate: createEstimationMutate } = useMutation(
		"start-estimations",
		({
			estimation,
			certification,
		}: {
			estimation: IEstimation;
			certification: ICertification;
		}) =>
			estimationsActions.create({
				amount: estimation.amount,
				product: estimation.product.id,
				certificationProcess: certification!.id,
				companyId: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { mutate: createCertificationMutate } = useMutation(
		"certification",
		(_: null) =>
			certificationActions.create({
				isClimeCo: true,
				name: "New",
				companyId: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const goToCertification = () => {
		createCertificationMutate(null, {
			onSuccess: (res) => {
				let index = 0;
				const createEstimation = (
					id: number,
					amount: number,
					product: IProduct,
					index: number
				) => {
					createEstimationMutate(
						{
							estimation: {
								id: id,
								amount: amount,
								product: product,
							},
							certification: res,
						},
						{
							onSuccess: () => {
								navigate("/climeco-certificationss/" + res.id, {
									state: {
										previousPage: window.location.href.includes(":3000")
											? window.location.href.split(":3000")[1]
											: window.location.href.split(".com")[1],
									},
								});
							},
							onError: (error) => {
								console.log(error);
								enqueueSnackbar(t("productDetail.somethingWrong"), {
									variant: "error",
								});
							},
						}
					);
				};

				createEstimation(index, 0, product as IProduct, index);
			},
			onError: (error) => {
				console.log(error);
				enqueueSnackbar(t("productDetail.somethingWrong"), {
					variant: "error",
				});
			},
		});
	};

	useEffect(() => {
		refetchProducts();
	}, [refetchProducts]);

	useEffect(() => {
		if (id) {
			refetch();
		} else {
			navigate("/404", { replace: true });
		}
	}, [id, refetch, navigate]);

	const certified =
		(product?.certification_processes?.length ?? 0) > 0 &&
		product!.certification_processes!.find(
			(c) => c.certification_process_steps!.length > 0
		) &&
		product!.certification_processes!.find(
			(c) =>
				c.certification_process_steps!.find(
					(step) => step.step.step_type === "OTHER"
				)?.status === "COMPLETED"
		) !== undefined;

	const amazonCertified =
		(product?.certification_processes ?? []).filter(
			(c) =>
				c.certification?.certification_type === "DEFAULT_EXTERNAL_CERTIFICATION"
		).length > 0 &&
		product!.certification_processes!.find(
			(c) => c.certification_process_steps!.length > 0
		) &&
		product!.certification_processes!.find(
			(c) =>
				c.certification_process_steps!.find(
					(step) => step.step.step_type === "OTHER"
				)?.status === "COMPLETED"
		) !== undefined;

	return (
		<Box>
			<Drawer
				anchor="right"
				open={showProductInformation}
				onClose={() => setShowProductInformation(false)}
				sx={{
					width: {
						xs: "90%",
						sm: "600px",
						md: "800px",
						lg: "auto",
					},
					maxWidth: {
						xs: "90%",
						sm: "600px",
						md: "800px",
						lg: "auto",
					},
					flexShrink: 0,
					boxShadow: "none",
					"& .MuiPaper-root": {
						width: {
							xs: "90%",
							sm: "600px",
							md: "800px",
							lg: "auto",
						},
					},
				}}
			>
				<Box
					sx={{
						padding: 4,
						width: "100%",
					}}
				>
					<Typography variant="h6" sx={{ marginBottom: 4 }}>
						{product?.name
							? product?.name + t("productDetail.info")
							: t("productDetail.productInfo")}
					</Typography>
					<Box>
						<span className={classes.inputLabel}>
							{t("productDetail.lcas")}
						</span>
						<Box sx={{ marginTop: 2, marginBottom: 2 }}>
							{(product?.lca_documents?.length ?? 0) > 0 ? (
								product!
									.lca_documents!.filter((d) => d.lca_document)
									.map((d) => (
										<Box
											sx={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												maxWidth: {
													xs: "90%",
													sm: "600px",
													md: "800px",
													lg: "auto",
												},
											}}
											key={d.id}
										>
											<Typography
												sx={{
													maxWidth: {
														xs: "calc(80% - 60)",
													},
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis",
												}}
											>
												<a
													href={d.lca_document!.toString()}
													target="_blank"
													rel="noreferrer"
												>
													{
														d.lca_document!.toString().split("/")[
															d.lca_document!.toString().split("/").length - 1
														]
													}
												</a>
											</Typography>
											<Typography
												sx={{
													textAlign: "right",
												}}
											>
												{new Intl.DateTimeFormat("en", {
													day: "2-digit",
													month: "2-digit",
													year: "numeric",
													timeZone: "UTC",
												}).format(new Date(d.updated_at ?? ""))}
											</Typography>
										</Box>
									))
							) : (
								<Typography>--</Typography>
							)}
						</Box>
					</Box>

					<Box>
						<span className={classes.inputLabel}>
							{t("productDetail.signedContracts")}
						</span>
						<Box
							sx={{
								marginTop: 2,
								marginBottom: 2,
								display: "flex",
								flexDirection: "column",
							}}
						>
							{product &&
							product.certification_processes &&
							product.certification_processes.length > 0 ? (
								product.certification_processes.map((d) => {
									return (d.certification_process_files ?? []).map((file) => {
										const fileUrl =
											typeof file.file === "string" ? file.file : undefined;
										const fileName = fileUrl || "--";

										return (
											<Box
												sx={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													maxWidth: {
														xs: "90%",
														sm: "600px",
														md: "800px",
														lg: "auto",
													},
												}}
											>
												<Typography
													sx={{
														maxWidth: {
															xs: "calc(80% - 60)",
														},
														overflow: "hidden",
														whiteSpace: "nowrap",
														textOverflow: "ellipsis",
													}}
												>
													<a
														href={fileUrl}
														target="_blank"
														rel="noreferrer noopener"
													>
														{
															fileName.toString().split("/")[
																fileName.toString().split("/").length - 1
															]
														}
													</a>
												</Typography>
												<Typography
													sx={{
														textAlign: "right",
													}}
												>
													{new Intl.DateTimeFormat("en", {
														day: "2-digit",
														month: "2-digit",
														year: "numeric",
														timeZone: "UTC",
													}).format(new Date(file.updated_at ?? ""))}
												</Typography>
											</Box>
										);
									});
								})
							) : (
								<Typography>--</Typography>
							)}
						</Box>
					</Box>
					<span className={classes.inputLabel}>
						{t("productDetail.estimations")}
					</span>
					{product?.estimations ? (
						<ListTable
							headers={[
								{ key: "amount", title: "Estimated Sales", type: "text" },
								{
									key: "lca_document",
									subkey: "emissions",
									title: "Emissions",
									type: "lca_document_emissions",
								},
								{
									key: "checklist_document",
									title: "Checklist Document",
									type: "download",
								},
							]}
							data={product?.estimations}
						/>
					) : (
						<Typography variant="body2" sx={{ padding: 2 }}>
							{t("productDetail.noEstimationsYet")}
						</Typography>
					)}
				</Box>
			</Drawer>

			<Box sx={{ flexGrow: 1 }}>
				<Heading
					title={id ? "Edit Product" : "Add Product"}
					showSearch={false}
					setShowProductInformation={id ? setShowProductInformation : undefined}
					noMarginBottom
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: {
							xs: "column",
							sm: "row",
						},
						gap: 2,
						justifyContent: "flex-end",
					}}
				>
					<ProductStatus
						title={t("productDetail.productLCAStatus")}
						subtitle={`${
							(product?.product_lca_version_emissions?.length ?? 0) > 0 &&
							product?.product_lca_version_emissions![0].lca_version &&
							product?.product_lca_version_emissions![0].lca_version!.status ===
								"ACTIVE" &&
							product?.product_lca_version_emissions![0].lca_version!
								.approved_date
								? "since " +
								  new Intl.DateTimeFormat("en", {
										day: "2-digit",
										month: "2-digit",
										year: "numeric",
										timeZone: "UTC",
								  }).format(
										new Date(
											product?.product_lca_version_emissions![0].lca_version!.approved_date!
										)
								  )
								: (product?.product_lca_version_emissions?.length ?? 0) > 0 &&
								  product?.product_lca_version_emissions![0].lca_version
								? product?.product_lca_version_emissions![0].lca_version!.status.toLowerCase()
								: "pending"
						}`}
						status={
							(product?.product_lca_version_emissions?.length ?? 0) > 0 &&
							product?.product_lca_version_emissions![0].lca_version
								? product?.product_lca_version_emissions![0].lca_version!.status.toLowerCase()
								: "pending"
						}
					/>
					<ProductStatus
						title={t("productDetail.climeCoCertStatus")}
						subtitle={
							certified
								? `since ${
										(product?.certification_processes?.length ?? 0) > 0 &&
										(product!
											.certification_processes!.filter(
												(c) =>
													c.certification?.certification_type ===
													"CLIMECO_INTERNAL_CERTIFICATION"
											)
											.find(
												(c) =>
													c.certification_process_steps!.find(
														(step) => step.step.step_type === "OTHER"
													)?.status === "COMPLETED"
											)?.certification_process_steps?.length ?? 0) > 0
											? new Intl.DateTimeFormat("en", {
													day: "2-digit",
													month: "2-digit",
													year: "numeric",
													timeZone: "UTC",
											  }).format(
													new Date(
														product!
															.certification_processes!.filter(
																(c) =>
																	c.certification?.certification_type ===
																	"CLIMECO_INTERNAL_CERTIFICATION"
															)
															.find(
																(c) =>
																	c.certification_process_steps!.find(
																		(step) => step.step.step_type === "OTHER"
																	)?.status === "COMPLETED"
															)!
															.certification_process_steps!.find(
																(step) => step.step.step_type === "OTHER"
															)!.updated_at!
													)
											  )
											: "--"
								  }`
								: "pending"
						}
						status={certified ? "completed" : "pending"}
					/>

					<ProductStatus
						title={t("productDetail.amazonStatus")}
						subtitle={
							certified
								? `since ${
										(product?.certification_processes ?? []).filter(
											(c) =>
												c.certification?.certification_type ===
												"DEFAULT_EXTERNAL_CERTIFICATION"
										).length > 0 &&
										((product?.certification_processes ?? [])
											.filter(
												(c) =>
													c.certification?.certification_type ===
													"DEFAULT_EXTERNAL_CERTIFICATION"
											)
											.find(
												(c) =>
													c.certification_process_steps?.find(
														(step) => step.step.step_type === "OTHER"
													)?.status === "COMPLETED"
											)?.certification_process_steps?.length ?? 0) > 0
											? new Intl.DateTimeFormat("en", {
													day: "2-digit",
													month: "2-digit",
													year: "numeric",
													timeZone: "UTC",
											  }).format(
													new Date(
														product?.certification_processes
															?.filter(
																(c) =>
																	c.certification?.certification_type ===
																	"DEFAULT_EXTERNAL_CERTIFICATION"
															)
															.find(
																(c) =>
																	c.certification_process_steps?.find(
																		(step) => step.step.step_type === "OTHER"
																	)?.status === "COMPLETED"
															)!
															.certification_process_steps?.find(
																(step) => step.step.step_type === "OTHER"
															)!.updated_at!
													)
											  )
											: "--"
								  }`
								: "pending"
						}
						status={amazonCertified ? "completed" : "pending"}
					/>
				</Box>
				<Box
					sx={{
						marginBottom: 4,
						boxShadow: "0 0px 6px rgba(0, 0, 0, 0.1)",
						padding: 2,
						display: "flex",
						flexDirection: "column",
						marginTop: 4,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "start",
							gap: 2,
						}}
					>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Typography variant="h6" sx={{ marginBottom: 2 }}>
								{t("productDetail.lca")}
							</Typography>
							{!product?.product_lca_version_emissions &&
							(product?.product_lca_version_emissions?.length ?? 0) > 0 ? (
								<Typography variant="body2" sx={{ marginBottom: 4 }}>
									{t("productDetail.lcaInfo")}
								</Typography>
							) : (
								<Typography variant="body2" sx={{ marginBottom: 4 }}>
									{t("productDetail.lcaVersion")}
								</Typography>
							)}
						</Box>
						{!product?.product_lca_version_emissions &&
							(product?.product_lca_version_emissions?.length ?? 0) > 0 && (
								<Box
									sx={{
										border: "1px solid #efefef",
										padding: 1,
										maxWidth: 400,
										textAlign: "center",
									}}
								>
									<Typography variant="body2" sx={{ fontSize: 12 }}>
										{t("productDetail.noLca")}{" "}
										<span
											style={{
												color: "#25406D",
												fontSize: 12,
												fontWeight: 700,
												cursor: "pointer",
											}}
											onClick={() => {
												setLCARecommendations(true);
											}}
										>
											{t("productDetail.clickHere")}
										</span>
									</Typography>
								</Box>
							)}
					</Box>

					{product &&
						product.product_lca_version_emission?.lca_version
							?.rejected_date && (
							<Alert severity="error" sx={{ marginBottom: 4 }}>
								{t("productDetail.certificationRejected")}{" "}
								{product.product_lca_version_emission?.lca_version
									?.reject_reason ?? t("productDetail.invalidLCA")}
							</Alert>
						)}
					<Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
						<Box
							sx={{
								marginRight: 0,
								marginLeft: 2,
								display: "flex",
								flexDirection: "column",
								maxHeight: 77,
							}}
						>
							<span className={classes.inputLabel}>
								{t("productDetail.lcaName")}
							</span>

							<Typography
								variant="body2"
								sx={{
									textAlign: "center",
									marginBottom: 2,
									fontWeight: "bold",
								}}
							>
								{(product?.product_lca_version_emissions &&
									(product?.product_lca_version_emissions[0].lca_version
										?.title ??
										product?.product_lca_version_emissions[0].lca_version?.lca
											?.title ??
										"LCA")) ??
									"LCA"}
							</Typography>
						</Box>

						<Box
							sx={{
								marginRight: 0,
								marginLeft: 2,
								display: "flex",
								flexDirection: "column",
								maxHeight: 77,
							}}
						>
							<span className={classes.inputLabel}>
								{t("productDetail.lcaDocument")}
							</span>

							<Typography
								sx={{
									marginLeft: 2,
									textOverflow: "ellipsis",
									overflow: "hidden",
									width: 150,
									flex: { xs: 1, sm: 1, md: "unset" },
									fontSize: 14,
									whiteSpace: "nowrap",
								}}
							>
								<a
									href={
										(product?.product_lca_version_emissions &&
											product?.product_lca_version_emissions[0].lca_version?.lca_document?.toString()) ??
										"#"
									}
									style={{ whiteSpace: "nowrap" }}
									target="_blank"
									rel="noreferrer"
									download
								>
									...
									{product?.product_lca_version_emissions &&
									product?.product_lca_version_emissions[0].lca_version?.lca_document?.toString()
										? "..." +
										  product?.product_lca_version_emissions[0]
												.lca_version!.lca_document!.toString()
												.split("/")
												[
													product?.product_lca_version_emissions[0]
														.lca_version!.lca_document!.toString()
														.split("/").length - 1
												].slice(-15)
										: "--"}
								</a>
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
							}}
						>
							{product?.product_lca_version_emissions &&
								product?.product_lca_version_emissions[0].lca_version
									?.certification_date && (
									<Box
										sx={{
											marginRight: 0,
											marginLeft: 2,
											display: "flex",
											flexDirection: "column",
											maxHeight: 77,
										}}
									>
										<span className={classes.inputLabel}>
											{t("productDetail.lcaCertificationDate")}
										</span>

										<Typography
											sx={{
												textOverflow: "ellipsis",
												overflow: "hidden",
												maxWidth: 250,
											}}
										>
											{new Intl.DateTimeFormat("en", {
												day: "2-digit",
												month: "2-digit",
												year: "numeric",
												timeZone: "UTC",
											}).format(
												new Date(
													(product?.product_lca_version_emissions &&
														product?.product_lca_version_emissions[0]
															.lca_version?.certification_date) ??
														""
												)
											)}
										</Typography>
									</Box>
								)}
						</Box>
					</Box>
				</Box>

				<FormControl sx={{ width: "100%" }}>
					{id &&
					((!user?.is_superuser && product?.status !== "ACTIVE") ||
						user?.is_superuser) ? (
						<>
							<span className={classes.inputLabel}>
								{t("productDetail.status")}
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="status"
								select
								value={product?.status}
								placeholder={t("productDetail.statusProduct")}
								disabled={!user?.is_superuser}
								onChange={(e) => {
									setProduct(
										(prevState) =>
											({
												...prevState,
												status: e.target.value,
											} as IProduct)
									);
								}}
								sx={{
									marginBottom: 2,
									"& .MuiSelect-select": {
										backgroundColor: "#fff",
									},
									"& .MuiOutlinedInput-input": {
										backgroundColor: "#fff",
									},
									"& .MuiSelect-icon": {
										border: "none",
									},
								}}
							>
								{["ACTIVE", "PENDING", "OUTDATED"].map((option, index) => (
									<MenuItem key={option} value={option}>
										<ProductStatusChip
											status={option as "ACTIVE" | "PENDING" | "OUTDATED"}
										/>
									</MenuItem>
								))}
							</TextField>
						</>
					) : (
						<></>
					)}
					<span className={classes.inputLabel}>{t("productDetail.title")}</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="title"
						value={product?.name}
						placeholder={t("productDetail.titleProduct")}
						onChange={(e) => {
							setProduct(
								(prevState) =>
									({
										...prevState,
										name: e.target.value,
									} as IProduct)
							);
						}}
						sx={{ marginBottom: 2 }}
					/>

					<span className={classes.inputLabel}>{t("productDetail.asin")}</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="asin"
						value={product?.asin}
						placeholder={t("productDetail.asin")}
						onChange={(e) => {
							setProduct(
								(prevState) =>
									({
										...prevState,
										asin: e.target.value,
									} as IProduct)
							);
						}}
						sx={{ marginBottom: 2 }}
					/>

					<span className={classes.inputLabel}>{t("productDetail.upc")}</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="upc"
						value={product?.upc}
						placeholder={t("productDetail.upc")}
						type="text"
						inputProps={{
							inputMode: "numeric",
						}}
						onChange={(e) => {
							const value = e.target.value;

							const isNumeric = /^[0-9]*$/.test(value);
							if (isNumeric && value.length <= 12) {
								setProduct(
									(prevState) =>
										({
											...prevState,
											upc: e.target.value,
										} as IProduct)
								);
							}
						}}
						sx={{
							marginBottom: 2,
						}}
					/>

					<span className={classes.inputLabel}>{t("productDetail.brand")}</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="brand"
						value={product?.brand}
						placeholder={t("productDetail.brand")}
						onChange={(e) => {
							setProduct(
								(prevState) =>
									({
										...prevState,
										brand: e.target.value,
									} as IProduct)
							);
						}}
						sx={{ marginBottom: 2 }}
					/>
					<Box sx={{ marginTop: 2 }}>
						<span className={classes.inputLabel}>
							{t("productDetail.websites")}
						</span>
						<ArrayInput
							array={product?.websites}
							setArray={(websites) => {
								setProduct(
									(prevState) =>
										({
											...prevState,
											websites: websites,
										} as IProduct)
								);
							}}
							title={t("productDetail.websites")}
						/>
					</Box>
					<Button
						variant="contained"
						onClick={() => {
							mutate();
						}}
						disabled={isLoading}
						sx={{
							marginTop: 2,
							maxWidth: 300,
							alignSelf: "end",
							minHeight: 34,
						}}
					>
						{isLoading ? (
							<CircularProgress size={12} sx={{ color: "white" }} />
						) : (
							t("productDetail.submitChanges")
						)}
					</Button>
				</FormControl>
			</Box>

			<BasicModal
				width={800}
				showModal={showCertificateModal}
				handleClose={() => {
					setShowCertificateModal(false);
				}}
				children={
					<CertificateProduct
						goToProducts={goToProducts}
						goToCertification={goToCertification}
						addProduct={addProduct}
						firstTime={products.length < 1}
					/>
				}
				hideClose
			/>
			<BasicModal
				width={800}
				showModal={showLCARecommendations}
				handleClose={() => {
					setLCARecommendations(false);
				}}
				children={
					<LCARecommendations setLCARecommendations={setLCARecommendations} />
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showLCARejectedModal}
				handleClose={() => {
					setShowLCARejectedModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("productDetail.lcaRejected")}{" "}
							<b style={{ color: "#EB4B4B" }}>{t("productDetail.rejected")}</b>:
							<br />
							<br />
							<b>
								{product &&
								product.estimations &&
								product?.estimations?.length > 0
									? product.estimations.find((e) => e.rejected_date !== null)
											?.reject_reason
									: t("productDetail.invalidLCA")}
							</b>
							<br />
							<br />
							{t("productDetail.updateLCA")}{" "}
							{t("productDetail.additionalQuestions")}{" "}
							<a
								href="mailto:productcertification@climeco.com"
								rel="noreferrer noopener"
							>
								productcertification@climeco.com
							</a>
						</Typography>

						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								onClick={() => {
									setShowLCARejectedModal(false);
								}}
							>
								{t("productDetail.buttonOK")}
							</Button>
						</Box>
					</Box>
				}
			/>
		</Box>
	);
}
