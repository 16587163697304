import {
	Box,
	Button,
	Chip,
	FormControlLabel,
	FormGroup,
	Switch,
	Typography,
} from "@mui/material";
import Avatar from "react-avatar";
import { PropTypes } from "./User.types";
import { MoreButton } from "../MoreButton";
import { connectionsClient } from "../../utils/ConnectionsClient";
import { useContext } from "react";
import { UserContext } from "../../contexts/user";
import { useNavigate } from "react-router-dom";
import AdminPanelSettings from "@mui/icons-material/AdminPanelSettings";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../contexts/data";

export function User(props: PropTypes) {
	const { name, company } = props;
	const { user, setUser } = useContext(UserContext);
	const { showTestData, setShowTestData } = useContext(DataContext);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const logout = () => {
		connectionsClient.updateTokens({
			access: null,
			refresh: null,
			userId: null,
			parentAccess: null,
			parentRefresh: null,
			parentUserId: null,
		});
		setUser(null);
		navigate("/");
		window.location.href = window.location.origin;
	};

	const backToAdmin = () => {
		connectionsClient.updateTokens({
			access: connectionsClient.token.parentAccess,
			refresh: connectionsClient.token.parentRefresh,
			userId: connectionsClient.token.parentUserId,
			parentAccess: null,
			parentRefresh: null,
			parentUserId: null,
		});
		window.location.href = window.location.origin;
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					flex: 1,
					marginTop: 4,
				}}
			>
				<Box
					sx={{
						position: "relative",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Avatar
						name={name}
						size="80"
						round="50%"
						style={{ fontSize: "24px" }}
					></Avatar>
					<Box
						sx={{
							position: "absolute",
							bottom: 10,
							right: -4,
							transform: "translateY(50%)",
						}}
					>
						<MoreButton
							menuOptions={[
								{
									label: t("user.logout"),
									callback: logout,
								},
							]}
						/>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						flex: 1,
						textAlign: "left",
						marginTop: 2,
					}}
				>
					<Typography
						variant="body2"
						gutterBottom
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							"-webkit-box-orient": "vertical",
							"-webkit-line-clamp": "1",
							marginBottom: 0,
							textTransform: "capitalize",
							wordBreak: "break-word",
							fontWeight: "bold",
						}}
					>
						{company}
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							"-webkit-box-orient": "vertical",
							"-webkit-line-clamp": "1",
							marginBottom: 0,
							wordBreak: "break-word",
							fontSize: 12,
						}}
					>
						{name}
					</Typography>
					{user?.is_superuser && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 1,
								alignItems: "center",
							}}
						>
							<Chip
								sx={{
									backgroundColor: "#03b093",
									color: "#fff",
									fontWeight: "bold",
									"& .MuiChip-icon": {
										color: "#25406D!important",
									},
									marginTop: 2,
									fontSize: 12,
								}}
								icon={<AdminPanelSettings style={{ fontSize: 14 }} />}
								label={t("user.admin")}
							/>
							<FormGroup
								sx={{
									padding: "4px 12px",
									backgroundColor: showTestData ? "#fc7a00" : "transparent",
									borderRadius: 1,
								}}
							>
								<FormControlLabel
									sx={{
										"& .MuiTypography-root": {
											fontSize: 10,
											color: showTestData ? "white" : "inherit",
										},
									}}
									control={
										<Switch
											checked={showTestData}
											onChange={(e) => setShowTestData(e.target.checked)}
											size="small"
										/>
									}
									label={t("user.showTestData")}
								/>
							</FormGroup>
						</Box>
					)}
					{connectionsClient.token.parentAccess && (
						<Button
							variant="contained"
							sx={{ marginTop: 2 }}
							onClick={backToAdmin}
						>
							{t("user.backToAdmin")}
						</Button>
					)}
				</Box>
			</Box>
		</Box>
	);
}
