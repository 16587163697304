import React, { useContext, useState } from "react";
import { DataContextType, PropTypes } from "./DataContext.types";
import { UserContext } from "../user";

export const DataContext = React.createContext<DataContextType>({
	isEditing: true,
	setIsEditing: (isEditing: boolean) => {},
	showTestData: false,
	setShowTestData: (showTestData: boolean) => {},
});

const showTestDataKey = "showTestData";

export const DataProvider: React.FC<PropTypes> = ({ children }) => {
	const { user } = useContext(UserContext);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [showTestData, setAuxShowTestData] = useState<boolean>(
		(user && !user.is_superuser) ||
			localStorage.getItem(showTestDataKey) !== null
			? true
			: false
	);

	const setShowTestData = (showTestData: boolean) => {
		if (showTestData) {
			localStorage.setItem(showTestDataKey, "true");
		} else {
			localStorage.removeItem(showTestDataKey);
		}
		setAuxShowTestData(showTestData);
		window.location.reload();
	};

	return (
		<DataContext.Provider
			value={{ isEditing, setIsEditing, showTestData, setShowTestData }}
		>
			{children}
		</DataContext.Provider>
	);
};
