import { useState, useEffect, useContext } from "react";
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	TextField,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { PropTypes } from "./ExternalCertificationActionBar.types";
import { ICompany, IProduct } from "../../models";
import {
	company as companyActions,
	product as productActions,
} from "../../controllers";
import { BasicModal } from "../BasicModal";
import ChooseProvider from "../ChooseProvider/ChooseProvider";
import { UserContext } from "../../contexts/user";
import { useTranslation } from "react-i18next";

type Provider = {
	id: number;
	name: string;
	certification_type: string;
};

export function ExternalCertificationActionBar({
	refetch,
	filters,
	setFilters,
	setPaginationModel,
}: PropTypes) {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const [selectedCompanies, setSelectedCompanies] = useState<Array<ICompany>>(
		[]
	);
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [companiesSearch, setCompaniesSearch] = useState("");
	const [products, setProducts] = useState<Array<IProduct>>([]);
	const handleSearchChange = (value: string) => {
		setCompaniesSearch(value);
	};

	const [selectedProvider, setSelectedProvider] = useState<Provider | null>(
		null
	);

	const [showProviderModal, setShowProviderModal] = useState(false);

	const { refetch: refetchCompanies, isLoading: isLoadingCompanies } = useQuery(
		"companies",
		() => companyActions.list(companiesSearch, undefined, "name", 999),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		refetchCompanies();
	}, [refetchCompanies, companiesSearch]);

	const { refetch: refetchProducts, isLoading: isLoadingProducts } = useQuery(
		"products-active-external",
		() =>
			productActions.list(
				user?.company.id,
				undefined,
				[],
				"ClimeCo certified",
				1,
				undefined,
				1000
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				const products = res.results.filter(
					(product) =>
						product.certification_processes?.find(
							(certificationProcess) =>
								certificationProcess.certification?.certification_type ===
									"CLIMECO_INTERNAL_CERTIFICATION" &&
								!certificationProcess.certification_process_steps.find(
									(certificationProcessStep) =>
										certificationProcessStep.status !== "COMPLETED"
								)
						) &&
						!product.certification_processes?.find(
							(certificationProcess) =>
								certificationProcess.certification?.certification_type ===
								"DEFAULT_EXTERNAL_CERTIFICATION"
						)
				);
				setProducts(products);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (user) {
			refetchProducts();
		}
	}, [user, refetchProducts]);

	const { refetch: refetchCompaniesInSearch } = useQuery(
		"companies-in-search",
		() =>
			companyActions.list(
				"",
				undefined,
				"name",
				999,
				searchParams.get("companies")
					? searchParams
							.get("companies")!
							.split(",")
							.map((c) => parseInt(c))
					: []
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setSelectedCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (searchParams.get("companies")) {
			refetchCompaniesInSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refetchCompaniesInSearch]);

	return (
		<>
			<Box sx={{ alignItems: "stretch", display: "flex", width: "100%" }}>
				<Box
					sx={{
						display: "flex",
						flex: 1,
						alignItems: "stretch",
						justifyContent: "space-between",
						flexDirection: {
							xs: "column",
							sm: "column",
							md: "column",
							lg: "column-reverse",
						},
						gap: {
							xs: 2,
							sm: 2,
							md: 2,
							lg: 0,
						},
						marginTop: {
							xs: 2,
							sm: 2,
							md: 2,
							lg: 0,
						},
						width: "100%",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: {
								xs: "column",
								sm: "column",
								md: "column",
								lg: "row",
							},
							flex: 1,
							alignItems: {
								xs: "flex-start",
								sm: "flex-start",
								md: "flex-start",
								lg: "flex-end",
							},
							justifyContent: "flex-start",
							marginTop: "0",
							alignSelf: "flex-start",
							gap: 2,
							width: "100%",
						}}
					>
						{filters.companies && (
							<Autocomplete
								disablePortal
								id="company-box"
								options={companies}
								value={selectedCompanies}
								sx={{
									width: {
										xs: 240,
										sm: 240,
										md: 240,
										lg: 240,
										xl: 300,
									},
									borderRadius: "24px",
									borderColor: "#ececec",
									"& .MuiFormControl-root": {
										border: "none!important",
									},
									"& .MuiInputBase-root": {
										padding: "8px",
										overflow: "auto",
										maxHeight: "38px",
										border: "none!important",
									},
								}}
								multiple
								getOptionKey={(company) => company?.id ?? ""}
								getOptionLabel={(company) => company?.name ?? ""}
								onChange={(_, value) => {
									setSelectedCompanies(value);
									setPaginationModel({
										page: 0,
										pageSize: 10,
									});
									setFilters((prev) => ({
										...prev,
										companies: value.map((company) => company!.id),
									}));
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										placeholder={t("externalCertification.company")}
										sx={{
											maxHeight: 56,
											overflow: "auto!important",
											border: "1px solid #ececec",
											borderRadius: 2,
											backgroundColor: "#fff",
											"& input": {
												fontSize: 14,
											},
										}}
										onChange={(ev) => {
											if (ev.target.value !== "" || ev.target.value !== null) {
												handleSearchChange(ev.target.value);
											}
										}}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{isLoadingCompanies ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
							/>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flex: 1,
							alignItems: "center",
							justifyContent: "flex-end",
							flexDirection: {
								xs: "row",
								sm: "row",
								md: "row",
								lg: "row",
							},
							gap: {
								xs: 0.5,
								sm: 0.5,
								md: 0.5,
								lg: 0.5,
							},
							marginTop: {
								xs: 0,
								sm: 0,
								md: 0,
								lg: 0,
							},
							width: "100%",
						}}
					>
						<Button
							variant="contained"
							sx={{ minHeight: 34, minWidth: 120 }}
							onClick={() => {
								setShowProviderModal(true);
							}}
							disabled={isLoadingProducts || products.length === 0}
						>
							{t("externalCertification.createNew")}
						</Button>
					</Box>
				</Box>
			</Box>
			<BasicModal
				width={600}
				showModal={showProviderModal}
				handleClose={() => {
					setShowProviderModal(false);
				}}
				children={
					<ChooseProvider
						selectedProvider={selectedProvider}
						setSelectedProvider={setSelectedProvider}
						mutate={() => {
							navigate("/certifications/create");
						}}
						providerTypes={["DEFAULT_EXTERNAL_CERTIFICATION"]}
					/>
				}
			/>
		</>
	);
}
