import { useContext, useEffect, useState } from "react";
import {
	Typography,
	Box,
	Button,
	TextField,
	Grid,
	FormControl,
	FormControlLabel,
	Checkbox,
	Autocomplete,
	Alert,
	ButtonGroup,
	CircularProgress,
	Divider,
} from "@mui/material";
import { PropTypes } from "./CompanyOnboarding.types";
import { useMutation } from "react-query";
import { company as companyActions } from "../../controllers";
import { ICompany } from "../../models";
import { useSnackbar } from "notistack";
import { UserContext } from "../../contexts/user";
import { makeStyles } from "@mui/styles";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrayInput from "../ArrayInput/ArrayInput";
import AttestationEntityArrayInput from "../AttestationEntityArrayInput/AttestationEntityArrayInput";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
	inputLabel: {
		display: "flex",
		marginTop: 8,
		marginBottom: "14px",
		fontSize: 14,
		fontWeight: 700,
	},
	inputLabelRequired: {
		fontSize: 18,
		display: "inline-block",
		height: "5px",
		verticalAlign: "sub",
		color: "#B61313",
	},
});

export function CompanyOnboarding(props: PropTypes) {
	const classes = useStyles();
	const { user, getUser } = useContext(UserContext);
	const [company, setCompany] = useState<ICompany | null>({
		id: 0,
		name: "",
		email: user?.email ?? "",
		country: "US",
		formatted_address: "",
	});
	const [policyCheckbox, setPolicyCheckbox] = useState(false);
	const [protocolCheckbox, setProtocolCheckbox] = useState(false);

	const productProtocolUrl =
		"https://www.climeco.com/wp-content/uploads/2024/05/ClimeCo-Cert-Prod-Protocol_v12_FINAL_clean_no-sign_050124.pdf";
	const productProtocolAsset = "/ClimeCo-Cert-Prod-Protocol.pdf";
	const [isRemoteAvailable, setIsRemoteAvailable] = useState<boolean>(true);

	const [hadHistoricalData] = useState<Date | null | undefined>(undefined);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const [hadChinaProjects, setHadChinaProjects] = useState<boolean | undefined>(
		undefined
	);

	const { t } = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const [inputValue, setInputValue] = useState(
		company?.formatted_address || ""
	);

	const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
		usePlacesService({
			apiKey: "AIzaSyBeHWqS_4Rm9ZBIqbOtQ5PwGJIUYsaHxD0",
			options: {
				/*componentRestrictions: {
				country: ["us", "ca"],
			},*/
				types: ["street_address", "route"],
			},
			language: "en",
		});

	const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				//eslint-disable-next-line
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const validate = (company: ICompany | null) => {
		// TO DO: Add more
		let valid = true;
		if (!validateEmail(company?.email ?? "")) {
			valid = false;
			enqueueSnackbar("Email field is incorrect.", { variant: "error" });
		}
		return valid;
	};

	useEffect(() => {
		fetch(productProtocolUrl, { method: "HEAD" })
			.then((response) => {
				if (!response.ok) throw new Error("Network response was not ok");
				setIsRemoteAvailable(true);
			})
			.catch(() => {
				setIsRemoteAvailable(false);
			});
	}, [productProtocolUrl]);

	const { mutate } = useMutation(
		"company",
		() =>
			companyActions.create({
				name: company?.name,
				description: company?.description,
				formatted_address: company?.formatted_address,
				address: company?.address,
				city: company?.city,
				state: company?.state,
				zip_code: company?.zip_code,
				country: hadChinaProjects ? "cn" : company?.country,
				phone: company?.phone,
				email: company?.email,
				contact_name: user?.first_name,
				contact_email: company?.contact_email,
				contact_title: company?.contact_title,
				historically_approved: new Date(), //hadHistoricalData,
				seen_historically_approved_message: !hadHistoricalData
					? null
					: new Date(),
				historically_amazon_certified: true,
				legally_recognized_companies: company?.legally_recognized_companies,
				government_entities: company?.government_entities,
				payor_companies: company?.payor_companies ?? [
					{ name: "false", address: "", url: "", relationship: "" },
				],
				company_or_amazon_webpages: company?.company_or_amazon_webpages,
				company_representative_name: company?.company_representative_name,
				company_representative_title: company?.company_representative_title,
				are_any_entities_deemed_hostile_to_usa:
					company?.are_any_entities_deemed_hostile_to_usa,
				are_any_people_listed_in_ofac: company?.are_any_people_listed_in_ofac,
				are_any_people_engaged_in_money_laundering:
					company?.are_any_people_engaged_in_money_laundering,
				are_any_people_subject_to_usa_trade_restrictions:
					company?.are_any_people_subject_to_usa_trade_restrictions,
				total_first_year_forecasted_sales:
					company?.total_first_year_forecasted_sales,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				getUser();
			},
			onError: (error: any) => {
				console.log(error);
				setIsLoading(false);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);
	useEffect(() => {
		if (user?.company) {
			setIsLoading(false);
			navigate("/");
			props.handleClose();
		}
	}, [user, navigate, props]);

	const createCompany = () => {
		if (validate(company)) {
			setIsLoading(true);
			mutate();
		}
	};

	const validateCompany = () => {
		return (
			!company?.name ||
			!company?.description ||
			!company?.email ||
			!company?.formatted_address ||
			hadChinaProjects === undefined ||
			(!policyCheckbox ? true : false) ||
			(!protocolCheckbox ? true : false) ||
			(company?.legally_recognized_companies ?? []).length === 0 ||
			(company?.legally_recognized_companies ?? []).find(
				(c) =>
					(c?.name ?? "").trim().length === 0 ||
					(c?.address ?? "").trim().length === 0 ||
					(c?.url ?? "").trim().length === 0
			) !== undefined ||
			(company?.government_entities ?? []).length === 0 ||
			(company?.government_entities ?? []).find(
				(c) =>
					(c?.name ?? "").trim().length === 0 ||
					(c?.address ?? "").trim().length === 0 ||
					(c?.url ?? "").trim().length === 0
			) !== undefined ||
			(company?.company_representative_name ?? "").trim().length === 0 ||
			(company?.company_representative_title ?? "").trim().length === 0 ||
			(company?.company_or_amazon_webpages ?? []).length === 0 ||
			(company?.company_or_amazon_webpages ?? []).find(
				(c) => (c ?? "").trim().length === 0
			) !== undefined ||
			company?.are_any_entities_deemed_hostile_to_usa === undefined ||
			company?.are_any_entities_deemed_hostile_to_usa === true ||
			company?.are_any_people_engaged_in_money_laundering === undefined ||
			company?.are_any_people_engaged_in_money_laundering === true ||
			company?.are_any_people_listed_in_ofac === undefined ||
			company?.are_any_people_listed_in_ofac === true ||
			company?.are_any_people_subject_to_usa_trade_restrictions === undefined ||
			company?.are_any_people_subject_to_usa_trade_restrictions === true ||
			company?.total_first_year_forecasted_sales === undefined
		);
	};

	return (
		<Box
			sx={{
				width: "100%",
				position: "relative",
				height: "100%",
				overflowY: "hidden",
			}}
		>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				{t("companyOnboarding.yourCompany")}
			</Typography>
			<Typography variant="body2" sx={{ textAlign: "center", marginBottom: 2 }}>
				{t("companyOnboarding.provideInformation")}
			</Typography>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "divider",
					display: "flex",
					justifyContent: "center",
					marginBottom: 2,
				}}
			></Box>
			<FormControl sx={{ width: "100%" }}>
				<Box
					sx={{
						overflowY: "auto",
						overflowX: "hidden",
						height: "calc(100vh - 370px)",
						"@media (max-width: 600px)": {
							height: "calc(100vh - 390px)",
						},
					}}
				>
					<Typography
						variant={"h6"}
						sx={{
							textTransform: "uppercase",
							fontSize: 16,
							fontWeight: "bold",
							marginTop: 2,
							marginBottom: 1,
						}}
					>
						{t("companyOnboarding.companyDetails")}
					</Typography>
					<Divider sx={{ opacity: 0.5, marginBottom: 3 }} />
					<span className={classes.inputLabel}>
						{t("companyOnboarding.companyName")}{" "}
						<span className={classes.inputLabelRequired}>*</span>
					</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="name"
						placeholder={t("companyOnboarding.namePlaceholder")}
						value={company?.name}
						sx={{ marginBottom: 2 }}
						onChange={(e) => {
							setCompany(
								(prevState) =>
									({
										...prevState,
										name: e.target.value,
									} as ICompany)
							);
						}}
					/>
					<span className={classes.inputLabel}>
						{t("companyOnboarding.description")}{" "}
						<span className={classes.inputLabelRequired}>*</span>
					</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="description"
						placeholder={t("companyOnboarding.descriptionPlaceholder")}
						value={company?.description}
						sx={{ marginBottom: 2 }}
						onChange={(e) => {
							setCompany(
								(prevState) =>
									({
										...prevState,
										description: e.target.value,
									} as ICompany)
							);
						}}
					/>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6}>
							<span className={classes.inputLabel}>
								{t("companyOnboarding.email")}{" "}
								<span className={classes.inputLabelRequired}>*</span>
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="email"
								placeholder={t("companyOnboarding.emailPlaceholder")}
								sx={{ marginBottom: 2 }}
								value={company?.email}
								onChange={(e) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												email: e.target.value,
											} as ICompany)
									);
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<span className={classes.inputLabel}>
								{t("companyOnboarding.phone")}
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="phone"
								placeholder={t("companyOnboarding.phonePlaceholder")}
								value={company?.phone}
								sx={{ marginBottom: 2 }}
								onChange={(e) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												phone: e.target.value,
											} as ICompany)
									);
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12}>
							<span className={classes.inputLabel}>
								{t("companyOnboarding.address")}{" "}
								<span className={classes.inputLabelRequired}>*</span>
							</span>

							<Autocomplete
								id="autocomplete-address"
								filterOptions={(options) => options}
								noOptionsText={
									isPlacePredictionsLoading
										? t("companyOnboarding.loading")
										: t("companyOnboarding.noAddressFound")
								}
								value={inputValue}
								options={placePredictions.map((place) => place.description)}
								sx={{ width: "100%", marginBottom: 2 }}
								renderInput={(params) => (
									<TextField
										{...params}
										placeholder={t("companyOnboarding.enterAddress")}
										variant="outlined"
										InputLabelProps={{ shrink: false }}
										value={inputValue}
										onChange={(e) => {
											const value = e.target.value;
											setInputValue(value);
											setCompany((prevState) => ({
												...prevState!,
												formatted_address: value,
											}));
											if (value.trim() !== "") {
												getPlacePredictions({ input: value });
											}
										}}
									/>
								)}
								onChange={(e, newValue) => {
									if (newValue) {
										setInputValue(newValue);

										setCompany((prevState) => ({
											...prevState!,
											formatted_address: newValue,
										}));
									}
								}}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12}>
							<FormControlLabel
								control={
									<ButtonGroup
										disableElevation
										variant="contained"
										aria-label="China Projects data choice"
										sx={{
											"& button:not(:last-of-type)": {
												borderColor: "#bdbdbd!important",
											},
										}}
									>
										<Button
											onClick={() => setHadChinaProjects(true)}
											color={hadChinaProjects ? "primary" : "inherit"}
											sx={{
												backgroundColor: hadChinaProjects
													? "primary.main"
													: "#fafafa",
												color: hadChinaProjects
													? "common.white"
													: "text.primary",
											}}
										>
											{t("companyOnboarding.yes")}
										</Button>
										<Button
											onClick={() => setHadChinaProjects(false)}
											sx={{
												backgroundColor:
													!hadChinaProjects && hadChinaProjects !== undefined
														? "primary.main"
														: "#fafafa",
												color:
													!hadChinaProjects && hadChinaProjects !== undefined
														? "common.white"
														: "text.primary",
												"&:hover": {
													backgroundColor:
														hadChinaProjects || hadChinaProjects === undefined
															? "#f5f5f5!important"
															: "primary.main",
												},
											}}
										>
											{t("companyOnboarding.no")}
										</Button>
									</ButtonGroup>
								}
								label={t("companyOnboarding.chinaProjects")}
								sx={{
									paddingLeft: 1.5,
									marginTop: 2,
									"@media (max-width: 600px)": {
										marginBottom: 2,
									},
									"& .MuiFormControlLabel-label": {
										fontSize: "14px",
										paddingLeft: 1,
										fontWeight: "500",
									},
									display: "block",
								}}
							/>
						</Grid>
					</Grid>

					{hadHistoricalData === null ||
						(hadHistoricalData !== undefined && (
							<Box
								sx={{
									marginBottom: 3,
									marginTop: 2,
								}}
							>
								<Alert severity="warning">
									<Typography variant="body2" sx={{ fontWeight: "bold" }}>
										{t("companyOnboarding.fees")}
									</Typography>
								</Alert>
							</Box>
						))}

					<Typography
						variant={"h6"}
						sx={{
							textTransform: "uppercase",
							fontSize: 16,
							fontWeight: "bold",
							marginTop: 6,
							marginBottom: 1,
						}}
					>
						{t("companyOnboarding.attestationForm")}
					</Typography>
					<Divider sx={{ opacity: 0.5, marginBottom: 3 }} />
					<Box sx={{ marginTop: 2, marginBottom: 8 }}>
						<Box>
							<span className={classes.inputLabel}>
								{t("companyOnboarding.legallyRecognizedQuestion")}
							</span>
							<AttestationEntityArrayInput
								array={company?.legally_recognized_companies}
								setArray={(legallyRecognizedCompanies) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												legally_recognized_companies:
													legallyRecognizedCompanies,
											} as ICompany)
									);
								}}
								title={
									<>
										{t("companyOnboarding.legallyRecognizedCompaniesLabel")}{" "}
										<span className={classes.inputLabelRequired}>*</span>
									</>
								}
								required={{
									name: true,
									address: true,
									url: true,
									relationship: true,
								}}
							/>
							<AttestationEntityArrayInput
								array={company?.government_entities}
								setArray={(governmentEntities) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												government_entities: governmentEntities,
											} as ICompany)
									);
								}}
								title={
									<>
										{t("companyOnboarding.governmentEntitiesLabel")}{" "}
										<span className={classes.inputLabelRequired}>*</span>
									</>
								}
								required={{
									name: true,
									address: true,
									url: true,
									relationship: true,
								}}
							/>
						</Box>
						<Box sx={{ marginTop: 4 }}>
							<span
								style={{ paddingTop: "32px!important" }}
								className={classes.inputLabel}
							>
								{t("companyOnboarding.payorCompanyQuestion")}
							</span>
							<AttestationEntityArrayInput
								array={company?.payor_companies}
								setArray={(payorCompanies) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												payor_companies: payorCompanies,
											} as ICompany)
									);
								}}
								showRelationship
								title={t("companyOnboarding.payorCompanyLabel")}
								required={{
									name: false,
									address: false,
									url: false,
									relationship: false,
								}}
							/>
						</Box>
						<Box sx={{ marginTop: 4 }}>
							<span className={classes.inputLabel}>
								{t("companyOnboarding.amazonQuestion")}
								<span className={classes.inputLabelRequired}>*</span>
							</span>
							<ArrayInput
								array={company?.company_or_amazon_webpages}
								setArray={(webpages) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												company_or_amazon_webpages: webpages,
											} as ICompany)
									);
								}}
								title={t("companyOnboarding.amazonLabel")}
							/>
						</Box>
						<Box sx={{ marginTop: 4 }}>
							<span className={classes.inputLabel}>
								{t("companyOnboarding.representativeQuestion")}
							</span>
							<span className={classes.inputLabel}>
								{t("companyOnboarding.representativeNameLabel")}
								<span className={classes.inputLabelRequired}>*</span>
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								placeholder={t("companyOnboarding.representativeNameLabel")}
								value={company?.company_representative_name}
								sx={{ marginBottom: 2 }}
								onChange={(e) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												company_representative_name: e.target.value,
											} as ICompany)
									);
								}}
							/>
							<span className={classes.inputLabel}>
								{t("companyOnboarding.representativeTitleLabel")}
								<span className={classes.inputLabelRequired}>*</span>
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								placeholder={t("companyOnboarding.representativeTitleLabel")}
								value={company?.company_representative_title}
								sx={{ marginBottom: 2 }}
								onChange={(e) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												company_representative_title: e.target.value,
											} as ICompany)
									);
								}}
							/>
						</Box>
						<Box sx={{ marginTop: 4 }}>
							<span className={classes.inputLabel}>
								{t("companyOnboarding.entitiesQuestion")}
							</span>
							<FormControlLabel
								control={
									<ButtonGroup
										disableElevation
										variant="contained"
										aria-label={t("companyOnboarding.entitiesDeemedLabel")}
										sx={{
											"& button:not(:last-of-type)": {
												borderColor: "#bdbdbd!important",
											},
										}}
									>
										<Button
											onClick={() =>
												setCompany(
													(prevState) =>
														({
															...prevState,
															are_any_entities_deemed_hostile_to_usa: true,
														} as ICompany)
												)
											}
											color={
												company?.are_any_entities_deemed_hostile_to_usa
													? "primary"
													: "inherit"
											}
											sx={{
												backgroundColor:
													company?.are_any_entities_deemed_hostile_to_usa
														? "primary.main"
														: "#fafafa",
												color: company?.are_any_entities_deemed_hostile_to_usa
													? "common.white"
													: "text.primary",
											}}
										>
											{t("companyOnboarding.yes")}
										</Button>
										<Button
											onClick={() =>
												setCompany(
													(prevState) =>
														({
															...prevState,
															are_any_entities_deemed_hostile_to_usa: false,
														} as ICompany)
												)
											}
											sx={{
												backgroundColor:
													!company?.are_any_entities_deemed_hostile_to_usa &&
													company?.are_any_entities_deemed_hostile_to_usa !==
														undefined
														? "primary.main"
														: "#fafafa",
												color:
													!company?.are_any_entities_deemed_hostile_to_usa &&
													company?.are_any_entities_deemed_hostile_to_usa !==
														undefined
														? "common.white"
														: "text.primary",
												"&:hover": {
													backgroundColor:
														company?.are_any_entities_deemed_hostile_to_usa ||
														company?.are_any_entities_deemed_hostile_to_usa ===
															undefined
															? "#f5f5f5!important"
															: "primary.main",
												},
											}}
										>
											{t("companyOnboarding.no")}
										</Button>
									</ButtonGroup>
								}
								label={
									<>
										{t("companyOnboarding.entitiesDeemedLabel")}{" "}
										<span className={classes.inputLabelRequired}>*</span>
									</>
								}
								sx={{
									paddingLeft: 1.5,
									marginTop: 2,
									"@media (max-width: 600px)": {
										marginBottom: 2,
									},
									"& .MuiFormControlLabel-label": {
										fontSize: "14px",
										paddingLeft: 1,
										fontWeight: "500",
									},
									display: "block",
								}}
							/>
							<FormControlLabel
								control={
									<ButtonGroup
										disableElevation
										variant="contained"
										aria-label={t("companyOnboarding.ofacLabel")}
										sx={{
											"& button:not(:last-of-type)": {
												borderColor: "#bdbdbd!important",
											},
										}}
									>
										<Button
											onClick={() =>
												setCompany(
													(prevState) =>
														({
															...prevState,
															are_any_people_listed_in_ofac: true,
														} as ICompany)
												)
											}
											color={
												company?.are_any_people_listed_in_ofac
													? "primary"
													: "inherit"
											}
											sx={{
												backgroundColor: company?.are_any_people_listed_in_ofac
													? "primary.main"
													: "#fafafa",
												color: company?.are_any_people_listed_in_ofac
													? "common.white"
													: "text.primary",
											}}
										>
											{t("companyOnboarding.yes")}
										</Button>
										<Button
											onClick={() =>
												setCompany(
													(prevState) =>
														({
															...prevState,
															are_any_people_listed_in_ofac: false,
														} as ICompany)
												)
											}
											sx={{
												backgroundColor:
													!company?.are_any_people_listed_in_ofac &&
													company?.are_any_people_listed_in_ofac !== undefined
														? "primary.main"
														: "#fafafa",
												color:
													!company?.are_any_people_listed_in_ofac &&
													company?.are_any_people_listed_in_ofac !== undefined
														? "common.white"
														: "text.primary",
												"&:hover": {
													backgroundColor:
														company?.are_any_people_listed_in_ofac ||
														company?.are_any_people_listed_in_ofac === undefined
															? "#f5f5f5!important"
															: "primary.main",
												},
											}}
										>
											{t("companyOnboarding.no")}
										</Button>
									</ButtonGroup>
								}
								label={
									<>
										{t("companyOnboarding.ofacLabel")}{" "}
										<a href="https://us.practicallaw.thomsonreuters.com/Document/Ibb0a1462ef0511e28578f7ccc38dcbee/View/FullText.html?originationContext=document&vr=3.0&rs=cblt1.0&transitionType=DocumentItem&contextData=(sc.Search)">
											Office of Foreign Assets Control
										</a>{" "}
										{t("companyOnboarding.ofacLabel2")}
										<span className={classes.inputLabelRequired}>*</span>
									</>
								}
								sx={{
									paddingLeft: 1.5,
									marginTop: 2,
									"@media (max-width: 600px)": {
										marginBottom: 2,
									},
									"& .MuiFormControlLabel-label": {
										fontSize: "14px",
										paddingLeft: 1,
										fontWeight: "500",
									},
									display: "block",
								}}
							/>
							<FormControlLabel
								control={
									<ButtonGroup
										disableElevation
										variant="contained"
										aria-label={t("companyOnboarding.moneyLaunderingLabel")}
										sx={{
											"& button:not(:last-of-type)": {
												borderColor: "#bdbdbd!important",
											},
										}}
									>
										<Button
											onClick={() =>
												setCompany(
													(prevState) =>
														({
															...prevState,
															are_any_people_engaged_in_money_laundering: true,
														} as ICompany)
												)
											}
											color={
												company?.are_any_people_engaged_in_money_laundering
													? "primary"
													: "inherit"
											}
											sx={{
												backgroundColor:
													company?.are_any_people_engaged_in_money_laundering
														? "primary.main"
														: "#fafafa",
												color:
													company?.are_any_people_engaged_in_money_laundering
														? "common.white"
														: "text.primary",
											}}
										>
											{t("companyOnboarding.yes")}
										</Button>
										<Button
											onClick={() =>
												setCompany(
													(prevState) =>
														({
															...prevState,
															are_any_people_engaged_in_money_laundering: false,
														} as ICompany)
												)
											}
											sx={{
												backgroundColor:
													!company?.are_any_people_engaged_in_money_laundering &&
													company?.are_any_people_engaged_in_money_laundering !==
														undefined
														? "primary.main"
														: "#fafafa",
												color:
													!company?.are_any_people_engaged_in_money_laundering &&
													company?.are_any_people_engaged_in_money_laundering !==
														undefined
														? "common.white"
														: "text.primary",
												"&:hover": {
													backgroundColor:
														company?.are_any_people_engaged_in_money_laundering ||
														company?.are_any_people_engaged_in_money_laundering ===
															undefined
															? "#f5f5f5!important"
															: "primary.main",
												},
											}}
										>
											{t("companyOnboarding.no")}
										</Button>
									</ButtonGroup>
								}
								label={
									<>
										{t("companyOnboarding.moneyLaunderingLabel")}{" "}
										<span className={classes.inputLabelRequired}>*</span>
									</>
								}
								sx={{
									paddingLeft: 1.5,
									marginTop: 2,
									"@media (max-width: 600px)": {
										marginBottom: 2,
									},
									"& .MuiFormControlLabel-label": {
										fontSize: "14px",
										paddingLeft: 1,
										fontWeight: "500",
									},
									display: "block",
								}}
							/>
							<FormControlLabel
								control={
									<ButtonGroup
										disableElevation
										variant="contained"
										aria-label={t("companyOnboarding.tradeRestrictionsLabel")}
										sx={{
											"& button:not(:last-of-type)": {
												borderColor: "#bdbdbd!important",
											},
										}}
									>
										<Button
											onClick={() =>
												setCompany(
													(prevState) =>
														({
															...prevState,
															are_any_people_subject_to_usa_trade_restrictions:
																true,
														} as ICompany)
												)
											}
											color={
												company?.are_any_people_subject_to_usa_trade_restrictions
													? "primary"
													: "inherit"
											}
											sx={{
												backgroundColor:
													company?.are_any_people_subject_to_usa_trade_restrictions
														? "primary.main"
														: "#fafafa",
												color:
													company?.are_any_people_subject_to_usa_trade_restrictions
														? "common.white"
														: "text.primary",
											}}
										>
											{t("companyOnboarding.yes")}
										</Button>
										<Button
											onClick={() =>
												setCompany(
													(prevState) =>
														({
															...prevState,
															are_any_people_subject_to_usa_trade_restrictions:
																false,
														} as ICompany)
												)
											}
											sx={{
												backgroundColor:
													!company?.are_any_people_subject_to_usa_trade_restrictions &&
													company?.are_any_people_subject_to_usa_trade_restrictions !==
														undefined
														? "primary.main"
														: "#fafafa",
												color:
													!company?.are_any_people_subject_to_usa_trade_restrictions &&
													company?.are_any_people_subject_to_usa_trade_restrictions !==
														undefined
														? "common.white"
														: "text.primary",
												"&:hover": {
													backgroundColor:
														company?.are_any_people_subject_to_usa_trade_restrictions ||
														company?.are_any_people_subject_to_usa_trade_restrictions ===
															undefined
															? "#f5f5f5!important"
															: "primary.main",
												},
											}}
										>
											{t("companyOnboarding.no")}
										</Button>
									</ButtonGroup>
								}
								label={
									<>
										{t("companyOnboarding.tradeRestrictionsLabel")}{" "}
										<span className={classes.inputLabelRequired}>*</span>
									</>
								}
								sx={{
									paddingLeft: 1.5,
									marginTop: 2,
									"@media (max-width: 600px)": {
										marginBottom: 2,
									},
									"& .MuiFormControlLabel-label": {
										fontSize: "14px",
										paddingLeft: 1,
										fontWeight: "500",
									},
									display: "block",
								}}
							/>
						</Box>

						<Box sx={{ marginTop: 6 }}>
							<span className={classes.inputLabel}>
								{t("companyOnboarding.numberOfSalesQuestion")}
								<span className={classes.inputLabelRequired}>*</span>
							</span>
							<NumericFormat
								value={company?.total_first_year_forecasted_sales}
								name={"amount"}
								placeholder={t("companyOnboarding.numberOfSalesLabel")}
								customInput={TextField}
								type="text"
								thousandSeparator={","}
								allowNegative={false}
								fullWidth
								onValueChange={({ floatValue }) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												total_first_year_forecasted_sales: floatValue
													? Math.floor(floatValue)
													: undefined,
											} as ICompany)
									);
								}}
								style={{
									flex: 1,
									minWidth: "140px",
								}}
							/>
						</Box>

						<Divider sx={{ opacity: 0.5, marginTop: 4, marginBottom: 4 }} />
						<span className={classes.inputLabel}>
							{t("companyOnboarding.disclaimer")}
						</span>
					</Box>
				</Box>
				<Box
					sx={{
						backgroundColor: "#fff",
						height: "120px",
						"@media (max-width: 600px)": {
							height: "150px",
						},
						borderTop: "1px solid #ccc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							flexDirection: "row",
							"@media (max-width: 600px)": {
								flexDirection: "column",
								alignItems: "flex-start",
							},
						}}
					>
						<FormControlLabel
							value="end"
							control={
								<Checkbox
									size="small"
									checked={policyCheckbox}
									onChange={(e) => {
										setPolicyCheckbox(e.target.checked);
									}}
								/>
							}
							label={t("companyOnboarding.confirmReadTerms")}
							labelPlacement="end"
							sx={{ marginRight: 0.5, span: { fontSize: 14 } }}
						/>
						<Box
							sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
						>
							<a
								style={{ fontSize: 14, color: "#25406D", marginRight: 4 }}
								href="https://products.climeco.com/termsofuse.pdf"
								rel="noreferrer"
								target="_blank"
							>
								<b>{t("companyOnboarding.termsOfUse")}</b>
							</a>
							<span style={{ fontSize: 14, marginRight: 4 }}>
								{t("companyOnboarding.and")}
							</span>
							<a
								style={{ fontSize: 14, color: "#25406D" }}
								href="https://products.climeco.com/privacypolicy.pdf"
								rel="noreferrer"
								target="_blank"
							>
								<b>{t("companyOnboarding.privacyPolicy")}</b>
							</a>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							flexDirection: "row",
							"@media (max-width: 600px)": {
								flexDirection: "column",
								alignItems: "flex-start",
							},
						}}
					>
						<FormControlLabel
							value="end"
							control={
								<Checkbox
									size="small"
									checked={protocolCheckbox}
									onChange={(e) => {
										setProtocolCheckbox(e.target.checked);
									}}
								/>
							}
							label={t("companyOnboarding.confirmReadProtocol")}
							labelPlacement="end"
							sx={{ marginRight: 0.5, span: { fontSize: 14 } }}
						/>

						<a
							style={{ fontSize: 14, color: "#25406D" }}
							href={
								isRemoteAvailable ? productProtocolUrl : productProtocolAsset
							}
							rel="noreferrer"
							target="_blank"
							download={
								!isRemoteAvailable
									? "ClimeCo-Certified-Product-Protocol.pdf"
									: undefined
							}
						>
							<b>{t("companyOnboarding.productCertificationProtocol")}</b>
						</a>
					</Box>
					<Button
						variant="contained"
						onClick={createCompany}
						disabled={validateCompany()}
						sx={{
							marginTop: 0,
							maxWidth: 300,
							alignSelf: "end",
							minWidth: 100,
							minHeight: 34,
						}}
					>
						{isLoading ? (
							<CircularProgress size={12} sx={{ color: "white" }} />
						) : (
							t("companyOnboarding.save")
						)}
					</Button>
				</Box>
			</FormControl>
		</Box>
	);
}
