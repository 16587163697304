import { PropTypes } from "./UserActionBar.types";
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BasicModal } from "../BasicModal";
import { DeleteConfirmation } from "../DeleteConfirmation";
import { IUser, ICompany } from "../../models";
import { user as userActions } from "../../controllers";
import { company as companyActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";

export function UserActionBar({
	selectedRows,
	refetch,
	filters,
	setFilters,
	setPaginationModel,
}: PropTypes) {
	const navigate = useNavigate();
	const [showDelete, setShowDelete] = useState(false);
	const [searchParams] = useSearchParams();
	const [selectedCompanies, setSelectedCompanies] = useState<Array<ICompany>>(
		[]
	);
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [companiesSearch, setCompaniesSearch] = useState("");
	const { user } = useContext(UserContext);
	const { t } = useTranslation();

	const handleDeleteModalClose = () => {
		setShowDelete(false);
	};

	const deleteItem = () => {
		selectedRows.forEach((selectedRow) => {
			userActions.delete(
				selectedRow as IUser,
				!user?.is_superuser ? user?.company.id : undefined
			);
		});
		handleDeleteModalClose();
		refetch();
	};

	const getSelectionRowsTitle = () => {
		let title = "";
		selectedRows.forEach((selectedRow) => {
			title =
				title.length > 0
					? title + ", " + (selectedRow as IUser).username
					: (selectedRow as IUser).username;
		});

		return title;
	};

	const handleSearchChange = (value: string) => {
		setCompaniesSearch(value);
	};

	const { refetch: refetchCompanies, isLoading: isLoadingCompanies } = useQuery(
		"companies",
		() => companyActions.list(companiesSearch, undefined, "name", 999),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		refetchCompanies();
	}, [refetchCompanies, companiesSearch]);

	useEffect(() => {
		refetchCompanies();
	}, [refetchCompanies, companiesSearch]);

	const { refetch: refetchCompaniesInSearch } = useQuery(
		"companies-in-search",
		() =>
			companyActions.list(
				"",
				undefined,
				"name",
				999,
				searchParams.get("companies")
					? searchParams
							.get("companies")!
							.split(",")
							.map((c) => parseInt(c))
					: []
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setSelectedCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (searchParams.get("companies")) {
			refetchCompaniesInSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refetchCompaniesInSearch]);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					flex: 1,
				}}
			>
				{user && user.is_superuser && (
					<Box
						sx={{
							display: "flex",
							flexDirection: {
								xs: "column",
								sm: "column",
								md: "column",
								lg: "row",
							},
							flex: 1,
							alignItems: "center",
							justifyContent: "flex-start",
							marginTop: "0px",
							alignSelf: "flex-start",
							gap: {
								xs: 2,
								sm: 2,
								md: 2,
								lg: 0,
							},
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: {
									xs: "column",
									sm: "column",
									md: "column",
									lg: "row",
								},
								flex: 1,
								alignItems: {
									xs: "flex-start",
									sm: "flex-start",
									md: "flex-start",
									lg: "flex-end",
								},
								justifyContent: "flex-start",
								marginTop: "0px",
								alignSelf: "flex-start",
								gap: 2,
								width: "100%",
							}}
						>
							<Autocomplete
								disablePortal
								id="company-box"
								options={companies}
								value={selectedCompanies}
								sx={{
									width: {
										xs: 240,
										sm: 240,
										md: 240,
										lg: 240,
										xl: 300,
									},
									borderRadius: "24px",
									borderColor: "#ececec",
									"& .MuiFormControl-root": {
										border: "none!important",
									},
									"& .MuiInputBase-root": {
										padding: "8px",
										overflow: "auto",
										maxHeight: "38px",
										border: "none!important",
									},
									marginTop: "40px",
								}}
								multiple
								getOptionKey={(company) => company?.id ?? ""}
								getOptionLabel={(company) => company?.name ?? ""}
								onChange={(_, value) => {
									setSelectedCompanies(value);
									setPaginationModel({
										page: 0,
										pageSize: 10,
									});
									setFilters((prev) => ({
										...prev,
										companies: value.map((company) => company!.id),
									}));
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										placeholder={t("general.company")}
										sx={{
											maxHeight: 56,
											overflow: "auto!important",
											border: "1px solid #ececec",
											borderRadius: 2,
											backgroundColor: "#fff",
											"& input": {
												fontSize: 14,
											},
										}}
										onChange={(ev) => {
											if (ev.target.value !== "" || ev.target.value !== null) {
												handleSearchChange(ev.target.value);
											}
										}}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{isLoadingCompanies ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
							/>
						</Box>
					</Box>
				)}
				{selectedRows && selectedRows.length && user!.is_staff ? (
					<>
						<Button
							variant="outlined"
							sx={{ marginRight: 2, maxWidth: "200px" }}
							onClick={() => {
								setShowDelete(true);
							}}
						>
							{t("userActionBar.delete")}
						</Button>
					</>
				) : (
					<></>
				)}
				<Button
					variant="contained"
					sx={{ marginRight: 2 }}
					onClick={() => {
						navigate("/user");
					}}
				>
					{t("userActionBar.create")}
				</Button>
			</Box>
			<BasicModal
				width={600}
				showModal={showDelete}
				handleClose={handleDeleteModalClose}
				children={
					<DeleteConfirmation
						title={getSelectionRowsTitle()}
						handleDeleteModalClose={handleDeleteModalClose}
						callback={deleteItem}
					/>
				}
				hideClose
			/>
		</>
	);
}
