import { useState, useEffect, useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import { Heading } from "../Heading";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
} from "@mui/x-data-grid";
import { useMutation, useQuery } from "react-query";
import { company as companyActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { ICompany, IEntity } from "../../models";
import { CompanyActionBar } from "../CompanyActionBar";
import { Filters } from "../../routes/Routes.types";
import { UserContext } from "../../contexts/user";
import { useTranslation } from "react-i18next";

export function Companies() {
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState(searchParams.get("search") ?? "");
	const [filters, setFilters] = useState<Filters>({
		...(user?.is_superuser
			? {
					companies: searchParams.get("companies")
						? searchParams
								.get("companies")!
								.split(",")
								.map((c) => parseInt(c))
						: [],
			  }
			: {}),
	});
	const [paginationModel, setPaginationModel] = useState({
		page: searchParams.get("page") ? parseInt(searchParams.get("page")!) : 0,
		pageSize:
			searchParams.get("pageSize") &&
			!isNaN(parseInt(searchParams.get("pageSize")!))
				? parseInt(searchParams.get("pageSize")!)
				: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: searchParams.get("order")
				? searchParams.get("order")!.replaceAll("-", "")
				: "updated_at",
			sort: searchParams.get("order")
				? searchParams.get("order")!.includes("-")
					? "desc"
					: "asc"
				: "desc",
		})
	);
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [rowCount, setRowCount] = useState<number>(0);
	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);

	useEffect(() => {
		const searchValue = searchParams.get("search");
		if ((searchValue ?? "") !== search) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if (search) {
					params.set("search", search);
				} else {
					params.delete("search");
				}
				return params;
			});
		}
	}, [search, searchParams, setSearchParams]);

	useEffect(() => {
		const companiesValue = searchParams.get("companies");
		if (companiesValue !== (filters.companies?.join(",") || null)) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if ((filters.companies ?? []).length > 0) {
					params.set("companies", filters.companies!.join(","));
				} else {
					params.delete("companies");
				}
				return params;
			});
		}
	}, [filters, searchParams, setSearchParams]);

	useEffect(() => {
		const pageValue = searchParams.get("page");
		const pageSizeValue = searchParams.get("page");
		if (
			pageValue !== paginationModel.page.toString() ||
			pageSizeValue !== paginationModel.pageSize.toString()
		) {
			setSearchParams((params) => {
				if (pageValue !== paginationModel.page.toString()) {
					if (paginationModel.page !== 0) {
						params.set("page", paginationModel.page.toString());
					} else {
						params.delete("page");
					}
				}
				if (pageSizeValue !== paginationModel.pageSize.toString()) {
					if (paginationModel.pageSize !== 10) {
						params.set("pageSize", paginationModel.pageSize.toString());
					} else {
						params.delete("pageSize");
					}
				}
				return params;
			});
		}
	}, [paginationModel, searchParams, setSearchParams]);

	useEffect(() => {
		const orderValue = searchParams.get("order");
		if (
			sortModel.length > 0 &&
			orderValue !==
				(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
		) {
			setSearchParams((params) => {
				if (
					sortModel[0].sort !== "desc" ||
					sortModel[0].field !== "updated_at"
				) {
					console.log(
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
					params.set(
						"order",
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
				} else {
					params.delete("order");
				}

				return params;
			});
		}
	}, [sortModel, searchParams, setSearchParams]);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"companies",
		({ signal }) =>
			user?.company?.is_parent_company
				? companyActions.childrenList(
						search,
						paginationModel.page + 1,
						sortModel.length > 0
							? `${sortModel[0].sort === "desc" ? "-" : ""}${
									sortModel[0].field === "company"
										? "company__name"
										: sortModel[0].field
							  }`
							: "-updated_at",
						10,
						signal
				  )
				: companyActions.list(
						search,
						paginationModel.page + 1,
						sortModel.length > 0
							? `${sortModel[0].sort === "desc" ? "-" : ""}${
									sortModel[0].field === "company"
										? "company__name"
										: sortModel[0].field
							  }`
							: "-updated_at",
						10,
						undefined,
						signal
				  ),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, filters, paginationModel, sortModel]);

	useEffect(() => {
		setPaginationModel({
			page: 0,
			pageSize: 10,
		});
	}, [search]);

	const { mutate: mutateImpersonation } = useMutation(
		"impersonate",
		({ childCompanyId }: { childCompanyId: number }) => {
			return companyActions.impersonate(childCompanyId);
		},
		{
			onSuccess: () => {
				window.location.href = window.location.origin;
			},
			onError: (error: any) => {
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const columns = [
		{
			field: "id",
			headerName: t("companies.id"),
			valueGetter: ({ value }: { value: number }) =>
				Intl.NumberFormat("en", {
					maximumFractionDigits: 4,
				}).format(value),
		},
		{ field: "name", headerName: t("companies.title"), flex: 1, minWidth: 150 },
		{
			field: "created_at",
			headerName: t("companies.createdAt"),
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<any, Date>) => {
				return (
					<Typography sx={{ marginLeft: 2, fontSize: 14 }}>
						{params.row.created_at &&
							Intl.DateTimeFormat("en", {
								day: "numeric",
								month: "numeric",
								year: "numeric",
								timeZone: "UTC",
							}).format(new Date(params.row.created_at))}
					</Typography>
				);
			},
		},
		{
			field: "current_offsets_balance",
			headerName: t("companies.balance"),
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<any, Date>) => {
				return (
					<Typography sx={{ marginLeft: 2, fontSize: 14 }}>
						{new Intl.NumberFormat("en-US").format(
							params.row.current_offsets_balance
						)}
					</Typography>
				);
			},
		},
		{
			field: "action",
			headerName: t("companies.actions"),
			sortable: false,
			headerAlign: "right",
			align: "right",
			width: 200,
			renderCell: (row: GridRenderCellParams<any, Date>) => {
				const onClickEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation();
					navigate("/company/" + row.id);
				};

				const onClickAccessCompany = (
					e: React.MouseEvent<HTMLButtonElement>
				) => {
					e.stopPropagation();
					const companyId = Number(row.id);
					if (!isNaN(companyId)) {
						mutateImpersonation({ childCompanyId: companyId });
					} else {
						console.log("Invalid company ID");
					}
				};

				return (
					<>
						{user?.is_superuser && (
							<Button onClick={onClickEdit}>{t("companies.view")}</Button>
						)}
						{user?.company?.is_parent_company && (
							<Button onClick={onClickAccessCompany}>
								{t("companies.accessCompany")}
							</Button>
						)}
					</>
				);
			},
		},
	];

	return (
		<Box>
			<Heading
				title={t("companies.companies")}
				showSearch
				actions={() => (
					<CompanyActionBar
						selectedRows={selectedRows}
						refetch={refetch}
						filters={filters}
						setFilters={setFilters}
					/>
				)}
				setSearch={setSearch}
			/>
			<DataTable
				rows={companies}
				columns={columns}
				setSelectedRows={setSelectedRows}
				rowCount={rowCount}
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				sortModel={sortModel}
				setSortModel={setSortModel}
				isLoading={isLoading || isRefetching || isFetching}
				sx={{
					border: "1px solid #E3E3E3",
					borderRadius: "28px",
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-columnHeaders": {},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none!important",
					},
				}}
			/>
		</Box>
	);
}
