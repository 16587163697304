import { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Heading } from "../Heading";
import {
	ICertification,
	ICertificationSteps,
	ICompany,
	IEntity,
} from "../../models";
import { useNavigate, useSearchParams } from "react-router-dom";
import DataTable from "../DataTable/DataTable";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
	GridValueFormatterParams,
	GridValueGetterParams,
} from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { certification as certificationActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { ClimecoCertificationActionBar } from "../ClimecoCertificationActionBar";
import { useSnackbar } from "notistack";
import { Filters } from "../../routes/Routes.types";
import { useTranslation } from "react-i18next";
import { PropTypes } from "./ClimecoCertifications.types";

export function ClimecoCertifications({ companyId }: PropTypes) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const { user } = useContext(UserContext);
	const [certifications, setCertifications] = useState<Array<ICertification>>(
		[]
	);
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState(searchParams.get("search") ?? "");
	const [filters, setFilters] = useState<Filters>({
		...(user?.is_superuser
			? {
					companies: searchParams.get("companies")
						? searchParams
								.get("companies")!
								.split(",")
								.map((c) => parseInt(c))
						: [],
			  }
			: {}),
	});
	const [paginationModel, setPaginationModel] = useState({
		page: searchParams.get("page") ? parseInt(searchParams.get("page")!) : 0,
		pageSize:
			searchParams.get("pageSize") &&
			!isNaN(parseInt(searchParams.get("pageSize")!))
				? parseInt(searchParams.get("pageSize")!)
				: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: searchParams.get("order")
				? searchParams.get("order")!.replaceAll("-", "")
				: "updated_at",
			sort: searchParams.get("order")
				? searchParams.get("order")!.includes("-")
					? "desc"
					: "asc"
				: "desc",
		})
	);
	const [rowCount, setRowCount] = useState<number>(0);
	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);

	useEffect(() => {
		const searchValue = searchParams.get("search");
		if ((searchValue ?? "") !== search) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if (search) {
					params.set("search", search);
				} else {
					params.delete("search");
				}
				return params;
			});
		}
	}, [search, searchParams, setSearchParams]);

	useEffect(() => {
		const companiesValue = searchParams.get("companies");
		if (companiesValue !== (filters.companies?.join(",") || null)) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if ((filters.companies ?? []).length > 0) {
					params.set("companies", filters.companies!.join(","));
				} else {
					params.delete("companies");
				}
				return params;
			});
		}
	}, [filters, searchParams, setSearchParams]);

	useEffect(() => {
		const pageValue = searchParams.get("page");
		const pageSizeValue = searchParams.get("page");
		if (
			pageValue !== paginationModel.page.toString() ||
			pageSizeValue !== paginationModel.pageSize.toString()
		) {
			setSearchParams((params) => {
				if (pageValue !== paginationModel.page.toString()) {
					if (paginationModel.page !== 0) {
						params.set("page", paginationModel.page.toString());
					} else {
						params.delete("page");
					}
				}
				if (pageSizeValue !== paginationModel.pageSize.toString()) {
					if (paginationModel.pageSize !== 10) {
						params.set("pageSize", paginationModel.pageSize.toString());
					} else {
						params.delete("pageSize");
					}
				}
				return params;
			});
		}
	}, [paginationModel, searchParams, setSearchParams]);

	useEffect(() => {
		const orderValue = searchParams.get("order");
		if (
			sortModel.length > 0 &&
			orderValue !==
				(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
		) {
			setSearchParams((params) => {
				if (
					sortModel[0].sort !== "desc" ||
					sortModel[0].field !== "updated_at"
				) {
					console.log(
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
					params.set(
						"order",
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
				} else {
					params.delete("order");
				}

				return params;
			});
		}
	}, [sortModel, searchParams, setSearchParams]);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"climeco-certifications",
		({ signal }) =>
			certificationActions.list(
				true,
				companyId
					? companyId
					: user?.is_superuser
					? undefined
					: user?.company.id,
				search,
				filters.companies,
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field === "estimations"
								? "estimations__product__name"
								: sortModel[0].field
					  }`
					: "-updated_at",
				paginationModel.pageSize,
				undefined,
				signal
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCertifications(res.results);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, filters, paginationModel, sortModel]);

	const navigate = useNavigate();
	const columns = [
		{
			field: "id",
			headerName: t("climecoCertifications.id"),
			valueGetter: ({ value }: { value: number }) =>
				Intl.NumberFormat("en", {
					maximumFractionDigits: 4,
				}).format(value),
		},
		{
			field: "name",
			headerName: t("climecoCertifications.title"),
			flex: 1,
			minWidth: 150,
		},
		{
			field: "estimations",
			headerName: t("climecoCertifications.products"),
			sortable: false,
			flex: 1,
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams<any>) => {
				if (params.value == null) {
					return "";
				}
				return params.value && params.value.length;
			},
		},
		{
			field: "step",
			headerName: t("climecoCertifications.step"),
			sortable: false,
			flex: 1,
			renderCell: (row: GridRenderCellParams<any, Date>) => {
				const getStep = () => {
					if (row.row.certification && row.row.certification_process_steps) {
						const rejectedStep = row.row.certification_process_steps.findIndex(
							(step: ICertificationSteps) => step.status === "REJECTED"
						);

						const pendingStep = row.row.certification_process_steps.findIndex(
							(step: ICertificationSteps) => step.status === "PENDING"
						);

						return rejectedStep > -1
							? rejectedStep
							: pendingStep > -1
							? pendingStep
							: row.row.certification_process_steps.length - 1;
					} else {
						return 0;
					}
				};

				return (
					<Typography variant="body2">
						{row.row.certification_process_steps[getStep()].status ===
						"REJECTED"
							? t("lcas.statuses.rejected")
							: row.row.certification_process_steps[getStep()].step
									?.step_title || "--"}
					</Typography>
				);
			},
		},
		{
			field: "company",
			headerName: t("climecoCertifications.company"),
			valueFormatter: (params: GridValueFormatterParams<ICompany>) => {
				if (params.value == null) {
					return "";
				}
				return params.value.name;
			},
			flex: 1,
			minWidth: 150,
		},
		{
			field: "approvedby",
			headerName: t("climecoCertifications.approvedBy"),
			valueGetter: (params: GridValueGetterParams) => {
				return params.row.certification_process_steps;
			},
			valueFormatter: (
				params: GridValueFormatterParams<Array<ICertificationSteps>>
			) => {
				if (params.value == null || params.value.length === 0) {
					return "--";
				}
				let approvers: Array<string> = [];
				params.value.forEach((step) => {
					if (
						step.admin &&
						!approvers.find(
							(approver) => step.admin && approver === step.admin.email
						)
					) {
						approvers.push(step.admin.email);
					}
				});
				return approvers.length > 0 ? approvers.join(", ") : "--";
			},
			flex: 1,
			minWidth: 150,
		},
		{
			field: "certification_date",
			headerName: t("climecoCertifications.dateOfCertification"),
			valueGetter: (params: GridValueGetterParams) => {
				return params.row;
			},
			valueFormatter: (params: GridValueFormatterParams<ICertification>) => {
				if (
					params.value &&
					params.value.certification_process_steps.length > 0 &&
					params.value.certification_process_steps[
						params.value.certification_process_steps.length - 1
					].status === "COMPLETED" &&
					params.value.certification_process_steps[
						params.value.certification_process_steps.length - 1
					].step?.step_type === "OTHER" &&
					params.value.lca_versions &&
					params.value.lca_versions.length > 0
				) {
					return new Intl.DateTimeFormat("en", {
						day: "2-digit",
						month: "2-digit",
						year: "numeric",
						timeZone: "UTC",
					}).format(
						new Date(
							params.value.lca_versions.length > 0 &&
							params.value.lca_versions[0].certification_date
								? params.value.lca_versions[0].certification_date
								: params.value.certification_process_steps[
										params.value.certification_process_steps.length - 1
								  ].updated_at ?? ""
						)
					);
				} else {
					return t("climecoCertifications.notCertifiedYet");
				}
			},
			flex: 1,
			minWidth: 150,
		},
		{
			field: "action",
			headerName: t("climecoCertifications.actions"),
			sortable: false,
			headerAlign: "right",
			align: "right",
			renderCell: (row: GridRenderCellParams<any, any>) => {
				const onClickEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation();
					navigate(
						(row.row.is_historical
							? "/historical-data/"
							: "/climeco-certificationss/") + row.id,
						{
							state: {
								previousPage: window.location.href.includes(":3000")
									? window.location.href.split(":3000")[1]
									: window.location.href.split(".com")[1],
							},
						}
					);
				};

				return (
					<>
						<Button onClick={onClickEdit}>
							{t("climecoCertifications.edit")}
						</Button>
					</>
				);
			},
		},
	];

	if (!user?.is_superuser) {
		columns.splice(4, 1);
		columns.splice(4, 1);
	} else if (companyId) {
		columns.splice(4, 1);
	}

	return (
		<Box>
			{!companyId && (
				<Heading
					title={t("climecoCertifications.climecoCertifications")}
					showSearch={true}
					setSearch={setSearch}
					actions={() => (
						<ClimecoCertificationActionBar
							selectedRows={selectedRows}
							refetch={refetch}
							filters={filters}
							setFilters={setFilters}
							setPaginationModel={setPaginationModel}
						/>
					)}
				/>
			)}
			<DataTable
				rows={certifications}
				columns={columns}
				setSelectedRows={setSelectedRows}
				rowCount={rowCount}
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				sortModel={sortModel}
				setSortModel={setSortModel}
				isLoading={isLoading || isRefetching || isFetching}
				sx={{
					border: "1px solid #E3E3E3",
					borderRadius: "28px",
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-columnHeaders": {},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none!important",
					},
				}}
			/>
		</Box>
	);
}
