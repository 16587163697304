import { INotification } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"

class Notification {
    async list(limit: number, companyId?: number, search?: string, notViewed?: boolean, offset?: number): Promise<{ count: number, results: Array<INotification> }> {
        const endpoint = companyId ? "companies/" + companyId + "/notifications" : "notifications"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                page_size: limit, page: offset !== undefined ? Math.round((offset / limit) + 1) : undefined, search: search, not_viewed: notViewed ?? undefined, ordering: "-created_at",
                //company__is_test_company: localStorage.getItem("showTestData") ? undefined : false,
                product__company__is_test_company: localStorage.getItem("showTestData") ? undefined : false,
                //user__is_test_company: localStorage.getItem("showTestData") ? undefined : false,
                extra_company__is_test_company: localStorage.getItem("showTestData") ? undefined : false,
                lca_version__lca__company__is_test_company: localStorage.getItem("showTestData") ? undefined : false,
                certification_process__company__is_test_company: localStorage.getItem("showTestData") ? undefined : false,
                payment__company__is_test_company: localStorage.getItem("showTestData") ? undefined : false,
                sale__product__company__is_test_company: localStorage.getItem("showTestData") ? undefined : false
            }
        });
        return response.data;
    }

    async toggleViewed(id: number): Promise<Array<INotification>> {
        const endpoint = "notifications/" + id + "/toggle-viewed/"
        const response = await connectionsClient.client.patch(endpoint, {});
        return response.data.results;
    }
}

export const notification = new Notification();