import { useState, useEffect, useContext } from "react";
import { Box, Chip, IconButton, Typography, Button } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import { Heading } from "../Heading";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
	GridValueGetterParams,
} from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { lca as lcaActions } from "../../controllers";
import { useSnackbar } from "notistack";
import {
	IEntity,
	IGetCertifiedStep,
	ILCA,
	ILCAVersion,
	IProduct,
} from "../../models";
import { UserContext } from "../../contexts/user";
import { Filters } from "../../routes/Routes.types";
import { BasicModal } from "../BasicModal";
import { LCAActionBar } from "../LCAActionBar";
import { AiOutlineEye as Visibility } from "react-icons/ai";
import { TiDocumentAdd as AddIcon } from "react-icons/ti";
import { TiEdit as EditIcon } from "react-icons/ti";
import { useTranslation } from "react-i18next";

export function LCAs() {
	const { t } = useTranslation();
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const location = useLocation();
	const [showProductsModal, setShowProductsModal] = useState(false);
	const [showUpdateLCAModal, setShowUpdateLCAModal] = useState(false);
	const [showAddProductsModal, setShowAddProductsModal] = useState(false);
	const [showLCAsModal, setShowLCAsModal] = useState(
		location.state?.fromExistingLCA ? !location.state?.fromExistingLCA : true
	);
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState(searchParams.get("search") ?? "");
	const [filters, setFilters] = useState<Filters>({
		...(user?.is_superuser
			? {
					companies: searchParams.get("companies")
						? searchParams
								.get("companies")!
								.split(",")
								.map((c) => parseInt(c))
						: [],
			  }
			: {}),
	});
	const [paginationModel, setPaginationModel] = useState({
		page: searchParams.get("page") ? parseInt(searchParams.get("page")!) : 0,
		pageSize:
			searchParams.get("pageSize") &&
			!isNaN(parseInt(searchParams.get("pageSize")!))
				? parseInt(searchParams.get("pageSize")!)
				: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: searchParams.get("order")
				? searchParams.get("order")!.replaceAll("-", "")
				: "updated_at",
			sort: searchParams.get("order")
				? searchParams.get("order")!.includes("-")
					? "desc"
					: "asc"
				: "desc",
		})
	);
	const [LCAs, setLCAs] = useState<Array<ILCA>>([]);
	const [rowCount, setRowCount] = useState<number>(0);
	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);
	const [selectedRow, setSelectedRow] = useState<ILCAVersion | null>(null);

	useEffect(() => {
		const searchValue = searchParams.get("search");
		if ((searchValue ?? "") !== search) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if (search) {
					params.set("search", search);
				} else {
					params.delete("search");
				}
				return params;
			});
		}
	}, [search, searchParams, setSearchParams]);

	useEffect(() => {
		const companiesValue = searchParams.get("companies");
		if (companiesValue !== (filters.companies?.join(",") || null)) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if ((filters.companies ?? []).length > 0) {
					params.set("companies", filters.companies!.join(","));
				} else {
					params.delete("companies");
				}
				return params;
			});
		}
	}, [filters, searchParams, setSearchParams]);

	useEffect(() => {
		const pageValue = searchParams.get("page");
		const pageSizeValue = searchParams.get("page");
		if (
			pageValue !== paginationModel.page.toString() ||
			pageSizeValue !== paginationModel.pageSize.toString()
		) {
			setSearchParams((params) => {
				if (pageValue !== paginationModel.page.toString()) {
					if (paginationModel.page !== 0) {
						params.set("page", paginationModel.page.toString());
					} else {
						params.delete("page");
					}
				}
				if (pageSizeValue !== paginationModel.pageSize.toString()) {
					if (paginationModel.pageSize !== 10) {
						params.set("pageSize", paginationModel.pageSize.toString());
					} else {
						params.delete("pageSize");
					}
				}
				return params;
			});
		}
	}, [paginationModel, searchParams, setSearchParams]);

	useEffect(() => {
		const orderValue = searchParams.get("order");
		if (
			sortModel.length > 0 &&
			orderValue !==
				(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
		) {
			setSearchParams((params) => {
				if (
					sortModel[0].sort !== "desc" ||
					sortModel[0].field !== "updated_at"
				) {
					console.log(
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
					params.set(
						"order",
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
				} else {
					params.delete("order");
				}

				return params;
			});
		}
	}, [sortModel, searchParams, setSearchParams]);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"lcas",
		({ signal }) =>
			lcaActions.list(
				!user?.is_superuser ? user!.company.id : undefined,
				search,
				paginationModel.page + 1,
				filters.companies,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field
					  }`
					: "-updated_at",
				paginationModel.pageSize,
				signal
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setLCAs(res.results);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, filters, paginationModel, sortModel]);

	const { refetch: refetchLCA } = useQuery(
		"lca",
		() =>
			lcaActions.getOne(
				selectedRow?.lca_id ?? 0,
				user?.is_superuser ? undefined : user?.company.id ?? 0
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				if (res.lca_versions) {
					const allProducts: IProduct[] = [];

					res.lca_versions.forEach((lcaVersion) => {
						if (
							lcaVersion.lca_standards &&
							!Array.isArray(lcaVersion.lca_standards)
						) {
							lcaVersion.lca_standards = JSON.parse(lcaVersion.lca_standards);
						}

						if (lcaVersion.products) {
							allProducts.push(...lcaVersion.products);
						}
					});

					setSelectedRow({
						...res,
						products: allProducts,
					} as ILCAVersion);
				} else {
					setSelectedRow(null);
				}
			},
			onError: (error: any) => {
				console.log(error);
				setSelectedRow(null);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (selectedRow?.lca_id && showProductsModal) {
			refetchLCA();
		}
	}, [selectedRow?.lca_id, refetchLCA, showProductsModal]);

	const columns = [
		{
			field: "id",
			headerName: t("lcas.tableHeaders.id"),
			valueGetter: ({ value }: { value: number }) =>
				Intl.NumberFormat("en", {
					maximumFractionDigits: 4,
				}).format(value),
			minWidth: 40,
		},
		{
			field: "title",
			headerName: t("lcas.tableHeaders.title"),
			flex: 1,
			renderCell: (row: GridRenderCellParams<Array<string>, any>) => {
				return false && process.env.REACT_APP_ENV === "stage" ? (
					row.value
				) : (
					<a
						href={`/lca/${row.id}`}
						onClick={(e) => {
							e.preventDefault();
							navigate(`/lcas/${row.id}`);
						}}
					>
						{row.value}
					</a>
				);
			},
			minWidth: 150,
		},
		{
			field: "lca_versions",
			headerName: t("lcas.tableHeaders.versions"),
			width: 80,
			valueGetter: ({ value }: { value: Array<ILCAVersion> }) => value.length,
		},
		{
			field: "current_lca_version",
			headerName: t("lcas.tableHeaders.currentVersion"),
			flex: 1,
			minWidth: 150,
			valueGetter: (params: GridValueGetterParams) => params.row.lca_versions,
			renderCell: (row: GridRenderCellParams<Array<ILCAVersion>, any>) => (
				<Box
					sx={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}
				>
					{row.value.length > 0 && row.value[0].lca_document && (
						<a
							href={row.value[0].lca_document}
							download
							target="_blank"
							rel="noreferrer noopener"
						>
							{row.value[0].lca_document?.split("/").pop()}
						</a>
					)}
					{row.value.length > 0 && row.value[0].certification_date
						? new Intl.DateTimeFormat("en", {
								day: "2-digit",
								month: "2-digit",
								year: "numeric",
								timeZone: "UTC",
						  }).format(new Date(row.value[0].certification_date))
						: t("lcas.tableHeaders.noVersionsYet")}
				</Box>
			),
		},
		{
			field: "lca_versions_products",
			headerName: t("lcas.tableHeaders.products"),
			flex: 1,
			minWidth: 150,
			valueGetter: (params: GridValueGetterParams) => {
				return params.row.lca_versions;
			},
			renderCell: (row: GridRenderCellParams<Array<ILCAVersion>, any>) =>
				row.value.length > 0 ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							maxHeight: 40,
							overflow: "auto",
							textAlign: "left",
							gap: 1,
						}}
					>
						<Typography
							variant="body2"
							sx={{ fontSize: 10, fontWeight: "bold" }}
						>
							{t("lcas.totalProducts") + row.value[0].products.length}
						</Typography>
						{row.value[0].products.length > 0 && (
							<IconButton
								size={"small"}
								onClick={(e) => {
									e.stopPropagation();
									setSelectedRow(row.value[0]);
									setShowProductsModal(true);
								}}
							>
								<Visibility />
							</IconButton>
						)}
					</Box>
				) : (
					t("lcas.noProductsYet")
				),
		},
		{
			field: "lca_versions_certification",
			headerName: t("lcas.tableHeaders.certificationStatus"),
			width: 180,
			valueGetter: (params: GridValueGetterParams) => {
				return params.row.lca_versions;
			},
			renderCell: (row: GridRenderCellParams<Array<ILCAVersion>, any>) =>
				row.value.length > 0 && row.value[0].certification_process ? (
					<Chip
						label={
							row.value[0] && row.value[0].status === "REJECTED"
								? t("lcas.statuses.rejected")
								: row.value[0].certification_process.status
								? t(
										"lcas.statuses." +
											row.value[0].certification_process.status.toLowerCase()
								  )
								: t("lcas.statuses.inProgress")
						}
						onClick={() =>
							navigate(
								"/climeco-certificationss/" +
									row.value[0].certification_process.id,
								{
									state: {
										previousPage: window.location.href.includes(":3000")
											? window.location.href.split(":3000")[1]
											: window.location.href.split(".com")[1],
									},
								}
							)
						}
						size={"small"}
						sx={{
							backgroundColor:
								row.value[0] && row.value[0].status === "REJECTED"
									? "#EB4B4B"
									: row.value[0].certification_process.status
									? "#6ab04c"
									: "#f9ca24",
							color: row.value[0].certification_process.status
								? "#fff"
								: "#000",
							fontWeight: "bold",
							fontSize: 10,
							textTransform: "uppercase",

							"&:hover": {
								backgroundColor:
									row.value[0] && row.value[0].status === "REJECTED"
										? "#EB4B4B"
										: row.value[0].certification_process.status
										? "#6ab04c"
										: "#f9ca24",
							},
						}}
					/>
				) : (
					t("lcas.noCertificationYet")
				),
		},
	];

	return (
		<>
			<Box>
				<Heading
					title={t("lcas.lifecycleAssessment")}
					subtitle=""
					showSearch
					actions={() => (
						<LCAActionBar
							selectedRows={selectedRows}
							refetch={refetch}
							filters={filters}
							setFilters={setFilters}
							setShowAddProductsModal={setShowAddProductsModal}
							setShowUpdateLCAModal={setShowUpdateLCAModal}
							setPaginationModel={setPaginationModel}
						/>
					)}
					setSearch={setSearch}
				/>
				<DataTable
					rows={LCAs}
					columns={columns}
					setSelectedRows={setSelectedRows}
					rowCount={rowCount}
					paginationModel={paginationModel}
					setPaginationModel={setPaginationModel}
					sortModel={sortModel}
					setSortModel={setSortModel}
					isLoading={isLoading || isRefetching || isFetching}
					sx={{
						border: "1px solid #E3E3E3",
						borderRadius: "28px",
						"& .MuiDataGrid-root": { border: "none" },
						"& .MuiDataGrid-columnHeaders": {},
						"& .MuiDataGrid-footerContainer": { borderTop: "none!important" },
					}}
				/>
			</Box>

			<BasicModal
				width={600}
				showModal={showProductsModal}
				handleClose={() => {
					setSelectedRow(null);
					setShowProductsModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							{t("lcas.modalTitles.productList")}
						</Typography>

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								gap: 1,
							}}
						>
							{selectedRow?.products.map((p: IProduct, index) => (
								<Box style={{ minHeight: 18, width: "100%", direction: "ltr" }}>
									{"- " + p.name}
								</Box>
							))}
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={600}
				showModal={showLCAsModal}
				handleClose={() => {
					setShowLCAsModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							{t("lcas.modalTitles.lca")}
						</Typography>

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								gap: 1,
							}}
						>
							{t("lcas.addOrUpdateLCA")}
						</Box>

						<Box
							sx={{
								display: "flex",
								gap: 3,
								padding: 6,
								paddingBottom: "20px",
								justifyContent: LCAs && LCAs.length > 0 ? "start" : "center",
								flexDirection: {
									xs: "column",
									sm: "row",
								},
							}}
						>
							<Button
								variant="outlined"
								onClick={() => {
									navigate("/lca", {
										state: {
											initialStep: IGetCertifiedStep.ADD_LCAS,
											previousPage: window.location.href.includes(":3000")
												? window.location.href.split(":3000")[1]
												: window.location.href.split(".com")[1],
										},
									});
								}}
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "start",
									padding: 3,
									borderRadius: "16px",
									paddingTop: 6,
									border: "none!important",
									outline: "1px solid #B1D1F0!important",
									flex: 1,
									"&:hover": {
										outline: "2px solid #34918E!important",
										backgroundColor: "transparent",
										border: "none!important",
									},
									maxWidth: 300,
								}}
							>
								<AddIcon style={{ fontSize: 24, color: "#000" }} />
								<Typography
									variant="body2"
									sx={{
										marginTop: 1,
										fontWeight: "bold",
										color: "#000",
										textTransform: "none",
										textAlign: "left",
									}}
								>
									{t("lcas.addNewLCA")}
								</Typography>
							</Button>

							<Button
								variant="outlined"
								onClick={() => {
									setShowLCAsModal(false);
								}}
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "start",
									padding: 2,
									borderRadius: "16px",
									paddingTop: 6,
									border: "none!important",
									outline: "1px solid #B1D1F0!important",
									flex: 1,
									"&:hover": {
										outline: "2px solid #34918E!important",
										backgroundColor: "transparent",
										border: "none!important",
									},
								}}
							>
								<EditIcon style={{ fontSize: 24, color: "#000" }} />
								<Typography
									variant="body2"
									sx={{
										marginTop: 1,
										fontWeight: "bold",
										color: "#000",
										textTransform: "none",
										textAlign: "left",
									}}
								>
									{t("lcas.updateExistingLCA")}
								</Typography>
							</Button>
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={600}
				showModal={showAddProductsModal}
				handleClose={() => {
					setShowAddProductsModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							{t("lcas.modalTitles.addProducts")}
						</Typography>

						<Typography
							variant="body1"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("lcas.modalTitles.addProductsText")}
							<br />
							<br />
							{t("lcas.modalTitles.addProductsText2")}
						</Typography>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowAddProductsModal(false);
								}}
							>
								{t("climecoCertificationProcess.no")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								onClick={() => {
									setShowAddProductsModal(false);
									navigate("/lcas/" + selectedRows[0]?.id, {
										state: {
											fromExistingLCA: true,
											addingProducts: true,
										},
									});
								}}
							>
								{t("climecoCertificationProcess.yes")}
							</Button>
						</Box>
					</Box>
				}
			/>
			{/** TO DO: UPDATE TEXTS */}
			<BasicModal
				width={600}
				showModal={showUpdateLCAModal}
				handleClose={() => {
					setShowUpdateLCAModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							{t("lcas.modalTitles.updateProducts")}
						</Typography>

						<Typography
							variant="body1"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("lcas.modalTitles.updateProductsText")}
							<br />
							<br />
							{t("lcas.modalTitles.updateProductsText2")}
						</Typography>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowUpdateLCAModal(false);
								}}
							>
								{t("climecoCertificationProcess.no")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								onClick={() => {
									setShowUpdateLCAModal(false);
									navigate("/lcas/" + selectedRows[0]?.id, {
										state: {
											fromExistingLCA: true,
											updatingLCA: true,
										},
									});
								}}
							>
								{t("climecoCertificationProcess.yes")}
							</Button>
						</Box>
					</Box>
				}
			/>
		</>
	);
}
