import {
	Box,
	Button,
	FormControl,
	Grid,
	TextField,
	Autocomplete,
	CircularProgress,
	FormControlLabel,
	Switch,
	Typography,
	Divider,
} from "@mui/material";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/user";
import { makeStyles } from "@mui/styles";
import { ICompany } from "../../models";
import { useMutation } from "react-query";
import { company as companyActions } from "../../controllers";
import { useSnackbar } from "notistack";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 8,
		marginBottom: 4,
		fontWeight: 700,
		color: "#888",
	},
});

export function CompanyProfile() {
	const { t } = useTranslation();
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();

	const [company, setCompany] = useState<ICompany>(
		user?.company || ({} as ICompany)
	);
	const {
		placesService,
		placePredictions,
		getPlacePredictions,
		isPlacePredictionsLoading,
	} = usePlacesService({
		apiKey: "AIzaSyBeHWqS_4Rm9ZBIqbOtQ5PwGJIUYsaHxD0",
		options: {
			types: ["street_address", "route"],
		},
		language: "en",
	});
	const { isLoading, mutate } = useMutation(
		"companies",
		() =>
			companyActions.update({
				id: user?.company.id!,
				name: company?.name,
				description: company?.description,
				formatted_address: company?.formatted_address,
				address: company?.address,
				city: company?.city,
				state: company?.state,
				zip_code: company?.zip_code,
				country: company?.country,
				phone: company?.phone,
				email: company?.email,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				enqueueSnackbar(t("companyProfile.companyUpdated"), {
					variant: "success",
				});
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("companyProfile.errorSomethingWentWrong"), {
					variant: "error",
				});
			},
		}
	);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
			}}
		>
			<Typography
				variant={"h6"}
				sx={{
					textTransform: "uppercase",
					fontSize: 16,
					fontWeight: "bold",
					marginTop: 2,
					marginBottom: 1,
				}}
			>
				{t("companyOnboarding.companyDetails")}
			</Typography>
			<Divider sx={{ opacity: 0.5, marginBottom: 3 }} />
			<FormControl sx={{ width: "100%" }}>
				<span className={classes.inputLabel}>{t("companyProfile.name")}</span>
				<TextField
					InputLabelProps={{ shrink: false }}
					fullWidth
					label=""
					id="name"
					placeholder={t("companyProfile.namePlaceholder")}
					value={company?.name}
					sx={{ marginBottom: 2 }}
					onChange={(e) => {
						setCompany(
							(prevState) =>
								({
									...prevState,
									name: e.target.value,
								} as ICompany)
						);
					}}
				/>
				<span className={classes.inputLabel}>
					{t("companyProfile.description")}
				</span>
				<TextField
					InputLabelProps={{ shrink: false }}
					fullWidth
					label=""
					id="description"
					placeholder={t("companyProfile.descriptionPlaceholder")}
					value={company?.description}
					sx={{ marginBottom: 2 }}
					onChange={(e) => {
						setCompany(
							(prevState) =>
								({
									...prevState,
									description: e.target.value,
								} as ICompany)
						);
					}}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={6}>
						<span className={classes.inputLabel}>
							{t("companyProfile.email")}
						</span>
						<TextField
							InputLabelProps={{ shrink: false }}
							fullWidth
							label=""
							id="email"
							placeholder={t("companyProfile.emailPlaceholder")}
							sx={{ marginBottom: 2 }}
							value={company?.email}
							onChange={(e) => {
								setCompany(
									(prevState) =>
										({
											...prevState,
											email: e.target.value,
										} as ICompany)
								);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<span className={classes.inputLabel}>
							{t("companyProfile.phone")}
						</span>
						<TextField
							InputLabelProps={{ shrink: false }}
							fullWidth
							label=""
							id="phone"
							placeholder={t("companyDetail.phonePlaceholder")}
							value={company?.phone}
							sx={{ marginBottom: 2 }}
							onChange={(e) => {
								setCompany(
									(prevState) =>
										({
											...prevState,
											phone: e.target.value,
										} as ICompany)
								);
							}}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12}>
						<span className={classes.inputLabel}>
							{t("companyProfile.address")}
						</span>
						<Autocomplete
							id="autocomplete-address"
							filterOptions={(options) => options}
							value={company?.formatted_address}
							noOptionsText={
								isPlacePredictionsLoading
									? t("companyProfile.loading")
									: t("companyProfile.noAddressFound")
							}
							getOptionLabel={(option) => option || ""}
							options={
								isPlacePredictionsLoading
									? [t("companyProfile.loading")]
									: placePredictions.map((place) => {
											return place.description;
									  })
							}
							sx={{ width: "100%", marginBottom: 2 }}
							renderInput={(params) => (
								<TextField
									{...params}
									value={company?.formatted_address}
									placeholder={t("companyProfile.enterAddress")}
									variant="outlined"
									InputLabelProps={{ shrink: false }}
									onChange={(e) => {
										getPlacePredictions({
											input: e.target.value,
										});

										setCompany(
											(prevState) =>
												({
													...prevState,
													address: e.target.value,
												} as ICompany)
										);
									}}
								/>
							)}
							onChange={(e) => {
								const optionIndex =
									e.currentTarget.getAttribute("data-option-index");
								const selectedIndex = parseInt(
									optionIndex ? optionIndex : "",
									10
								);
								const place = placePredictions[selectedIndex];
								if (place?.place_id) {
									placesService?.getDetails(
										{
											placeId: place.place_id,
										},
										(placeDetails: any) => {
											let inputAddress: string | null = null;
											let inputCity: string | null = null;
											let inputState: string | null = null;
											let inputZipCode: string | null = null;
											let inputCountry: string | null = null;
											placeDetails.address_components.forEach(
												(component: {
													long_name: string;
													short_name: string;
													types: Array<string>;
												}) => {
													if (
														component.types.find((t) => t === "street_number")
													) {
														inputAddress = !inputAddress
															? component.short_name
															: component.short_name + " " + inputAddress;
													}
													if (component.types.find((t) => t === "route")) {
														inputAddress = !inputAddress
															? component.short_name
															: inputAddress + " " + component.short_name;
													}
													if (component.types.find((t) => t === "locality")) {
														inputCity = component.short_name;
													}
													if (
														component.types.find(
															(t) => t === "administrative_area_level_1"
														)
													) {
														inputState = component.short_name;
													}
													if (
														component.types.find((t) => t === "postal_code")
													) {
														inputZipCode = component.short_name;
													}
													if (component.types.find((t) => t === "country")) {
														inputCountry = component.short_name;
													}
												}
											);
											const auxCompany = { ...company };
											if (inputAddress) {
												auxCompany.address = inputAddress;
											}
											if (inputCity) {
												auxCompany.city = inputCity;
											}
											if (inputState) {
												auxCompany.state = inputState;
											}
											if (inputZipCode) {
												auxCompany.zip_code = inputZipCode;
											}
											if (inputCountry) {
												auxCompany.country = inputCountry;
											}
											auxCompany.formatted_address =
												placeDetails.formatted_address;

											setCompany(auxCompany as ICompany);

											if (
												!inputAddress ||
												!inputCity ||
												!inputState ||
												!inputZipCode ||
												!inputCountry
											) {
												enqueueSnackbar(t("companyProfile.incorrectAddress"), {
													variant: "error",
												});
											}
										}
									);
								}
							}}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12}>
						<FormControlLabel
							control={
								<Switch
									checked={company?.country === "cn" ? true : false}
									onChange={(e) => {
										setCompany(
											(prevState) =>
												({
													...prevState,
													country: e.target.checked ? "cn" : "",
												} as ICompany)
										);
									}}
									name="china-projects"
								/>
							}
							label={t("companyProfile.chinaProjects")}
							sx={{
								"& .MuiFormControlLabel-label": {
									fontSize: "14px",
								},
							}}
						/>
					</Grid>
				</Grid>

				<Button
					variant="contained"
					onClick={() => {
						mutate();
					}}
					sx={{
						marginTop: 2,
						minHeight: 34,
						maxWidth: 300,
						alignSelf: "end",
					}}
				>
					{isLoading ? (
						<CircularProgress size={12} sx={{ color: "white" }} />
					) : (
						t("companyProfile.save")
					)}
				</Button>
			</FormControl>
		</Box>
	);
}
