import { ILCA } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"


class LCA {
    async list(companyId?: number, search?: string, page?: number, companies?: Array<number>, sort: string = "-updated_at", pageSize: number = 10, signal?: AbortSignal): Promise<{ count: number, results: Array<ILCA> }> {
        const endpoint = (companyId ? "companies/" + companyId + "/" : "") + "lcas/"
        const params = new URLSearchParams();
        params.append("page", page?.toString() ?? "1");
        params.append("search", search ?? "");
        params.append("ordering", sort);
        params.append("page_size", pageSize.toString());
        if (companies && companies.length > 0) {
            params.append("company__in", companies.join(","));
        }
        const showTestData = localStorage.getItem("showTestData");
        if (!showTestData) {
            params.append("company__is_test_company", "false");
        }
        const response = await connectionsClient.client.get(endpoint, {
            params,
            signal
        });
        return response.data;
    }

    async create(title: string, companyId: number): Promise<ILCA> {
        const endpoint = companyId ? "companies/" + companyId + "/lcas" : "lcas";

        const response = await connectionsClient.client.post(endpoint, {
            title: title,
            company_id: companyId
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data;
    }

    async getOne(lcaId: number, companyId?: number): Promise<ILCA> {
        const endpoint = (companyId ? "companies/" + companyId + "/" : "") + "lcas/"
        const response = await connectionsClient.client.get(endpoint + lcaId);
        return response.data;
    }

}

export const lca = new LCA();