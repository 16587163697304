import { ICertification, ICertificationFiles, IStep } from "../../models"
import { ISubscription } from "../../models/subscription-model"
import { connectionsClient } from "../../utils/ConnectionsClient"
import { CertificationType } from "./certification.types"

class Certification {
    async list(isClimeCo: boolean, companyId?: number, search?: string, companies?: Array<number>, page?: number, sort: string = "-updated_at", pageSize: number = 10, isHistorical?: boolean, signal?: AbortSignal): Promise<{ count: number, results: Array<ICertification> }> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/external-certification-processes/"
        const endpoint = companyId ? "companies/" + companyId + path : path
        const response = await connectionsClient.client.get(endpoint, {
            params: { limit: 999, search: search, ordering: sort, company__in: companies && companies.length > 0 ? companies.join(",") : undefined, page: page?.toString() ?? "1", page_size: pageSize, ...(isHistorical ? { is_historical__isnull: !isHistorical } : {}), company__is_test_company: localStorage.getItem("showTestData") ? undefined : false },
            signal
        });
        return response.data;
    }

    async listProviders(params?: Object): Promise<
        [
            {
                id: number;
                name: string;
                certification_type: string;
            }
        ]
    > {
        const path = "/certifications"
        const endpoint = path
        const response = await connectionsClient.client.get(endpoint, {
            params: params || {}
        });
        return response.data.results;
    }



    async unsignedList(companyId?: number, search?: string, companies?: Array<number>, page?: number, sort: string = "-updated_at", pageSize: number = 10): Promise<{ count: number, results: Array<ICertification> }> {
        const path = "/unsigned-climeco-certification-processes/"
        const endpoint = companyId ? "companies/" + companyId + path : path
        const response = await connectionsClient.client.get(endpoint, {
            params: { limit: 999, search: search, ordering: sort, company__in: companies && companies.length > 0 ? companies.join(",") : undefined, page: page?.toString() ?? "1", page_size: pageSize, company__is_test_company: localStorage.getItem("showTestData") ? undefined : false }
        });
        return response.data;
    }

    async documentsList(companyId?: number, search?: string, companies?: Array<number>, page?: number, sort: string = "-updated_at", pageSize: number = 10): Promise<{ count: number, results: Array<ICertification> }> {
        const path = "/all-climeco-certification-processes/"
        const endpoint = companyId ? "companies/" + companyId + path : path
        const response = await connectionsClient.client.get(endpoint, {
            params: { limit: 999, search: search, ordering: sort, company__in: companies && companies.length > 0 ? companies.join(",") : undefined, page: page?.toString() ?? "1", page_size: pageSize, company__is_test_company: localStorage.getItem("showTestData") ? undefined : false }
        });
        return response.data;
    }

    async getOne(isClimeCo: boolean, certificationId: number, companyId?: number): Promise<ICertification> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/external-certification-processes/"
        const endpoint = companyId ? "companies/" + companyId + path + certificationId : path + certificationId
        const response = await connectionsClient.client.get(endpoint);
        return response.data;
    }

    async generateLink(isClimeCo: boolean, certificationId: number, companyId?: number): Promise<ICertification> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/climeco-certification-processes/"
        const endpoint = companyId ? "companies/" + companyId + path + certificationId + "/generate-signing-url/" : path + certificationId + "/generate-signing-url/"
        const response = await connectionsClient.client.get(endpoint);
        return response.data;
    }

    async getContractDocuments(isClimeCo: boolean, certificationId: number, companyId?: number): Promise<Array<ICertificationFiles>> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/external-certification-processes/"
        const endpoint = companyId ? "companies/" + companyId + path + certificationId + "/download-document/" : path + certificationId + "/download-document/"
        const response = await connectionsClient.client.get(endpoint, { params: { company__is_test_company: localStorage.getItem("showTestData") ? undefined : false } });
        return response.data;
    }

    async create({ isClimeCo, name, companyId, certification, isHistorical, lcaVersions, addingProducts, updatingLCA }: CertificationType.Create): Promise<ICertification> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/external-certification-processes/"
        const endpoint = companyId ? "companies/" + companyId + path : path
        const response = await connectionsClient.client.post(endpoint, { name, certification, is_historical: isHistorical, lca_versions: lcaVersions, is_add_products: addingProducts, is_update_lca: updatingLCA });
        return response.data;
    }

    /*async update({ isClimeCo, id, name, companyId }: CertificationType.Update): Promise<ICertification> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/certification-processes/"
        const endpoint = companyId ? "companies/" + companyId + "/certification-processes/" + id : "certification-processes/" + id
        const response = await connectionsClient.client.patch(endpoint, { name });
        return response.data;
    }*/

    async update({ isClimeCo, id, estimations, companyId, description }: CertificationType.Update): Promise<ICertification> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/external-certification-processes/"
        const endpoint = companyId ? "companies/" + companyId + path + id : path + id
        const response = await connectionsClient.client.patch(endpoint, { estimations, description });
        return response.data;
    }

    async updateUserInformation({ isClimeCo, id, companyId, userInformation }: CertificationType.Update): Promise<ICertification> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/external-certification-processes/"
        const endpoint = companyId ? "companies/" + companyId + path + id : path + id
        const response = await connectionsClient.client.patch(endpoint, { user_information: userInformation });
        return response.data;
    }

    async toggleApproval({ isClimeCo, id, companyId }: CertificationType.Update): Promise<ICertification> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/certification-processes/"
        const endpoint = companyId ? "companies/" + companyId + path + id : path + id
        const response = await connectionsClient.client.patch(endpoint, { requested_approval: new Date().toISOString() });
        return response.data;
    }

    async addOffsets({ id, companyId, quantity, description, amount }: CertificationType.Update): Promise<ICertification> {
        const endpoint = "/climeco-add-offsets/"
        const response = await connectionsClient.client.post(endpoint, { certification_process_id: id > -1 ? id : undefined, company_id: companyId, quantity, description: description, amount: amount });
        return response.data;
    }

    async addDocuments({ isClimeCo, id, companyId, file, name }: CertificationType.Update): Promise<ICertification> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/certification-processes/"
        const endpoint = (companyId ? "companies/" + companyId + path + id : path + id) + "/certification-process-files/"
        const formData = new FormData();
        if (file) {
            formData.append("file", file);
        }
        if (name) {
            formData.append("name", name);
        }
        const response = await connectionsClient.client.post(endpoint, formData, { headers: { "Content-Type": "multipart/form-data" } });
        return response.data;
    }

    async getDocuments({ isClimeCo, id, companyId, file }: CertificationType.Update): Promise<Array<ICertificationFiles>> {
        const endpoint = "companies/" + companyId + "/certification-process-files/"
        const response = await connectionsClient.client.get(endpoint, { params: { company__is_test_company: localStorage.getItem("showTestData") ? undefined : false } });
        return response.data.results;
    }

    async generateCSV({ isClimeCo, id, companyId }: CertificationType.Update): Promise<Array<ICertificationFiles>> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/external-certification-processes/"
        const endpoint = (companyId ? "companies/" + companyId + path + id : path + id) + "/generate-csv/"
        const response = await connectionsClient.client.post(endpoint, {});
        return response.data;
    }

    async delete(isClimeCo: boolean, certification: ICertification): Promise<{ res: string }> {
        const path = isClimeCo ? "/climeco-certification-processes/" : "/certification-processes/"
        const response = await connectionsClient.client.delete(`${path}${certification.id}`);
        return response.data;
    }

    async getSteps(certificationType: "CLIMECO_INTERNAL_CERTIFICATION"
        | "DEFAULT_EXTERNAL_CERTIFICATION", isHistorical: boolean): Promise<Array<IStep>> {
        const path = "/steps/"
        const response = await connectionsClient.client.get(`${path}`, {
            params: { certification__certification_type: certificationType, used_in_historical: isHistorical }
        });
        return response.data.results;
    }
    async getCartSubscriptions(certificationId: number): Promise<{
        "annual_fee_to_pay": number,
        "lca_fee_to_pay": number,
        "lca_fee_to_pay_multiplier": number,
        "revised_lca_fee_to_pay": number,
        "revised_lca_fee_to_pay_multiplier": number,
        "rejections_lca_fee_to_pay": number,
        "subscriptions": Array<ISubscription>
    }> {
        const path = "/certification-process-cart-subscriptions/"
        const response = await connectionsClient.client.get(`${path}`, {
            params: { certification_id: certificationId }
        });
        return response.data;
    }
}

export const certification = new Certification();